import { Component, OnInit } from '@angular/core';
import DDIOptions from 'src/app/data/ddi';
import { ApiService } from 'src/app/services/api/api.service';
import { SignupService } from 'src/app/services/signup/signup.service';
import { ToastService } from 'src/app/services/toast/toast.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { Router } from '@angular/router';
import { FormBuilder } from '@angular/forms';

type SettingsPermissions = {
  user_group_user: boolean;
  user_group: boolean;
};

@Component({
  selector: 'app-dashboard-group-users',
  templateUrl: './dashboard-group-users.component.html',
  styleUrls: ['./dashboard-group-users.component.scss'],
})
export class DashboardGroupUsersComponent implements OnInit {
  tabIndex = 0;
  activedTab = 'groups';
  tabs = [
    { label: 'Grupos', value: 'groups' },
    { label: 'Usuários', value: 'users' },
  ];

  canView: SettingsPermissions = {
    user_group: false,
    user_group_user: false,
  };

  filterForm = this.formBuilder.group({
    user: [null, []],
    action: [null, []],
    group: [null, []],
    initial_date: [null, []],
    final_date: [null, []],
  });

  DDIOptions = DDIOptions;

  records: any[] = [];
  users: any[] = [];

  loading = true;

  showRecords = true;

  showFilterModal = false;
  roleOptions: SelectItem[] = [];

  constructor(
    private toast: ToastService,
    public signupService: SignupService,
    private authService: AuthService,
    private router: Router,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit(): void {
    this.setPermissions();

    if (!this.canView.user_group && !this.canView.user_group_user) {
      this.toast.show(
        'error',
        'Aviso',
        'Você não tem permissão para acessar essa página.'
      );
      this.router.navigateByUrl('/app/dashboard');
    }

    this.tabs = this.tabs.filter((tab) => {
      if (tab.value === 'groups' && !this.canView.user_group) {
        return false;
      }

      if (tab.value === 'users' && !this.canView.user_group_user) {
        return false;
      }

      return true;
    });

    this.loading = false;
  }

  redirectToDetails(id: any) {
    this.router.navigateByUrl(
      `/app/settings/permissions/history/details/${id}`
    );
  }

  resetForm() {
    this.filterForm.reset();
    this.showFilterModal = false;
  }

  filterData() {}

  addGroup() {}

  onChangeTab(index: number) {
    this.activedTab = this.tabs[index].value;

    this.tabIndex = index;
  }

  setPermissions() {
    const permissionArr = [
      'de_can_view_user_group',
      'de_can_view_user_group_user',
    ];

    permissionArr.forEach((permission) => {
      const label = permission.split('_').slice(3).join('_');

      this.canView[`${label}`] = this.authService.verifyPermission([
        permission,
      ]);
    });
  }
}
