import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api/api.service';
import { SignupService } from 'src/app/services/signup/signup.service';
import { ToastService } from 'src/app/services/toast/toast.service';
import { Router } from '@angular/router';
import { MaskPipe } from 'ngx-mask';
import { FormBuilder } from '@angular/forms';
import Swal from 'sweetalert2';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-dashboard-companies',
  templateUrl: './dashboard-companies.component.html',
  styleUrls: ['./dashboard-companies.component.scss'],
})
export class DashboardCompaniesComponent implements OnInit {
  permissionObj = {
    canChange: true,
  };

  data: PermissionCompany[] = [];
  loading = true;
  offset = 0;
  count = 0;

  filterForm = this.formBuilder.group({
    name: [null, []],
    document: [null, []],
    status: [null, []],
  });

  statusOptions: SelectItem[] = [
    { label: 'Ativo', value: 'true' },
    { label: 'Inativo', value: 'false' },
  ];

  hasFilters = false;
  showFilterModal = false;

  constructor(
    private toast: ToastService,
    public signupService: SignupService,
    private router: Router,
    private api: ApiService,
    private maskPipe: MaskPipe,
    private formBuilder: FormBuilder,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.setPermissions();
    this.getData();

    this.filterForm.valueChanges.subscribe((values) => {
      this.hasFilters = Object.values(values).some(
        (value) => value !== null && value !== ''
      );
    });
  }

  async getData(page: number = 1) {
    try {
      const filters = {};

      if (this.hasFilters) {
        const values = this.filterForm.value;
        Object.entries(values).forEach(([key, value]: any) => {
          if (value !== null && value !== '') {
            filters[key] = value;
          }
        });
      }

      const { count, results, offset } = await this.api.get({
        route: 'api/v2/company/',
        token: true,
        params: {
          page,
          type: 'list',
          ...filters,
        },
      });

      this.data = results;
      this.offset = offset;
      this.count = count;
    } catch (error) {
      console.error(error);
      this.toast.show('error', 'Erro', 'Ocorreu um erro ao carregar os dados.');
    }

    this.loading = false;
  }

  resetForm() {
    this.hasFilters = false;
    this.filterForm.reset();
    this.showFilterModal = false;
    this.loading = true;
    this.getData();
  }

  redirectToDetails(id: any) {
    this.router.navigateByUrl(
      `/app/settings/permissions/company/details/${id}`
    );
  }

  formatDocument(item: string) {
    return item.replace(
      /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/,
      '$1.$2.$3/$4-$5'
    );
  }

  formatPhone(item: { ddi: string; number: string }) {
    const ddiCode = item.ddi ? `+${item.ddi} ` : '';
    const checkNumberLength = item.number.length === 9;
    const formattedNumber = this.maskPipe.transform(
      item.number,
      checkNumberLength ? '00000-0000' : '0000-0000'
    );

    return `${ddiCode}${formattedNumber}`;
  }

  changePageData(page: number) {
    this.loading = true;
    this.getData(page);
  }

  async filterData() {
    this.loading = true;

    try {
      const values = this.filterForm.value;

      const filters = {};

      Object.entries(values).forEach(([key, value]: any) => {
        if (value !== null && value !== '') {
          let newValue = value;
          if (key === 'start_date' || key === 'end_date') {
            newValue = value.split('/').reverse().join('-');
          }
          filters[key] = newValue;
        }
      });

      const { results, count, offset } = await this.api.get({
        route: 'api/v2/company/',
        token: true,
        params: {
          page: 1,
          ...filters,
          type: 'list',
        },
      });

      this.hasFilters = true;
      this.count = count;
      this.data = results;
      this.offset = offset;
      this.showFilterModal = false;
    } catch (error) {
      console.error(error);
      this.toast.show('error', 'Erro', 'Ocorreu um erro ao carregar os dados.');
    }
    this.loading = false;
  }

  async handleChangeStatus(id: string) {
    try {
      const finded = this.data.find((item) => item.id === id);
      const isActive = finded.status === 'Ativa' ? true : false;

      const { isConfirmed } = await Swal.fire({
        title: isActive
          ? 'Deseja desativar essa empresa?'
          : 'Deseja ativar essa empresa?',
        text: isActive
          ? 'A empresa e os usuários vinculados não poderão acessar o sistema.'
          : 'A empresa e os usuários vinculados poderão acessar o sistema.',
        icon: 'warning',
        showConfirmButton: true,
        confirmButtonColor: '#3085d6',
        confirmButtonText: isActive ? 'Desativar' : 'Ativar',
        showCancelButton: true,
        cancelButtonColor: '#d33',
        cancelButtonText: 'Cancelar',
      });

      if (!isConfirmed) {
        return;
      }

      await this.api.patch({
        route: `api/v2/company/${id}/`,
        token: true,
        body: {
          status: isActive ? 'Inativa' : 'Ativa',
        },
      });

      this.toast.show(
        'info',
        'Sucesso',
        'Status da empresa alterado com sucesso.'
      );

      const index = this.data.findIndex((item) => item.id === id);
      this.data[index].status = isActive ? 'Inativa' : 'Ativa';
    } catch (error) {
      console.error(error);
      this.toast.show(
        'error',
        'Erro',
        'Ocorreu um erro ao mudar status da empresa.'
      );
    }
  }

  setPermissions() {
    const permissions = Object.keys(this.permissionObj).map(
      (key) =>
        `de_can_${key.split('can').join('').toLowerCase()}_user_group_company`
    );

    permissions.forEach((permission, idx) => {
      this.permissionObj[Object.keys(this.permissionObj)[idx]] =
        this.authService.verifyPermission(permission);
    });
  }
}
