import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ToastService } from 'src/app/services/toast/toast.service';

@Component({
  selector: 'app-dashboard-company',
  templateUrl: './dashboard-company.component.html',
  styleUrls: ['./dashboard-company.component.scss'],
})
export class DashboardCompanyComponent implements OnInit {
  tabIndex = 0;
  activedTab = 'dados-da-empresa';
  tabs = [
    { label: 'Dados da empresa', value: 'dados-da-empresa' },
    { label: 'Usuários associados', value: 'usuarios-associados' },
    { label: 'Fundos associados', value: 'fundos-associados' },
    { label: 'Permissões associadas', value: 'grupos-de-permissao' },
  ];

  loading = false;

  constructor(
    private authService: AuthService,
    private toast: ToastService,
    private router: Router
  ) {}

  ngOnInit(): void {
    if (
      !this.authService.verifyPermission(['de_can_view_user_group_company'])
    ) {
      this.toast.show(
        'error',
        'Aviso',
        'Você não tem permissão para acessar essa página.'
      );
      this.router.navigateByUrl('/app/dashboard');
    }
  }

  onChangeTab(index: number) {
    this.activedTab = this.tabs[index].value;

    this.tabIndex = index;
  }
}
