import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api/api.service';
import { SignupService } from 'src/app/services/signup/signup.service';
import { ToastService } from 'src/app/services/toast/toast.service';
import { Router } from '@angular/router';
import { FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-dashboard-history',
  templateUrl: './dashboard-history.component.html',
  styleUrls: ['./dashboard-history.component.scss'],
})
export class DashboardHistoryComponent implements OnInit {
  permissionObj = {
    canAdd: true,
    canChange: true,
    canDelete: true,
  };

  filterForm = this.formBuilder.group({
    user: [null, []],
    action: [null, []],
    name: [null, []],
    group_name: [null, []],
    start_date: [null, []],
    end_date: [null, []],
  });

  data: HistoryValues[] = [];

  loading = true;
  hasFilters = false;
  showFilterModal = false;

  showRecords = true;
  count = 0;
  offset = 0;

  groupOptions: SelectItem[] = [];
  actionOptions: SelectItem[] = [];
  usersOptions: SelectItem[] = [];

  constructor(
    private api: ApiService,
    private toast: ToastService,
    public signupService: SignupService,
    private router: Router,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit(): void {
    // if (!this.authService.verifyPermission(['de_can_view_audit'])) {
    //   this.toast.show(
    //     'error',
    //     'Aviso',
    //     'Você não tem permissão para acessar essa página.'
    //   );
    //   this.router.navigateByUrl('/app/dashboard');
    // }

    this.getData();

    this.filterForm.valueChanges.subscribe((values) => {
      this.hasFilters = Object.values(values).some(
        (value) => value !== null && value !== ''
      );
    });
  }

  addAudit() {
    return;
  }

  async getData(page: number = 1) {
    try {
      const filters = {};

      if (this.hasFilters) {
        const values = this.filterForm.value;
        Object.entries(values).forEach(([key, value]: any) => {
          if (value !== null && value !== '') {
            filters[key] = value;
          }
        });
      }

      if (filters['start_date'] && !filters['end_date']) {
        const atualDate = new Date().toISOString().split('T')[0];
        filters['end_date'] = atualDate;
      }

      if (filters['end_date'] && !filters['start_date']) {
        filters['start_date'] = '2000-01-01';
      }

      const { count, results, offset } = await this.api.get({
        route: 'api/v2/permission_history/',
        token: true,
        params: {
          page,
          ...filters,
        },
      });

      this.count = count;
      this.offset = offset;
      this.data = results.history ?? [];

      this.groupOptions = results.groups.map((group) => ({
        label: group.name,
        value: group.id,
      }));
      this.actionOptions = results.actions.map((group) => ({
        label: group.description,
        value: group.id,
      }));
      this.usersOptions = results.users.map((group) => ({
        label: group.name,
        value: group.id,
      }));
    } catch (error) {
      console.error(error);
      this.toast.show('error', 'Erro', 'Ocorreu um erro ao carregar os dados.');
    }
    this.loading = false;
  }

  redirectToDetails(id: any) {
    this.router.navigateByUrl(
      `/app/settings/permissions/history/details/${id}`
    );
  }

  resetForm() {
    this.hasFilters = false;
    this.filterForm.reset();
    this.showFilterModal = false;
    this.loading = true;
    this.getData();
  }

  async filterData() {
    this.loading = true;

    try {
      const values = this.filterForm.value;

      const filters = {};

      Object.entries(values).forEach(([key, value]: any) => {
        if (value !== null && value !== '') {
          let newValue = value;
          if (key === 'start_date' || key === 'end_date') {
            newValue = value.split('/').reverse().join('-');
          }
          filters[key] = newValue;
        }
      });

      if (filters['start_date'] && !filters['end_date']) {
        const atualDate = new Date().toISOString().split('T')[0];
        filters['end_date'] = atualDate;
      }

      if (filters['end_date'] && !filters['start_date']) {
        filters['start_date'] = '2000-01-01';
      }

      const { count, results, offset } = await this.api.get({
        route: 'api/v2/permission_history/',
        token: true,
        params: {
          page: 1,
          ...filters,
        },
      });

      this.hasFilters = true;
      this.count = count;
      this.offset = offset;
      this.data = results.history ?? [];
      this.showFilterModal = false;

      this.groupOptions = results.groups.map((group) => ({
        label: group.name,
        value: group.id,
      }));
      this.actionOptions = results.actions.map((group) => ({
        label: group.description,
        value: group.id,
      }));
      this.usersOptions = results.users.map((group) => ({
        label: group.name,
        value: group.id,
      }));
    } catch (error) {
      console.error(error);
      this.toast.show('error', 'Erro', 'Ocorreu um erro ao carregar os dados.');
    }
    this.loading = false;
  }
  changePageData(page: number) {
    this.loading = true;
    this.getData(page);
  }
}
