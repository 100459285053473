<div class="shareholder">
    <div class="row">
        <div class="col-12 ml-auto mr-auto">
            <ng-container *ngIf="!loading">
                <!--    <app-back-button
                    *ngIf="applicableRole === 'ADM'"></app-back-button> -->
            </ng-container>

            <div class="text-right mb-2" *ngIf="loading">
                <ngx-skeleton-loader [theme]="{
                    width: '360px',
                    height: '25px',
                    margin:'0'
                  }">
                </ngx-skeleton-loader>
            </div>

            <div class="d-flex flex-row-reverse mb-2" #toggleOptions
                *ngIf="!loading">
                <div class="dropdown-container">
                    <button class="dropdown-container-content"
                        [class.active]="showOptions" type="button"
                        (click)="showOptions = !showOptions">
                        <span
                            *ngIf="approval !== null && approval.valid_until === null || false">
                            {{ approval.version_number }} <a
                                *ngIf="approval.is_draft === true">(Rascunho)</a>
                            - Criada em {{formatDateTime(approval.created_at)}}
                        </span>
                        <span
                            *ngIf="approval !== null && approval.valid_until !== null || false">
                            {{ approval.version_number }} <a
                                *ngIf="approval.is_draft === true">(Rascunho)</a>
                            - Criada em {{formatDateTime(approval.created_at)}}
                            e válida até
                            {{formatDateTime(approval.valid_until)}}
                        </span>
                        <i class="fas fa-sort-down"></i>
                    </button>

                    <div class="dropdown" *ngIf="showOptions">
                        <div *ngFor="let item of allApprovals; index as i">
                            <button
                                *ngIf="item.revision && item.valid_until === null"
                                (click)="changeApproval(item)">{{item.version_number}}
                                <a *ngIf="item.is_draft === true">(Rascunho)</a>
                                Criada em {{formatDateTime(item.created_at)}}
                            </button>
                            <button
                                *ngIf="item.revision && item.valid_until !== null"
                                (click)="changeApproval(item)">{{item.version_number}}
                                <a *ngIf="item.is_draft === true">(Rascunho)</a>
                                Criada em {{formatDateTime(item.created_at)}} e
                                válida até
                                {{formatDateTime(item.valid_until)}}
                            </button>
                        </div>
                    </div>
                </div>
                <span class="shareholder-profile-type">
                    Versões:
                </span>
            </div>

            <div class="shareholder-card">
                <div class="row w-100 no-gutters">
                    <div class="col-12 col-lg-8 d-flex flex-row"
                        *ngIf="loading">
                        <div class="shareholder-profile-initials bg-none">
                            <ngx-skeleton-loader appearance="circle" [theme]="{
                                width: '64px',
                                height: '64px',
                                margin:'0'
                              }">
                            </ngx-skeleton-loader>
                        </div>
                        <app-spacer [right]="32"></app-spacer>
                        <div class="d-flex flex-column">
                            <ngx-skeleton-loader [theme]="{
                                height: '44px',
                                width: '500px',
                                margin:'0'
                              }">
                            </ngx-skeleton-loader>
                            <div class="d-flex flex-row align-items-center">
                                <ngx-skeleton-loader [theme]="{
                                height: '24px',
                                width:'150px'
                            }"></ngx-skeleton-loader>
                            </div>
                        </div>
                    </div>

                    <div class="col-12 col-lg-8 d-flex flex-row"
                        *ngIf="!loading">
                        <div class="shareholder-profile-initials">
                            <span>{{getInitials(approval)}}</span>
                        </div>
                        <app-spacer [right]="32"></app-spacer>
                        <div class="d-flex flex-column w-100">
                            <span
                                class="shareholder-profile-name">{{getDisplayName(approval)}}</span>
                            <div class="d-flex flex-row align-items-center">
                                <span class="shareholder-profile-type">
                                    {{getDisplayProfileType(approval)}}
                                    <ng-container *ngIf="approval.user_details">
                                        | Criado por
                                        {{approval.user_details.first_name}}
                                        {{approval.user_details.last_name}}
                                    </ng-container>
                                </span>
                            </div>
                        </div>
                    </div>

                    <div class="col-12 col-lg-3" *ngIf="false">
                        <div class="d-flex flex-row align-items-center">
                            <i
                                class="shareholder-profile-icon fas fa-envelope"></i>
                            <app-spacer [right]="16"></app-spacer>

                            <ng-container
                                *ngIf="approval.register.type === 'PF'">
                                <span
                                    class="shareholder-profile-contact">{{approval.register.person.email}}</span>
                            </ng-container>

                            <ng-container
                                *ngIf="approval.register.type !== 'PF'">
                                <span
                                    class="shareholder-profile-contact">{{approval.register.company.contact_email}}</span>
                            </ng-container>
                        </div>

                        <app-spacer [bottom]="16"></app-spacer>

                        <div class="d-flex flex-row align-items-center">
                            <i
                                class="shareholder-profile-icon fas fa-phone"></i>
                            <app-spacer [right]="16"></app-spacer>
                            <span
                                class="shareholder-profile-contact">{{formatPhone(getPhone(approval))}}</span>
                        </div>
                    </div>

                    <ng-container *ngIf="!loading">
                        <ng-container *ngIf="!isSimplified">
                            <div
                                class="col-12 col-lg-4 d-flex justify-content-end">
                                <ng-container *ngIf="self_view">
                                    <app-button (onClick)="redirectToEdit()"
                                        [center]="true" [outline]="true">
                                        Editar cadastro
                                    </app-button>

                                    <app-spacer [right]="24"></app-spacer>
                                </ng-container>

                                <app-badge
                                    *ngIf="approval.completed || !approval.can_sign"
                                    [approval]="approval">
                                </app-badge>

                                <!-- <ng-container
                                    *ngIf="approval.can_sign && !approval.completed">
                                    <app-button
                                        (onClick)="handleApproval(true)">Aprovar</app-button>
                                    <app-spacer [right]="8"></app-spacer>
                                    <app-button [danger]="true"
                                        (onClick)="handleApproval(false,true)">Retornar</app-button>
                                    <app-spacer [right]="8"></app-spacer>
                                    <app-button [danger]="true"
                                        (onClick)="handleApproval(false)">Rejeitar</app-button>
                                </ng-container> -->

                                <ng-container *ngIf="isApprover">
                                    <app-spacer [right]="24"></app-spacer>

                                    <app-button-with-dropdown
                                        label="Aprovar cadastro"
                                        (toggleShowOptions)="toggleShowOptions()"
                                        (onClick)="handleApproval(true)"
                                        [showOptions]="showCtaOptions"
                                        [disabled]="!approval.can_sign || approval.completed || !permissionObj.canChange">
                                        <button class="dropdown-button"
                                            style="color:var(--danger)"
                                            [disabled]="!(approval.can_sign && !approval.completed)"
                                            (click)="handleApproval(false,true)">
                                            Retornar
                                        </button>
                                        <button class="dropdown-button"
                                            style="color:var(--danger)"
                                            [disabled]="!(approval.can_sign && !approval.completed)"
                                            (click)="handleApproval(false)">
                                            Rejeitar
                                        </button>
                                        <button class="dropdown-button"
                                            (click)="redirectToEdit()">Editar
                                        </button>
                                        <button class="dropdown-button"
                                            [disabled]="approval.completed"
                                            (click)="handleApproval(true,false,true)">
                                            Homologar
                                        </button>
                                    </app-button-with-dropdown>
                                </ng-container>
                            </div>
                        </ng-container>

                        <ng-container *ngIf="sign_view">
                            <div
                                class="col-12 col-lg-4 d-flex justify-content-end">
                                <app-button
                                    (onClick)="handleRepresentativeSign()">Assinar
                                    como representante</app-button>
                            </div>
                        </ng-container>

                        <ng-container *ngIf="isSimplified">
                            <div
                                class="col-12 col-lg-4 d-flex justify-content-end">
                                <svg-icon
                                    src="assets/images/bagde_cedentesimplificado_interna.svg">
                                </svg-icon>
                            </div>
                        </ng-container>
                    </ng-container>
                </div>
            </div>
            <app-spacer [bottom]="24"></app-spacer>

            <ng-container *ngIf="signing">
                <ngx-skeleton-loader [theme]="{
                height: '140px',
                width: '100%',
                margin:'0'
              }">
                </ngx-skeleton-loader>
            </ng-container>


            <ng-container *ngIf="!signing">
                <app-approvation-steps
                    [approval]="approval"></app-approvation-steps>
            </ng-container>

            <app-spacer [bottom]="60"></app-spacer>

            <ng-container *ngIf="loading">
                <div class="row">
                    <div class="col-2" *ngFor="let number of [0,1,2,3,4,5]">
                        <ngx-skeleton-loader [theme]="{
                height: '38px',
                margin:'0'
              }">
                        </ngx-skeleton-loader>
                    </div>
                </div>
            </ng-container>

            <ng-container *ngIf="!loading">
                <div class="d-flex">
                    <app-spacer [left]="20"></app-spacer>
                    <app-tabs [tabs]="tabs" (onChangeTab)="onChangeTab($event)"
                        [currentTab]="tabIndex" [dashboardTab]="true">
                    </app-tabs>
                </div>
            </ng-container>

            <app-spacer [bottom]="34"></app-spacer>

            <div *ngIf="activedTab === 'dados-complementares'">
                <app-dashboard-approval-complementary>
                </app-dashboard-approval-complementary>
            </div>

            <div *ngIf="activedTab === 'profissional'">
                <app-dashboard-approval-professional>
                </app-dashboard-approval-professional>
            </div>

            <div *ngIf="activedTab === 'representantes'">
                <app-dashboard-approval-representatives>
                </app-dashboard-approval-representatives>
            </div>

            <div *ngIf="activedTab === 'prestadores'">
                <app-dashboard-approval-providers>
                </app-dashboard-approval-providers>
            </div>

            <div *ngIf="activedTab === 'rendimentos-patrimonios'">
                <app-dashboard-approval-income>
                </app-dashboard-approval-income>
            </div>

            <div *ngIf="activedTab === 'anexos'">
                <app-dashboard-approval-attachments (onClick)="getApproval()">
                </app-dashboard-approval-attachments>
            </div>

            <div *ngIf="activedTab === 'historico'">
                <app-dashboard-approval-accompaniment>
                </app-dashboard-approval-accompaniment>
            </div>

            <div *ngIf="activedTab === 'assinaturas'">
                <app-dashboard-approval-signatures>
                </app-dashboard-approval-signatures>
            </div>

            <div *ngIf="activedTab === 'vinculos'">
                <app-dashboard-approval-bonds [bonds]="bonds">
                </app-dashboard-approval-bonds>
            </div>

            <div *ngIf="activedTab === 'minutas'">
                <app-dashboard-approval-draft>
                </app-dashboard-approval-draft>
            </div>

            <div *ngIf="activedTab === 'composicao-acionaria'">
                <app-dashboard-approval-composition>
                </app-dashboard-approval-composition>
            </div>

            <div *ngIf="activedTab === 'grupos-e-poderes'">
                <app-dashboard-approval-powers>
                </app-dashboard-approval-powers>
            </div>

            <div *ngIf="activedTab === 'contratos'">
                <app-dashboard-approval-documents
                    [approval]="approval"></app-dashboard-approval-documents>
            </div>
        </div>
    </div>

    <ng-container *ngIf="!loading">
        <app-warning-modal [active]="warningModal"
            (onClose)="closeWarningModal()">
            <h3>Atenção</h3>
            <app-spacer [bottom]="24"></app-spacer>

            <ng-container *ngIf="approve && !resetApproval">
                <p>
                    Você tem certeza que deseja {{homologated ? 'homologar' :
                    'aprovar'}} o cadastro de
                    {{approval.register.type === 'PF' ?
                    approval.register.person.full_name :
                    approval.register.company.corporate_name}}
                </p>
                <app-spacer [bottom]="56"></app-spacer>

                <app-button (onClick)="closeWarningModal()" [center]="true"
                    [danger]="true" [disabled]="signing">
                    Cancelar
                </app-button>
                <app-spacer [bottom]="8"></app-spacer>
                <app-button (onClick)="signApproval(approve, homologated)"
                    [center]="true" class="add-button" [loading]="signing">
                    Sim, quero {{homologated?'homologar':'aprovar'}}
                </app-button>
            </ng-container>

            <ng-container *ngIf="!approve && !resetApproval">
                <form [formGroup]="reproveForm">
                    <p>
                        Você tem certeza que deseja reprovar o cadastro de
                        {{approval.register.type === 'PF' ?
                        approval.register.person.full_name :
                        approval.register.company.corporate_name}}
                    </p>
                    <app-spacer [bottom]="16"></app-spacer>
                    <app-input type="textarea" [group]="reproveForm"
                        controlName="description" [rows]="4"
                        label="Esclareça o motivo da reprovação" placeholder=""
                        [noResize]="true">
                    </app-input>
                    <app-spacer [bottom]="56"></app-spacer>

                    <app-button (onClick)="closeWarningModal()" [center]="true"
                        [disabled]="signing" class="add-button">
                        Cancelar
                    </app-button>
                    <app-spacer [bottom]="8"></app-spacer>
                    <app-button (onClick)="signApproval(approve)"
                        [center]="true" [loading]="signing" [danger]="true"
                        [disabled]="this.reproveForm.invalid">
                        Sim, quero reprovar
                    </app-button>
                </form>
            </ng-container>

            <ng-container *ngIf="resetApproval">
                <form [formGroup]="reproveForm">
                    <p>
                        Você tem certeza que deseja retornar o cadastro de
                        {{approval.register.type === 'PF' ?
                        approval.register.person.full_name :
                        approval.register.company.corporate_name}}

                    </p>
                    <app-spacer [bottom]="16"></app-spacer>
                    <app-input type="textarea" [group]="reproveForm"
                        controlName="description" [rows]="4"
                        label="Esclareça o motivo do retorno" placeholder=""
                        [noResize]="true">
                    </app-input>
                    <app-spacer [bottom]="56"></app-spacer>

                    <app-button (onClick)="closeWarningModal()" [center]="true"
                        [disabled]="signing" class="add-button">
                        Cancelar
                    </app-button>
                    <app-spacer [bottom]="8"></app-spacer>


                    <app-button (onClick)="signApproval(approve)"
                        [center]="true" [loading]="signing" [danger]="true"
                        [disabled]="this.reproveForm.invalid">
                        Sim, quero retornar
                    </app-button>
                </form>
            </ng-container>

        </app-warning-modal>
    </ng-container>


    <!-- <app-warning-modal [active]="confirmEditModal"
        (onClose)="confirmEditModal = false">
        <h3>Atenção</h3>
        <app-spacer [bottom]="24"></app-spacer>

        <p>
            Este cadastro possuí um rascunho em aprovação, deseja editar o
            rascunho?
        </p>
        <app-spacer [bottom]="56"></app-spacer>

        <app-button (onClick)="confirmEditModal = false" [center]="true"
            [danger]="true" [disabled]="signing">
            Cancelar
        </app-button>
        <app-spacer [bottom]="8"></app-spacer>
        <app-button (onClick)="redirectToEdit()" [center]="true"
            class="add-button" [loading]="signing">
            Sim, quero editar
        </app-button>
    </app-warning-modal> -->
</div>