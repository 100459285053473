import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/services/api/api.service';
import { SignupService } from 'src/app/services/signup/signup.service';

import Utils, { GetHelper } from 'src/app/helpers/utils';
import { StorageService } from 'src/app/services/storage/storage.service';
import { ToastService } from 'src/app/services/toast/toast.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import UIDefaultTheme from 'src/themes';
import Swal from 'sweetalert2';
import { LoadingService } from 'src/app/services/Loading/loading.service';
import { permission } from 'src/app/data/permissions';

@Component({
  selector: 'app-signup-unified-basic',
  templateUrl: './signup-unified-basic.component.html',
  styleUrls: ['./signup-unified-basic.component.scss'],
})
export class SignupUnifiedBasicComponent implements OnInit {
  steps: Step[] = [
    {
      icon: 'fas fa-fingerprint',
      svg: 'assets/images/icon-fingerprint-active.svg',
      title: 'Dados pessoais',
      slug: 'personal',
    },
    {
      icon: 'fas fa-unlock-alt',
      svg: 'assets/images/icon-lock.svg',
      title: 'Senha de acesso',
      slug: 'password',
    },
    {
      icon: 'fas fa-id-card-alt',
      svg: 'assets/images/icon-profile.svg',
      title: 'Perfil de usuário',
      slug: 'profile',
    },
    {
      icon: 'fas fa-ellipsis-h',
      svg: 'assets/images/icon-more.svg',
      title: '',
      slug: 'more',
    },
  ];

  message = ' Todos os campos são de preenchimento obrigatório';

  _activeStep = 0;

  loading = false;

  hided = false;

  validateForm = false;

  showSupportModal = false;

  label: string | null = null;
  name: string | null = '';
  isPF: boolean = false;
  role: string | null = null;

  haveInvite = false;

  set activeStep(val: number) {
    this._activeStep = val;
  }

  get activeStep() {
    return this._activeStep;
  }

  public UIDefaultTheme = UIDefaultTheme;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private apiService: ApiService,
    public signupService: SignupService,
    private storageService: StorageService,
    private toastService: ToastService,
    private authService: AuthService,
    public loadingService: LoadingService
  ) {
    this.signupService.submitBasicForm.subscribe(() => {
      this.proceed();
    });
  }

  ngOnInit(): void {
    let urlParts = this.router.url.split('/basic')[0].split('/');
    let range = urlParts.length;
    this.role = urlParts[range - 1];
    this.getBasicInfo(this.route.snapshot.params.token);

    document.onkeydown = (e) => {
      if (e.key === 'Enter') {
        this.proceed();
      }
    };
  }

  async changeActiveRegister(id: number) {
    try {
      await this.apiService.patch({
        route: `user/me/`,
        token: true,
        body: {
          active_register: id,
        },
      });

      const { data } = await this.apiService.get<ApiResponse<NewLoginResponse>>(
        {
          route: 'user/me/',
          token: true,
        }
      );

      this.authService.setUser(data);

      const {
        active_register: { register },
      } = data;

      this.router.navigate([
        `/signup/${register.role.applicable}/${register.type.toLowerCase()}`,
      ]);
    } catch (error) {
      console.error(error);
      this.toastService.show('error', 'Erro!', 'Erro ao alterar conta ativa');
    }
  }

  async getBasicInfo(token: string) {
    if (!token) return;

    try {
      const res = await this.apiService.get({
        route: `api/registration/invite/`,
        params: { token },
      });

      const user = this.authService.getUser();

      if (user) {
        const result = await Swal.fire({
          title: 'Atenção',
          text: `Você está autenticado como ${user.first_name} ${user.last_name}, deseja vincular este convite ao seu usuário atual?`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Sim, vincular',
          confirmButtonColor: '#3085d6',
          cancelButtonText: 'Não, voltar',
          cancelButtonColor: '#d33',
        });

        if (result.isConfirmed) {
          await this.apiService.post({
            route: `api/registration/invite_link/`,
            token: true,
            body: { token },
          });

          const { data } = await this.apiService.get<
            ApiResponse<RegisterUserResponse[]>
          >({
            route: `api/registration/register_user/`,
            token: true,
            params: {
              register__invite__token: token,
            },
          });

          const created_register = data[0];

          this.changeActiveRegister(created_register.id);
        } else {
          this.router.navigate(['/app/dashboard']);
        }

        return;
      }

      if (res.data) this.haveInvite = true;

      if (res.data.name) {
        this.name = res.data.name;
      }

      if (res.data.document) {
        this.isPF = res.data.document.length <= 14;
      }

      if (this.role === 'provider') {
        this.label = res.agent.map((_) => _.name).join(', ');
      }

      if (this.role === 'assignor') {
        this.label = `Cedente pessoa ${this.isPF ? 'física' : 'jurídica'}`;
      }

      if (this.role === 'guarantor') {
        this.label = `Avalista pessoa ${this.isPF ? 'física' : 'jurídica'}`;
      }

      if (res.data[0].data.document.length > 14) {
        this.name = res.data[0].data.name.toUpperCase();
      }
    } catch (error) {
      console.log(error);

      if (error.status === 404) {
        await Swal.fire({
          title: 'Atenção',
          text: `O token informado é inválido ou já foi utilizado.`,
          icon: 'warning',
          confirmButtonText: 'Ok',
          confirmButtonColor: '#3085d6',
        });

        this.router.navigate(['/app/dashboard']);
      }

      console.error(error);
    }
  }

  canProceed() {
    switch (this.activeStep) {
      case 0:
        return this.signupService.getBasicFormStatus('personal');

      case 1:
        return this.signupService.getBasicFormStatus('password');

      case 2:
        return this.signupService.getBasicFormStatus('profile');

      default:
        return true;
    }
  }

  getButtonIcon() {
    return this.canProceed()
      ? 'assets/images/icon_button_arrow.svg'
      : 'assets/images/icon_button_block.svg';
  }

  async validateEmail() {
    const personalData: BasicPersonalValues =
      this.signupService.getBasicFormData('personal');

    try {
      const res = await this.apiService.post<ValidateFieldResponse>({
        route: 'validate-email-exists/',

        body: { email: personalData.email },
      });

      return res.exists;
    } catch (error) {}

    return true;
  }

  async validateCpf() {
    const personalData: BasicPersonalValues =
      this.signupService.getBasicFormData('personal');

    try {
      const res = await this.apiService.post<ValidateFieldResponse>({
        route: 'validate-cpf-exists/',

        body: { cpf: personalData.document },
      });

      return res.exists;
    } catch (error) {}

    return true;
  }

  async validatePhone() {
    const personalData: BasicPersonalValues =
      this.signupService.getBasicFormData('personal');

    try {
      // const res = await this.apiService.post<ValidateFieldResponse>({
      //   route: 'validate-phone-exists/',

      //   body: { phone: Utils.onlyNumbers(personalData.phone_number) },
      // });

      // return res.exists;

      return false;
    } catch (error) {
      console.error(error);
    }

    return true;
  }

  toggleSupportModal() {
    this.showSupportModal = !this.showSupportModal;
  }

  async loginUser() {
    const { email, document }: BasicPersonalValues =
      this.signupService.getBasicFormData('personal');
    const { password }: BasicPasswordValues =
      this.signupService.getBasicFormData('password');
    const { type }: BasicProfileValues =
      this.signupService.getBasicFormData('profile');

    try {
      const { data } = await this.apiService.post<
        ApiResponse<NewLoginResponse>
      >({
        route: 'new-login/',
        body: { username: Utils.onlyNumbers(document), password },
      });

      let permissionsArr =
        data.active_register.permissions_role &&
        data.active_register.permissions_role.permissions.length > 0
          ? data.active_register.permissions_role.permissions
          : permission;

      this.authService.setToken(data.tokens.access);
      this.authService.setUser(data);
      this.authService.permissions = permissionsArr;
      this.storageService.set('permissions', permissionsArr, true);

      const { role, type } = data.active_register.register;

      const route = `/signup/${role.applicable}/${type.toLowerCase()}`;

      this.router.navigate([route]);

      return true;
    } catch (error) {
      return false;
    }
  }

  async registerUser() {
    this.loading = true;

    const personalData: BasicPersonalValues =
      this.signupService.getBasicFormData('personal');
    const personalHelper = new GetHelper(personalData);

    const passwordData: BasicPasswordValues =
      this.signupService.getBasicFormData('password');
    const passwordHelper = new GetHelper(passwordData);

    const profileData: BasicProfileValues =
      this.signupService.getBasicFormData('profile');
    const profileHelper = new GetHelper(profileData);

    const names = personalHelper.get('full_name').split(' ');

    const [first_name, ...last_names] = names;

    const last_name = last_names.join(' ');

    const payload = {
      user: {
        email: personalHelper.get('email'),
        username: Utils.onlyNumbers(personalHelper.get('document')),
        password: passwordHelper.get('password'),
        first_name: first_name,
        last_name: last_name,
        type: profileHelper.get('type'),
        role: profileHelper.get('id'),
        agent: personalHelper.get('agent') ?? [],
      },
      fund: personalHelper.get('fund'),
      invite: this.route.snapshot.params.token,
      profile: {
        document: {
          user: 1,
          number: Utils.onlyNumbers(personalHelper.get('document')),
          type:
            personalHelper.get('document_type') === 'other'
              ? personalHelper.get('document_name')
              : personalHelper.get('document_type'),
          dispatching_agency: null,
          uf: null,
          expedition_date: null,
        },
        nationality: Number(personalHelper.get('nacionality')),
      },

      phone: {
        user: 1,
        code: Number(personalHelper.get('phone_ddi')),
        number: Utils.onlyNumbers(personalHelper.get('phone_number')),
        branch: 0,
      },
    };

    try {
      const res = await this.apiService.post({
        route: 'new-register/',
        body: payload,
      });

      this.storageService.setPreUser(res);

      return res;
    } catch (error: any) {
      console.error(error);

      this.toastService.show(
        'error',
        'Erro!',
        `Erro ao concluir cadastro\n\r${JSON.stringify(error.error)}`
      );
    }

    this.loading = false;
    return false;
  }

  async proceed() {
    if (this.canProceed()) {
      switch (this.activeStep) {
        case 0:
          this.loading = true;
          try {
            const response = await Promise.all([
              this.validateEmail(),
              this.validateCpf(),
              this.validatePhone(),
            ]);
            this.loading = false;

            let message = [];

            if (response[0]) {
              message.push('Este e-mail ja está em uso');
            } else if (response[1]) {
              message.push('Este CPF ja está em uso');
            } else if (response[2]) {
              message.push('Este Telefone ja está em uso');
            }

            if (response.every((r) => !r)) {
              const personalData: BasicPersonalValues =
                this.signupService.getBasicFormData('personal');

              this.storageService.setPreUser({ email: personalData.email });

              let sendSMS = true;

              if (
                window.location.href.includes('stage') ||
                window.location.href.includes('localhost')
              ) {
                // const res = await Swal.fire({
                //   title: 'Atenção',
                //   text: 'Você está em abiente de teste, deseja enviar a validação de token?',
                //   showConfirmButton: true,
                //   confirmButtonText: 'Sim, enviar',
                //   confirmButtonColor: '#28a745',
                //   showCancelButton: true,
                //   cancelButtonText: 'Não, continuar',
                //   cancelButtonColor: '#dc3545',
                // });
                // sendSMS = res.isConfirmed;
                sendSMS = false;
              }

              if (sendSMS) {
                await this.apiService.post({
                  route: 'request-token/',
                  body: {
                    /* username: personalData.document, */
                    email: personalData.email,
                    phone: Utils.onlyNumbers(personalData.phone_number),
                    channel: 'all',
                  },
                });

                this.validateForm = true;
              } else {
                this.activeStep = 1;
                this.message = 'Defina uma senha para seguir seu cadastro';
              }
            } else {
              this.toastService.show('error', 'Erro!', message.join('\n\r'));
            }
          } catch (error) {
            this.toastService.show(
              'error',
              'Erro',
              'Erro ao verificar dados e enviar token'
            );
          }

          break;

        case 1:
          if (this.haveInvite) {
            const roles = this.signupService.getRoles();
            const type = this.isPF ? 'PF' : 'PJ';
            // teste
            const profile = roles
              .filter(
                (role) =>
                  role.is_visible &&
                  (role.applicable === 'all' || role.applicable === this.role)
              )
              .find((_role) => _role.slug.split('-')[2] === type);

            const roleValues = profile?.slug ? profile.slug.split('-') : [];

            this.signupService.setBasicFormData('profile', {
              type: roleValues[1],
              role: roleValues[2],
              id: profile?.id,
            });

            if (this.isPF) {
              const _registered = await this.registerUser();

              if (_registered) {
                // this.toastService.show(
                //   'info',
                //   'Sucesso!',
                //   'Cadastro concluido, acessando na plataforma...'
                // );

                const res = await this.loginUser();

                if (!res) {
                  this.toastService.show(
                    'info',
                    'Sucesso',
                    'Erro ao acessar plataforma, logue-se na aplicação'
                  );
                  this.router.navigate(['/login']);
                }
              }
            } else {
              this.hided = false;
              this.activeStep = 3;
            }

            this.message = `Alguns campos são de preenchimento obrigatório`;
            break;
          }

          this.activeStep = 2;
          this.message = 'Selecione uma opção para seguir seu cadastro';
          this.hided = true;
          break;

        case 2:
          const personalValues: BasicProfileValues =
            this.signupService.getBasicFormData('profile');

          if (personalValues) {
            if (personalValues.type === 'PF') {
              const _registered = await this.registerUser();

              if (_registered) {
                // this.toastService.show(
                //   'info',
                //   'Sucesso!',
                //   'Cadastro concluido, acessando na plataforma...'
                // );

                const res = await this.loginUser();

                if (!res) {
                  this.toastService.show(
                    'info',
                    'Sucesso',
                    'Erro ao acessar plataforma, logue-se na aplicação'
                  );
                  this.router.navigate(['/login']);
                }
              }
            } else {
              this.hided = false;
              this.activeStep = 3;
            }
          }
          this.message = `Alguns campos são de preenchimento obrigatório`;
          break;

        case 3:
          const _registered = await this.registerUser();

          if (_registered) {
            // this.toastService.show(
            //   'info',
            //   'Sucesso!',
            //   'Cadastro concluido, acessando na plataforma...'
            // );

            const res = await this.loginUser();

            if (!res) {
              this.toastService.show(
                'info',
                'Sucesso',
                'Erro ao acessar plataforma, logue-se na aplicação'
              );
              this.router.navigate(['/login']);
            }
          }
          break;

        default:
          break;
      }
    }
  }

  async onValidate({ valid }: ValidateResponse) {
    this.loading = true;

    if (valid) {
      this.toastService.show('info', 'Sucesso!', 'Token validado com sucesso');

      this.activeStep = 1;
      this.message = 'Defina uma senha para seguir seu cadastro';
    } else {
      this.toastService.show('error', 'Erro!', 'Falha ao validar o token');
    }

    this.validateForm = false;

    this.loading = false;
  }
}
