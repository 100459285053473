<ng-container *ngIf="loading">
    <ngx-skeleton-loader [theme]="{width: '100%',height: '400px'}">
    </ngx-skeleton-loader>
</ng-container>

<div class="dash-card no-padding" *ngIf="!loading">
    <div class="dash-card-header d-block">
        <h2>Fundos associados</h2>
        <app-spacer [bottom]="16"></app-spacer>

        <div class="d-flex align-items-center justify-content-end">
            <app-button [inverted]="true" class="add-button"
                (onClick)="showFilterModal = true">
                <svg-icon src="assets/images/icon_filter.svg"></svg-icon>
                Aplicar filtros
            </app-button>
        </div>
    </div>

    <div class="dash-card-body padding-container">

        <table class="bordered">
            <thead>
                <tr>
                    <!-- <th></th> -->
                    <th>Razão social</th>
                    <th>CNPJ</th>
                    <th>Nome abreviado</th>
                    <!-- <th></th> -->
                </tr>
            </thead>

            <tbody>
                <tr *ngFor="let item of funds">
                    <!-- <td style="width: 75px;">
                        <button (click)="showDetails = !showDetails">
                            <i class="fas" [class.fa-chevron-up]="!showDetails"
                                [class.fa-chevron-down]="showDetails"></i>
                        </button>
                    </td> -->

                    <td>
                        <div>{{item.name}}</div>
                        <!-- <div *ngIf="showDetails" class="mt-2">
                            <p>Razão social: {{item.companyName}}</p>
                            <p>Carteira: {{item.wallet}}</p>
                        </div> -->
                    </td>

                    <td>
                        {{formatDocument(item.document)}}
                    </td>

                    <td>{{item.short_name}}</td>

                    <!-- <td>
                        <div class="dash-card-item-buttons">
                            <button class="dash-item-button"
                                (click)="unboundFund(item.id)"
                                *ngIf="permissionObj.canChange">
                                <svg-icon
                                    src="assets/images/icon-unlink.svg"></svg-icon>
                            </button>
                        </div>
                    </td> -->
                </tr>

                <tr *ngIf="funds.length === 0">
                    <td>Nenhum fundo adicionado</td>
                </tr>
            </tbody>
            <app-spacer [bottom]="28"></app-spacer>
        </table>
    </div>
</div>

<div class="col-12 ml-auto mr-auto">
    <div class="dash-card-pagination">
        <app-pagination [data]="funds" [count]="count" [show]="!loading"
            [offset]="offset" (onPageChange)="changePageData($event)"
            [dashPagination]="true">
        </app-pagination>
    </div>
</div>



<app-side-modal [active]="showFilterModal" title="Filtrar listagem"
    (onClose)="showFilterModal = false">
    <form [formGroup]="filterForm">
        <div class="row">
            <div class="col-12">
                <app-input [group]="filterForm" controlName="fund_name"
                    placeholder="Digite" label="Fundo">
                </app-input>
                <app-spacer [bottom]="16"></app-spacer>
            </div>

            <div class="col-12">
                <app-input [group]="filterForm" controlName="fund_document"
                    placeholder="Digite" label="CNPJ">
                </app-input>
                <app-spacer [bottom]="16"></app-spacer>
            </div>
        </div>

        <app-spacer [bottom]="32"></app-spacer>

        <div class="row button-sticky">
            <div class="col-12 col-lg-6">
                <app-button (onClick)="resetForm()" class="add-button"
                    [phantom]="true">
                    Limpar filtros
                </app-button>
            </div>

            <div class="col-12 col-lg-6">
                <div class="text-right">
                    <app-button (onClick)="filterData()" [loading]="loading"
                        [disabled]="loading">
                        Aplicar filtros
                    </app-button>
                </div>
            </div>
        </div>
    </form>
</app-side-modal>