<div class="shareholder">
    <div class="row">
        <div class="col-12 ml-auto mr-auto">

            <ng-container *ngIf="!loading">
                <!-- <app-back-button></app-back-button> -->
            </ng-container>

            <div class="shareholder-card">
                <div class="row w-100 no-gutters">
                    <div class="col-12 col-lg-8 d-flex flex-row"
                        *ngIf="loading">
                        <div class="shareholder-profile-initials bg-none">
                            <ngx-skeleton-loader appearance="circle" [theme]="{
                                    width: '64px',
                                    height: '64px',
                                    margin:'0'
                                  }">
                            </ngx-skeleton-loader>
                        </div>
                        <app-spacer [right]="32"></app-spacer>
                        <div class="d-flex flex-column">
                            <ngx-skeleton-loader [theme]="{
                                    height: '44px',
                                    width: '500px',
                                    margin:'0'
                                  }">
                            </ngx-skeleton-loader>
                            <div class="d-flex flex-row align-items-center">
                                <ngx-skeleton-loader [theme]="{
                                    height: '24px',
                                    width:'150px'
                                }"></ngx-skeleton-loader>
                            </div>
                        </div>
                    </div>

                    <div class="col-12 col-lg-8 d-flex flex-row"
                        *ngIf="!loading">
                        <div class="shareholder-profile-initials">
                            <span>
                                {{fund.name ? getInitials(fund.name) : '0*'}}
                            </span>
                        </div>
                        <app-spacer [right]="32"></app-spacer>
                        <div class="d-flex flex-column">
                            <span class="shareholder-profile-name">
                                {{fund.name ?? '0*'}}
                            </span>
                            <div class="d-flex flex-row align-items-center">
                                <span class="shareholder-profile-type">
                                    {{getChoicesName('cvm_classification_choices',
                                    fund.cvm_classification)?.label}}
                                </span>
                            </div>
                        </div>
                    </div>

                    <div class="col-12 col-lg-4 d-flex justify-content-end">
                        <ng-container *ngIf="loading">
                            <ngx-skeleton-loader [theme]="{
                            height: '44px',
                            width: '139px',
                            margin:'0'
                          }">
                            </ngx-skeleton-loader>
                        </ng-container>

                        <ng-container *ngIf="!loading">
                            <app-badge *ngIf="approval.completed"
                                [approval]="approval">
                            </app-badge>

                            <ng-container *ngIf="permissionObj.canChange">
                                <app-button (onClick)="rediretToEdit()"
                                    [outline]="true">
                                    Editar
                                </app-button>
                            </ng-container>

                            <ng-container
                                *ngIf="approval.can_sign && !approval.completed && permissionObj.canChange">
                                <app-button
                                    (onClick)="handleApproval(true)">Aprovar</app-button>
                                <app-spacer [right]="8"></app-spacer>
                                <app-button [danger]="true"
                                    (onClick)="handleApproval(false,true)">Retornar</app-button>
                                <app-spacer [right]="8"></app-spacer>
                                <app-button [danger]="true"
                                    (onClick)="handleApproval(false)">Rejeitar</app-button>
                            </ng-container>
                        </ng-container>
                    </div>
                </div>
            </div>
            <app-spacer [bottom]="24"></app-spacer>

            <ng-container *ngIf="loading">
                <ngx-skeleton-loader [theme]="{
                height: '140px',
                width: '100%',
                margin:'0'
              }">
                </ngx-skeleton-loader>
            </ng-container>

            <ng-container *ngIf="!loading">
                <app-approvation-steps
                    [approval]="approval"></app-approvation-steps>
            </ng-container>

            <app-spacer [bottom]="60"></app-spacer>

            <ng-container *ngIf="loading">
                <div class="row">
                    <div class="col-2" *ngFor="let number of [0,1,2,3,4,5]">
                        <ngx-skeleton-loader [theme]="{
                height: '38px',
                margin:'0'
              }">
                        </ngx-skeleton-loader>
                    </div>
                </div>
            </ng-container>

            <ng-container *ngIf="!loading">
                <div class="d-flex">
                    <app-spacer [left]="20"></app-spacer>
                    <app-tabs [tabs]="tabs" (onChangeTab)="onChangeTab($event)"
                        [currentTab]="tabIndex" [dashboardTab]="true">
                    </app-tabs>
                </div>
            </ng-container>

            <app-spacer [bottom]="24"></app-spacer>

            <div *ngIf="activedTab === 'dados-basicos'">
                <app-dashboard-fund-approval-basic [isLoading]="loading">
                </app-dashboard-fund-approval-basic>
            </div>

            <ng-container *ngIf="!loading">
                <div *ngIf="activedTab === 'transferencias'">
                    <app-dashboard-fund-approval-transfers>
                    </app-dashboard-fund-approval-transfers>
                </div>

                <div *ngIf="activedTab === 'dados-complementares'">
                    <app-dashboard-fund-approval-complementary>
                    </app-dashboard-fund-approval-complementary>
                </div>

                <div *ngIf="activedTab === 'cotas'">
                    <app-dashboard-fund-approval-quotas>
                    </app-dashboard-fund-approval-quotas>
                </div>

                <div *ngIf="activedTab === 'prestadores-servico'">
                    <app-dashboard-fund-approval-providers>
                    </app-dashboard-fund-approval-providers>
                </div>

                <div *ngIf="activedTab === 'taxas'">
                    <app-dashboard-fund-approval-tax>
                    </app-dashboard-fund-approval-tax>
                </div>

                <div *ngIf="activedTab === 'bancarios'">
                    <app-dashboard-fund-approval-banking>
                    </app-dashboard-fund-approval-banking>
                </div>

                <div *ngIf="activedTab === 'anexos'">
                    <app-dashboard-fund-approval-files>
                    </app-dashboard-fund-approval-files>
                </div>

                <div *ngIf="activedTab === 'modelos'">
                    <app-dashboard-fund-approval-attachments>
                    </app-dashboard-fund-approval-attachments>
                </div>

                <div *ngIf="activedTab === 'historico'">
                    <app-dashboard-fund-approval-accompaniment>
                    </app-dashboard-fund-approval-accompaniment>
                </div>

                <!-- <div *ngIf="activedTab === 'contratos'">
                <app-dashboard-fund-approval-documents>
                </app-dashboard-fund-approval-documents>
            </div> -->

                <!-- <div *ngIf="activedTab === 'cotistas'">
                <app-dashboard-fund-approval-shareholder>
                </app-dashboard-fund-approval-shareholder>
            </div> -->

                <div *ngIf="activedTab === 'cedentes'">
                    <app-dashboard-fund-approval-assignor>
                    </app-dashboard-fund-approval-assignor>
                </div>
            </ng-container>
        </div>
    </div>


    <app-warning-modal [active]="warningModal" *ngIf="!loading">
        <h3>Atenção</h3>
        <app-spacer [bottom]="24"></app-spacer>

        <ng-container *ngIf="approve && !resetApproval">
            <p>
                Você tem certeza que deseja aprovar o cadastro de
                {{approval.fund.fantasy_name}}
            </p>
            <app-spacer [bottom]="56"></app-spacer>

            <app-button (onClick)="closeWarningModal()" [center]="true"
                [danger]="true" [disabled]="signing">
                Cancelar
            </app-button>
            <app-spacer [bottom]="8"></app-spacer>
            <app-button (onClick)="signApproval(approve)" [center]="true"
                class="add-button" [loading]="signing">
                Sim, quero aprovar
            </app-button>
        </ng-container>

        <ng-container *ngIf="!approve && !resetApproval">
            <form [formGroup]="reproveForm">
                <p>
                    Você tem certeza que deseja reprovar o fundo
                    {{approval.fund.fantasy_name}}
                </p>
                <app-spacer [bottom]="16"></app-spacer>
                <app-input type="textarea" [group]="reproveForm"
                    controlName="description" [rows]="4"
                    label="Esclareça o motivo da reprovação" placeholder=""
                    [noResize]="true">
                </app-input>
                <app-spacer [bottom]="56"></app-spacer>

                <app-button (onClick)="closeWarningModal()" [center]="true"
                    [disabled]="signing" class="add-button">
                    Cancelar
                </app-button>
                <app-spacer [bottom]="8"></app-spacer>
                <app-button (onClick)="signApproval(approve)" [center]="true"
                    [loading]="signing" [danger]="true"
                    [disabled]="this.reproveForm.invalid">
                    Sim, quero reprovar
                </app-button>
            </form>
        </ng-container>

        <ng-container *ngIf="resetApproval">
            <form [formGroup]="reproveForm">
                <p>
                    Você tem certeza que deseja retornar o fundo
                    {{approval.fund.fantasy_name}}
                </p>
                <app-spacer [bottom]="16"></app-spacer>
                <app-input type="textarea" [group]="reproveForm"
                    controlName="description" [rows]="4"
                    label="Esclareça o motivo do retorno" placeholder=""
                    [noResize]="true">
                </app-input>
                <app-spacer [bottom]="56"></app-spacer>

                <app-button (onClick)="closeWarningModal()" [center]="true"
                    [disabled]="signing" class="add-button">
                    Cancelar
                </app-button>
                <app-spacer [bottom]="8"></app-spacer>


                <app-button (onClick)="signApproval(approve)" [center]="true"
                    [loading]="signing" [danger]="true"
                    [disabled]="this.reproveForm.invalid">
                    Sim, quero retornar
                </app-button>
            </form>
        </ng-container>

    </app-warning-modal>
</div>