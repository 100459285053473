<div class="dashboard h-100">
    <div class="dashboard-card no-padding d-flex flex-column justify-content-between"
        style="max-width: 800px">
        <div class="row">
            <div class="col-12">
                <div class=" d-flex flex-column padding-container"
                    *ngIf="loading || groups.length > 0">
                    <ng-container *ngIf="loading">
                        <ngx-skeleton-loader [theme]="{
                            height: '24px',
                            width: '30px',
                            margin:'0'
                          }">
                        </ngx-skeleton-loader>

                        <ngx-skeleton-loader [theme]="{
                                height: '27px',
                                width: '100px',
                            marginBottom:'30px'
                              }">
                        </ngx-skeleton-loader>
                    </ng-container>

                    <ng-container *ngIf="!loading && groups.length > 0">
                        <span class="card-info-number">{{groups.length > 9
                            ?
                            '' :
                            0}}{{groups.length}}</span>
                        <span class="card-info-title">Usuários e Grupos</span>
                    </ng-container>
                </div>
            </div>

            <div class="col-12 dashboard-card-body">
                <ng-container *ngIf="loading">
                    <table #table>
                        <thead>
                            <tr>
                                <th>
                                    <ngx-skeleton-loader [theme]="{
                                    height: '15px',
                                    width: '125px',
                                    marginBottom:'0'                            
                                  }">
                                    </ngx-skeleton-loader>
                                </th>

                                <th class="text-right">
                                    <ngx-skeleton-loader [theme]="{
                                    height: '15px',
                                    width: '125px',
                                    marginBottom:'0'                            
                                  }">
                                    </ngx-skeleton-loader>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let number of [0,1,2,3,4,5,6]">
                                <td>
                                    <ngx-skeleton-loader [theme]="{
                                        height: '15px',
                                        width: '100px',
                                    marginBottom:'0'                            
                                      }">
                                    </ngx-skeleton-loader>
                                </td>
                                <td class="item-number">
                                    <ngx-skeleton-loader [theme]="{
                                    height: '15px',
                                    width: '30px',
                                    marginBottom:'0'
                                  }">
                                    </ngx-skeleton-loader>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </ng-container>

                <table *ngIf="!loading && groups.length > 0">
                    <thead>
                        <tr>
                            <th>Grupo</th>
                            <th style="text-align:right;">Usuários associados
                            </th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let permission of visible_groups">
                            <td class="doc-item"
                                [style.max-width.px]="tableSize"
                                style="width: 100%;">
                                {{permission.group}}</td>
                            <td style="text-align:right;">
                                {{permission.users}}
                            </td>
                            <td>
                                <div class="table-options">
                                    <button (click)="editGroup(permission.id)"
                                        *ngIf="permissionObj.canChange">
                                        <i class="fas fa-pen"></i>
                                    </button>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div class="col-12">
                <div class="padding-container d-flex flex-row justify-content-between"
                    *ngIf="loading || groups.length > 0">
                    <ng-container *ngIf="loading">
                        <td>
                            <ngx-skeleton-loader [theme]="{
                                height: '41px',
                                        width: '180px',
                            marginBottom:'0'                            
                              }">
                            </ngx-skeleton-loader>

                        <td>
                            <ngx-skeleton-loader [theme]="{
                                height: '41px',
                                width: '180px',
                                marginBottom:'0'                            
                                  }">
                            </ngx-skeleton-loader>
                        </td>
                    </ng-container>

                    <ng-container *ngIf="!loading && groups.length > 0">
                        <app-button [inverted]="true"
                            (onClick)="redirect('/app/settings/groups')">
                            Ver todos
                        </app-button>

                        <app-button (click)="addPermission()" class="add-button"
                            *ngIf="permissionObj.canAdd"> <i
                                class="fas fa-plus-circle mr-2"></i>
                            Adicionar grupo de usuários
                        </app-button>
                    </ng-container>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-12" *ngIf="groups.length === 0 && !loading">
                <div class="empty-card">
                    <div class="img-container">
                        <img src="/assets/images/forgot-password-icon.png"
                            alt="icone">
                    </div>

                    <h3>Defina os grupos de usuários</h3>

                    <p>Ainda não existem grupos cadastrados</p>

                    <app-button (click)="addPermission()" class="add-button"
                        *ngIf="permissionObj.canAdd">
                        <i class="fas fa-plus-circle mr-2"></i>
                        Adicionar grupo de permissões
                    </app-button>
                </div>
            </div>
        </div>
    </div>
</div>