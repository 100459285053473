<ng-container *ngIf="loading">
    <ngx-skeleton-loader [theme]="{width: '100%',height: '400px'}">
    </ngx-skeleton-loader>
</ng-container>


<div class="dash-card no-padding" *ngIf="!loading">
    <div class="dash-card-body pt-4">
        <div>
            <h2 style="padding-left:30px;">Usuários associados</h2>
            <app-spacer [bottom]="16"></app-spacer>

            <div class="d-flex align-items-center justify-content-end"
                style="padding-right:30px;">
                <app-button [inverted]="true" class="add-button"
                    (onClick)="showFilterModal = true">
                    <svg-icon src="assets/images/icon_filter.svg"></svg-icon>
                    Aplicar filtros
                </app-button>
            </div>
        </div>
        <app-spacer [bottom]="24"></app-spacer>

        <table class="bordered">
            <thead>
                <tr>
                    <th>Nome/Razão social</th>
                    <th>Departamento</th>
                    <th>Grupos de usuários</th>
                    <th>Documento</th>
                    <!-- <th>E-mail</th> -->
                    <th>Status</th>
                    <th></th>
                </tr>
            </thead>

            <tbody>
                <tr *ngFor="let item of data">
                    <td>{{item.name}}</td>

                    <td>{{item.department}}</td>
                    <td>{{item.group}}</td>

                    <td>{{formatDocument(item.document_number)}}</td>

                    <!-- <td>{{item.email}}</td> -->

                    <td>
                        <div class="status-tag"
                            [class.-inactive]="item.status === 'Inativa'">
                            {{item.status}}
                        </div>
                    </td>
                    <td>
                        <button class="button"
                            (click)="handleChangeStatus(item.id)"
                            *ngIf="permissionObj.canChange"
                            [title]="item.status === 'Inativa' ? 'Ativar usuário' : 'Bloquear usuário'">
                            <i class="fas fa-unlock-alt"></i>
                        </button>
                        <app-spacer [right]="8"></app-spacer>

                        <button class="button"
                            (click)="redirectToDetails(item.id)"
                            *ngIf="permissionObj.canChange" title="Detalhes">
                            <i class="fas fa-eye"></i>
                        </button>
                    </td>
                </tr>

                <tr *ngIf="data.length === 0">
                    <td>Nenhum usuário adicionado</td>
                </tr>
            </tbody>
            <app-spacer [bottom]="28"></app-spacer>
        </table>
    </div>
</div>

<div class="col-12 ml-auto mr-auto">
    <div class="dash-card-pagination">
        <app-pagination [data]="data" [count]="count" [show]="!loading"
            [offset]="offset" (onPageChange)="changePageData($event)"
            [dashPagination]="true">
        </app-pagination>
    </div>
</div>

<app-side-modal [active]="showFilterModal" title="Filtrar listagem"
    (onClose)="showFilterModal = false">
    <form [formGroup]="filterForm">
        <div class="row">
            <div class="col-12">
                <app-input [group]="filterForm" controlName="user_name"
                    placeholder="Digite" label="Usuário">
                </app-input>
                <app-spacer [bottom]="16"></app-spacer>
            </div>

            <div class="col-12">
                <app-masked-input [group]="filterForm"
                    controlName="user_document" label="Documento"
                    mask="CPF_CNPJ" placeholder="Insira seu documento">
                </app-masked-input>
                <app-spacer [bottom]="16"></app-spacer>
            </div>

            <div class="col-12">
                <app-select [items]="statusOptions" [group]="filterForm"
                    controlName="user_status" placeholder="Selecione"
                    label="Status">
                </app-select>
                <app-spacer [bottom]="16"></app-spacer>
            </div>

            <div class="col-12">
                <app-select [items]="departmentOptions" [group]="filterForm"
                    controlName="user_department" placeholder="Selecione"
                    label="Departamento">
                </app-select>
                <app-spacer [bottom]="16"></app-spacer>
            </div>

            <div class="col-12">
                <app-select [items]="permissionGroupOptions"
                    [group]="filterForm" controlName="user_group_permission"
                    placeholder="Selecione" label="Grupo de permissão">
                </app-select>
                <app-spacer [bottom]="16"></app-spacer>
            </div>
        </div>

        <app-spacer [bottom]="32"></app-spacer>

        <div class="row button-sticky">
            <div class="col-12 col-lg-6">
                <app-button (onClick)="resetForm()" class="add-button"
                    [phantom]="true">
                    Limpar filtros
                </app-button>
            </div>

            <div class="col-12 col-lg-6">
                <div class="text-right">
                    <app-button (onClick)="filterData()" [loading]="loading"
                        [disabled]="loading">
                        Aplicar filtros
                    </app-button>
                </div>
            </div>
        </div>
    </form>
</app-side-modal>