import { Component, OnInit } from '@angular/core';
import DDIOptions from 'src/app/data/ddi';
import { ApiService } from 'src/app/services/api/api.service';
import { SignupService } from 'src/app/services/signup/signup.service';
import { ToastService } from 'src/app/services/toast/toast.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-dashboard-permissions-details',
  templateUrl: './dashboard-permissions-details.component.html',
  styleUrls: ['./dashboard-permissions-details.component.scss'],
})
export class DashboardPermissionsDetailsComponent implements OnInit {
  permissionObj = {
    canChange: true,
  };

  DDIOptions = DDIOptions;

  loading = true;

  data: PermissionDetailsValue;
  permissionsArray: GroupPermission[] = [];

  constructor(
    private api: ApiService,
    private toast: ToastService,
    public signupService: SignupService,
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    if (!this.authService.verifyPermission(['de_can_view_group_permission'])) {
      this.toast.show(
        'error',
        'Aviso',
        'Você não tem permissão para acessar essa página.'
      );
      this.router.navigateByUrl('/app/dashboard');
    }

    this.setPermissions();
    this.getData();
  }

  async getData() {
    try {
      const id = this.route.snapshot.params.id;
      const res = await this.api.get({
        route: `api/v2/group/${id}/`,
        token: true,
      });

      this.data = res;

      this.formatList(this.data.permissions);
    } catch (error) {
      console.error(error);
      this.toast.show('error', 'Erro', 'Ocorreu um erro ao carregar os dados.');
    }
    this.loading = false;
  }

  addPermission() {
    this.router.navigateByUrl(
      `/app/settings/permissions/update/${this.route.snapshot.params.id}`
    );
  }

  getInitials(user: any) {
    const full_name = user.corporate_name.split(' ');
    const initials = full_name[0].charAt(0) + full_name[1].charAt(0);

    return initials;
  }

  getFundInitials(name: any) {
    const companyNameArray = name.split(' ');
    const initials = companyNameArray
      .map((word: string) => word.charAt(0))
      .join('');
    return `${initials[0].toUpperCase()}${
      initials[1] ? initials[1].toUpperCase() : ''
    }`;
  }

  linkLabel(audit: any) {
    if (audit) {
      return `Vínculos de ${audit.corporate_name}`;
    }
    return '';
  }

  formatList(list: EachPermission[]) {
    let arr: GroupPermission[] = [];

    list.forEach((item) => {
      const finded = arr.find((el) => el.type === item.type);

      if (finded) {
        finded.permissions.push(item);
      } else {
        arr.push({
          type: item.type,
          permissions: [item],
        });
      }
    });

    this.permissionsArray = arr;
  }

  setPermissions() {
    const permissions = Object.keys(this.permissionObj).map(
      (key) =>
        `de_can_${key.split('can').join('').toLowerCase()}_group_permission`
    );

    permissions.forEach((permission, idx) => {
      this.permissionObj[Object.keys(this.permissionObj)[idx]] =
        this.authService.verifyPermission(permission);
    });
  }
}
