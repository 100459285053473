<ng-container *ngIf="loading">
    <ngx-skeleton-loader [theme]="{
                        width: '100%',
                        height: '400px'
                      }">
    </ngx-skeleton-loader>
</ng-container>

<div class="dash-card no-padding" *ngIf="!loading">
    <div class="dash-card-header d-block">
        <ng-container *ngIf="!loading">
            <h2>Empresas</h2>

            <app-spacer [bottom]="16"></app-spacer>

            <div class="d-flex align-items-center justify-content-end">
                <app-button [inverted]="true" class="add-button"
                    (onClick)="showFilterModal = true">
                    <svg-icon src="assets/images/icon_filter.svg"></svg-icon>
                    Aplicar filtros
                </app-button>
            </div>
        </ng-container>
    </div>

    <div class="dash-card-body padding-container">
        <table class="bordered">
            <thead>
                <tr>
                    <th>Empresa</th>
                    <th>CNPJ</th>
                    <th>Telefone</th>
                    <th>Status</th>
                    <th></th>
                </tr>
            </thead>

            <tbody>
                <tr *ngFor="let item of data">
                    <td>{{item.company}}</td>

                    <td>
                        {{formatDocument(item.document_number)}}
                    </td>

                    <td>
                        {{item.phone ? formatPhone(item.phone) : ''}}
                    </td>

                    <td>
                        <div class="status-tag"
                            [class.-inactive]="item.status === 'Inativa'">
                            {{item.status}}
                        </div>
                    </td>

                    <td>
                        <div class="dash-card-item-buttons">
                            <button class="button"
                                (click)="handleChangeStatus(item.id)"
                                *ngIf="permissionObj.canChange">
                                <i class="fas fa-unlock-alt"
                                    [title]="item.status === 'Inativa' ? 'Ativar empresa' : 'Bloquear empresa'"></i>
                            </button>
                            <app-spacer [right]="8"></app-spacer>

                            <button class="dash-item-button"
                                (click)="redirectToDetails(item.id)">
                                <i class="fas fa-eye"></i>
                            </button>
                        </div>
                    </td>
                </tr>

                <tr *ngIf="data.length === 0">
                    <td>Nenhuma empresa adicionada</td>
                </tr>
            </tbody>
            <app-spacer [bottom]="28"></app-spacer>
        </table>
    </div>
</div>

<div class="col-12 ml-auto mr-auto">
    <div class="dash-card-pagination">
        <app-pagination [data]="data" [count]="count" [show]="!loading"
            [offset]="offset" (onPageChange)="changePageData($event)"
            [dashPagination]="true">
        </app-pagination>
    </div>
</div>

<app-side-modal [active]="showFilterModal" title="Filtrar listagem"
    (onClose)="showFilterModal = false">
    <form [formGroup]="filterForm">
        <div class="row">
            <div class="col-12">
                <app-input [group]="filterForm" controlName="name"
                    placeholder="Digite" label="Empresa">
                </app-input>
                <app-spacer [bottom]="16"></app-spacer>
            </div>

            <div class="col-12">
                <app-masked-input [group]="filterForm" controlName="document"
                    mask="00.000.000/0000-00" label="CNPJ"
                    placeholder="00.000.000/0000-00">
                </app-masked-input>
                <app-spacer [bottom]="16"></app-spacer>
            </div>

            <div class="col-12">
                <app-select [items]="statusOptions" [group]="filterForm"
                    controlName="status" placeholder="Selecione" label="Status">
                </app-select>
                <app-spacer [bottom]="16"></app-spacer>
            </div>
        </div>

        <app-spacer [bottom]="32"></app-spacer>

        <div class="row button-sticky">
            <div class="col-12 col-lg-6">
                <app-button (onClick)="resetForm()" class="add-button"
                    [phantom]="true">
                    Limpar filtros
                </app-button>
            </div>

            <div class="col-12 col-lg-6">
                <div class="text-right">
                    <app-button (onClick)="filterData()" [loading]="loading"
                        [disabled]="loading">
                        Aplicar filtros
                    </app-button>
                </div>
            </div>
        </div>
    </form>
</app-side-modal>