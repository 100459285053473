<div class="dash">
    <div class="dash-card no-padding">
        <div class="dash-card-header d-block">
            <ng-container *ngIf="loading">
                <ngx-skeleton-loader [theme]="{
                            width: '80px',
                            height: '25px'
                          }">
                </ngx-skeleton-loader>
            </ng-container>

            <ng-container *ngIf="!loading">
                <h2>Grupos de usuários</h2>

                <div class="d-flex align-items-center justify-content-end">
                    <app-button [inverted]="true" class="add-button"
                        (onClick)="showFilterModal = true">
                        <svg-icon
                            src="assets/images/icon_filter.svg"></svg-icon>
                        Aplicar filtros
                    </app-button>
                </div>
            </ng-container>
        </div>

        <div class="dash-card-body padding-container">
            <ng-container *ngIf="loading">
                <table #table>
                    <thead>
                        <tr>
                            <th>
                                <ngx-skeleton-loader [theme]="{
                                        height: '15px',
                                        width: '125px',
                                        marginBottom:'0'
                                      }">
                                </ngx-skeleton-loader>
                            </th>

                            <th class="text-right">
                                <ngx-skeleton-loader [theme]="{
                                        height: '15px',
                                        width: '125px',
                                        marginBottom:'0'
                                      }">
                                </ngx-skeleton-loader>
                            </th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let number of [0,1,2,3,4,5,6]">
                            <td>
                                <ngx-skeleton-loader [theme]="{
                                            height: '15px',
                                            width: '100px',
                                        marginBottom:'0'
                                          }">
                                </ngx-skeleton-loader>
                            </td>
                            <td class="item-number">
                                <ngx-skeleton-loader [theme]="{
                                        height: '15px',
                                        width: '30px',
                                        marginBottom:'0'
                                      }">
                                </ngx-skeleton-loader>
                            </td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>
            </ng-container>

            <table class="bordered" *ngIf="!loading">
                <thead>
                    <tr>
                        <th>Grupo</th>
                        <th>Empresa</th>
                        <th>Usuários associados</th>
                        <th>Permissões associadas</th>
                        <th>Última atualização</th>
                        <th></th>
                    </tr>
                </thead>

                <tbody>
                    <tr *ngFor="let item of data">
                        <td>{{item.group}}</td>

                        <td>
                            {{item.company}}
                        </td>

                        <td>{{item.users}}</td>

                        <td>{{item.permissions.length}}</td>

                        <td>{{item.updated_at}}</td>

                        <td>
                            <div class="dash-card-item-buttons">
                                <button class="dash-item-button"
                                    (click)="redirectToDetails(item.id)">
                                    <i class="fas fa-eye"></i>
                                </button>
                            </div>
                        </td>
                    </tr>

                    <tr *ngIf="data && data.length === 0">
                        <td>Nenhum grupo de usuário adicionado</td>
                    </tr>
                </tbody>
                <app-spacer [bottom]="28"></app-spacer>
            </table>
        </div>
    </div>
</div>

<div class="col-12 ml-auto mr-auto">
    <div class="dash-card-pagination">
        <app-pagination [data]="data" [count]="count" [show]="!loading"
            [offset]="offset" (onPageChange)="changePageData($event)"
            [dashPagination]="true">
        </app-pagination>
    </div>
</div>

<app-side-modal [active]="showFilterModal" title="Filtrar listagem"
    (onClose)="showFilterModal = false">
    <form [formGroup]="filterForm">
        <div class="row">
            <div class="col-12">
                <app-input [group]="filterForm" controlName="name"
                    placeholder="Digite" label="Grupo">
                </app-input>
                <app-spacer [bottom]="16"></app-spacer>
            </div>

            <div class="col-12">
                <app-input [group]="filterForm" controlName="company"
                    placeholder="Digite" label="Empresa">
                </app-input>
                <app-spacer [bottom]="16"></app-spacer>
            </div>

            <div class="col-12">
                <app-masked-input [group]="filterForm" controlName="start_date"
                    mask="00/00/0000" label="Data de início"
                    placeholder="dd/mm/aaaa">
                </app-masked-input>
                <app-spacer [bottom]="16"></app-spacer>
            </div>

            <div class="col-12">
                <app-masked-input [group]="filterForm" controlName="end_date"
                    mask="00/00/0000" label="Data final"
                    placeholder="dd/mm/aaaa">
                </app-masked-input>
                <app-spacer [bottom]="16"></app-spacer>
            </div>
        </div>

        <app-spacer [bottom]="32"></app-spacer>

        <div class="row button-sticky">
            <div class="col-12 col-lg-6">
                <app-button (onClick)="resetForm()" class="add-button"
                    [phantom]="true">
                    Limpar filtros
                </app-button>
            </div>

            <div class="col-12 col-lg-6">
                <div class="text-right">
                    <app-button (onClick)="filterData()" [loading]="loading"
                        [disabled]="loading">
                        Aplicar filtros
                    </app-button>
                </div>
            </div>
        </div>
    </form>
</app-side-modal>