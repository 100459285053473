<ng-container *ngIf="loading">
    <ngx-skeleton-loader [theme]="{
        width: '100%',
        height: '150px'
      }">
    </ngx-skeleton-loader>

    <app-spacer [bottom]="24"></app-spacer>

    <ngx-skeleton-loader [theme]="{
        width: '100%',
        height: '300px'
      }">
    </ngx-skeleton-loader>
</ng-container>

<ng-container *ngIf="!loading">
    <div class="dash">
        <div class="row">
            <div class="col-12 ml-auto mr-auto">
                <div class="dash-card no-padding">
                    <div class="dash-card-header d-block">
                        <ng-container>
                            <div class="d-flex justify-content-between">
                                <h2>{{data.user}}</h2>

                                <app-button (onClick)="handleEditUser(true)"
                                    [outline]="true" label="teste"
                                    *ngIf="permissionObj.canChange">
                                    Editar
                                </app-button>
                            </div>
                            <app-spacer [bottom]="16"></app-spacer>
                            <p class="user-description">
                                <span class="status-tag"
                                    [class.-inactive]="data.status === 'Inativo'">{{data.status}}</span>
                                {{data.type}} • {{data.company}} -
                                {{data.department}}
                            </p>
                        </ng-container>
                    </div>
                </div>

                <app-spacer [bottom]="16"></app-spacer>
            </div>


            <div class="col-12 ml-auto mr-auto">
                <!-- <app-tabs [tabs]="tabs" (onChangeTab)="onChangeTab($event)"
                [currentTab]="tabIndex" [dashboardTab]="true">
            </app-tabs>
            <app-spacer [bottom]="16"></app-spacer> -->

                <ng-container *ngIf="activedTab === 'data'">
                    <div class="dash-card no-padding">
                        <div class="col-12" style="padding: 30px 30px 20px;">
                            <div class="row">
                                <div class="col-12">
                                    <h3>Dados gerais</h3>
                                    <app-spacer [bottom]="24"></app-spacer>
                                </div>

                                <div class="col-lg-3">
                                    <p class="title">
                                        CPF
                                    </p>
                                    <p class="label">
                                        {{formatDocument(data.document_number)}}
                                    </p>
                                </div>

                                <div class="col-lg-3">
                                    <p class="title">
                                        E-mail
                                    </p>
                                    <p class="label">
                                        {{data.email}}
                                    </p>
                                </div>

                                <div class="col-lg-3">
                                    <p class="title">
                                        Telefone
                                    </p>
                                    <p class="label">
                                        {{formatPhone(data.phone)}}
                                    </p>
                                </div>

                                <div class="col-lg-3">
                                    <p class="title">
                                        Grupo associado
                                    </p>
                                    <p class="label">
                                        {{data.group}}
                                    </p>
                                </div>
                            </div>
                            <app-spacer [bottom]="24"></app-spacer>
                        </div>
                    </div>
                    <app-spacer [bottom]="24"></app-spacer>


                    <div class="dash-card no-padding">
                        <div class="col-12" style="padding: 30px 30px 20px;">
                            <div class="row">
                                <div
                                    class="col-12 d-flex align-items-center justify-content-between mb-4">
                                    <h3>Segurança</h3>

                                    <app-button
                                        (onClick)="changePassword = true"
                                        [outline]="true" label="teste"
                                        *ngIf="permissionObj.canChange">
                                        Redefinir senha do usuário
                                    </app-button>
                                </div>
                                <!-- 
                            <div class="col-lg-3">
                                <p class="title">
                                    Autenticação em dois fatores (2FA)
                                </p>
                                <p class="label">
                                    Ativo
                                </p>
                            </div>

                            <div class="col-lg-3">
                                <p class="title">
                                    Atualização de senha periódica
                                </p>
                                <p class="label">
                                    90 dias
                                </p>
                            </div>

                            <div class="col-lg-3">
                                <p class="title">
                                    Última alteração de senha
                                </p>
                                <p class="label">
                                    01/01/2001 ás 10:54
                                </p>
                            </div>

                            <div class="col-lg-3">
                                <p class="title">
                                    Senha válida até
                                </p>
                                <p class="label">
                                    01/03/2001
                                </p>
                            </div> -->
                            </div>
                        </div>
                    </div>
                    <app-spacer [bottom]="24"></app-spacer>

                    <div class="dash-card no-padding">
                        <div class="col-12" style="padding: 30px 30px 20px;">
                            <div class="row">
                                <div class="col-lg-3">
                                    <p class="title">
                                        Adicionado em
                                    </p>
                                    <p class="label">
                                        {{data.created_at}}
                                    </p>
                                </div>

                                <div class="col-lg-3">
                                    <p class="title">
                                        Última atualização
                                    </p>
                                    <p class="label">
                                        {{data.updated_at}}
                                    </p>
                                </div>

                                <div class="col-lg-3">
                                    <p class="title">
                                        Última mudança de status
                                    </p>
                                    <p class="label">
                                        {{data.updated_at}}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="dash-card no-padding mt-5"
                        *ngFor="let permission of permissionsArray">
                        <div
                            class=" dash-card-header d-flex align-items-start justify-content-between">
                            <h3>Permissões na empresa
                                {{permission.company_name}}
                            </h3>
                        </div>

                        <div class="permission-list">
                            <ul class="permission-group">
                                <ng-container
                                    *ngFor="let item of permission.permissions">
                                    <li class="permission-group-title">
                                        {{renderType(item.type)}}
                                    </li>

                                    <li *ngFor="let i of item.permissions;">
                                        {{i.description}}
                                    </li>
                                </ng-container>
                            </ul>
                        </div>
                    </div>
                </ng-container>

                <ng-container *ngIf="activedTab === 'history'">
                    <div class="dash-card no-padding">
                        <div class=" dash-card-header d-block">
                            <div class="button-toggle-container">
                                <button (click)="showRecords = true"
                                    [class.active]="showRecords">
                                    Registros
                                </button>
                                <button (click)="showRecords = false"
                                    [class.active]="!showRecords">
                                    Log de atividades
                                </button>
                            </div>

                            <app-spacer [bottom]="16"></app-spacer>

                            <div class="header-button-container justify-content-end"
                                *ngIf="showRecords">
                                <div
                                    class="d-flex align-items-center justify-content-end">
                                    <app-button [inverted]="true"
                                        class="add-button">
                                        Exportar
                                    </app-button>

                                    <app-spacer [right]="16"></app-spacer>

                                    <app-button [inverted]="true"
                                        class="add-button"
                                        (onClick)="showFilterModal = true">
                                        <svg-icon
                                            src="assets/images/icon_filter.svg"></svg-icon>
                                        Aplicar filtros
                                    </app-button>
                                </div>
                            </div>
                        </div>

                        <div class="dash-card-body padding-container">
                            <table #table>
                                <thead>
                                    <tr>
                                        <th>
                                            <ngx-skeleton-loader [theme]="{
                                                        height: '15px',
                                                        width: '125px',
                                                        marginBottom:'0'
                                                      }">
                                            </ngx-skeleton-loader>
                                        </th>

                                        <th class="text-right">
                                            <ngx-skeleton-loader [theme]="{
                                                        height: '15px',
                                                        width: '125px',
                                                        marginBottom:'0'
                                                      }">
                                            </ngx-skeleton-loader>
                                        </th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let number of [0,1,2,3,4,5,6]">
                                        <td>
                                            <ngx-skeleton-loader [theme]="{
                                                            height: '15px',
                                                            width: '100px',
                                                        marginBottom:'0'
                                                          }">
                                            </ngx-skeleton-loader>
                                        </td>
                                        <td class="item-number">
                                            <ngx-skeleton-loader [theme]="{
                                                        height: '15px',
                                                        width: '30px',
                                                        marginBottom:'0'
                                                      }">
                                            </ngx-skeleton-loader>
                                        </td>
                                        <td></td>
                                    </tr>
                                </tbody>
                            </table>

                            <ng-container *ngIf="showRecords">
                                <table class="bordered">
                                    <thead>
                                        <tr>
                                            <th>ID</th>
                                            <th>Ação</th>
                                            <th>Grupo</th>
                                            <th>Usuário</th>
                                            <th>Data e hora</th>
                                            <th></th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        <tr *ngFor="let item of records">
                                            <td>{{item.name}}</td>

                                            <td>
                                                {{item.action}}
                                            </td>

                                            <td>{{item.group}}</td>

                                            <td>{{item.user}}</td>

                                            <td>{{item.date | date:
                                                'dd/MM/yyyy HH:mm'}}</td>

                                            <td>
                                                <div
                                                    class="dash-card-item-buttons">
                                                    <button
                                                        class="dash-item-button"
                                                        (click)="redirectToDetails(item.id, 1)"
                                                        *ngIf="permissionObj.canChange">
                                                        <i
                                                            class="fas fa-eye"></i>
                                                    </button>
                                                </div>
                                            </td>
                                        </tr>

                                        <tr *ngIf="records.length === 0">
                                            <td>Nenhum registro encontrado</td>
                                        </tr>
                                    </tbody>
                                    <app-spacer [bottom]="28"></app-spacer>
                                </table>
                            </ng-container>

                            <ng-container *ngIf="!showRecords">
                            </ng-container>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>

    <app-warning-modal [active]="changePassword" (onClose)="closeModal()">
        <h3>Redefinir senha para o usuário:</h3>
        <app-spacer [bottom]="24"></app-spacer>

        <p></p>
        <p></p>

        <p><b>{{data.user}}</b></p>
        <p>{{data.document_number}}</p>
        <p>{{data.email}}</p>
        <app-spacer [bottom]="56"></app-spacer>

        <app-button (onClick)="closeModal()" class="add-button" [center]="true"
            [outline]="true">
            Cancelar
        </app-button>
        <app-spacer [bottom]="8"></app-spacer>
        <app-button (onClick)="sendChangePasswordToken()" [center]="true"
            class="add-button">
            Redefinir senha
        </app-button>
    </app-warning-modal>


    <app-warning-modal [active]="confirmChangePassword"
        (onClose)="closeModal()">
        <h3>Redefinir senha </h3>
        <app-spacer [bottom]="24"></app-spacer>

        <p>Tem certeza que deseja redefinir a senha?
            Essa ação não poderá ser revertida.</p>
        <app-spacer [bottom]="24"></app-spacer>

        <p><b>Mike Wazowski</b></p>
        <p>000.000.000-00</p>
        <p>Mike@wazowski.comp</p>
        <app-spacer [bottom]="56"></app-spacer>

        <app-button (onClick)="closeModal()" class="add-button" [center]="true"
            [outline]="true">
            Não, manter como está
        </app-button>
        <app-spacer [bottom]="8"></app-spacer>
        <app-button (onClick)="closeModal()" [center]="true" class="add-button">
            Sim, redefinir senha
        </app-button>
    </app-warning-modal>

    <app-side-modal [active]="editUser" title="Editar Usuário"
        (onClose)="handleEditUser()">

        <p>{{data.user}}</p>
        <p>{{formatDocument(data.document_number)}}</p>
        <app-spacer [bottom]="24"></app-spacer>

        <form [formGroup]="form">
            <div class="row">
                <div class="col-12">
                    <p>Tipo de usuário</p>
                    <app-spacer [bottom]="16"></app-spacer>

                    <div class="d-flex">
                        <app-radio [multiple]="true"
                            (onCheckChanged)="handleChangeValue('simple')"
                            [isChecked]="isSimple">
                            <div class="flex-1 d-flex flex-column">
                                <span class="pep-title">Usuário comum</span>
                            </div>
                        </app-radio>
                        <app-spacer [right]="24"></app-spacer>

                        <app-radio [multiple]="true"
                            (onCheckChanged)="handleChangeValue('owner')"
                            [isChecked]="isOwner">
                            <div class="flex-1 d-flex flex-column">
                                <span class="pep-title">Proprietário</span>
                            </div>
                        </app-radio>
                        <app-spacer [right]="24"></app-spacer>

                        <app-radio [multiple]="true"
                            (onCheckChanged)="handleChangeValue('manager')"
                            [isChecked]="isManager">
                            <div class="flex-1 d-flex flex-column">
                                <span class="pep-title">Usuário master</span>
                            </div>
                        </app-radio>
                    </div>
                    <app-spacer [bottom]="24"></app-spacer>
                </div>

                <div class="col-12">
                    <app-select [items]="companyOptions" [group]="form"
                        controlName="company" placeholder="Selecione"
                        label="Empresa teste">
                    </app-select>
                    <app-spacer [bottom]="16"></app-spacer>
                </div>

                <div class="col-12">
                    <app-select [items]="userGroupsOptions" [multiple]="true"
                        [group]="form" controlName="user_group"
                        placeholder="Selecione" label="Grupo de usuários">
                    </app-select>
                    <app-spacer [bottom]="16"></app-spacer>
                </div>


                <div class="col-12">
                    <p>Status</p>
                    <app-spacer [bottom]="16"></app-spacer>

                    <div class="d-flex">
                        <app-radio [multiple]="true"
                            (onCheckChanged)="handleChangeStatus(true)"
                            [isChecked]="isActive">
                            <div class="flex-1 d-flex flex-column">
                                <span class="pep-title">Ativo</span>
                            </div>
                        </app-radio>
                        <app-spacer [right]="24"></app-spacer>

                        <app-radio [multiple]="true"
                            (onCheckChanged)="handleChangeStatus()"
                            [isChecked]="isInactive">
                            <div class="flex-1 d-flex flex-column">
                                <span class="pep-title">Inativo</span>
                            </div>
                        </app-radio>
                    </div>
                    <app-spacer [bottom]="48"></app-spacer>
                </div>
            </div>

            <app-spacer [bottom]="32"></app-spacer>

            <div class="row button-sticky">
                <div class="col-12 col-lg-6">
                    <app-button (onClick)="handleEditUser()" class="add-button"
                        [phantom]="true">
                        Cancelar
                    </app-button>
                </div>

                <div class="col-12 col-lg-6">
                    <div class="text-right">
                        <app-button (onClick)="submit()"
                            [disabled]="getDisabled()" [loading]="submiting"
                            class="add-button">
                            Salvar edição
                        </app-button>
                    </div>
                </div>
            </div>
        </form>
    </app-side-modal>
</ng-container>