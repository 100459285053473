<div class="dash">
    <div class="dash-card no-padding">
        <div class=" dash-card-header d-block">

            <ng-container *ngIf="loading">
                <ngx-skeleton-loader [theme]="{
                            width: '80px',
                            height: '25px'
                          }">
                </ngx-skeleton-loader>
            </ng-container>

            <ng-container *ngIf="!loading">
                <h2>Usuários</h2>
                <app-spacer [bottom]="16"></app-spacer>

                <div class="d-flex align-items-center justify-content-end">
                    <app-button [inverted]="true" class="add-button"
                        (onClick)="showFilterModal = true">
                        <svg-icon
                            src="assets/images/icon_filter.svg"></svg-icon>
                        Aplicar filtros
                    </app-button>
                    <app-spacer [right]="16"></app-spacer>

                    <app-button (onClick)="addUser = true" class="add-button"
                        *ngIf="permissionObj.canAdd">
                        <i class="fas fa-plus-circle mr-2"></i>
                        Adicionar usuário
                    </app-button>
                </div>
            </ng-container>

            <ng-container *ngIf="loading">
                <ngx-skeleton-loader [theme]="{
                        width: '180px',
                        height: '40px'
                      }">
                </ngx-skeleton-loader>
            </ng-container>
        </div>

        <div class="dash-card-body padding-container" style="overflow: auto;">
            <ng-container *ngIf="loading">
                <table #table>
                    <thead>
                        <tr>
                            <th>
                                <ngx-skeleton-loader [theme]="{
                                        height: '15px',
                                        width: '125px',
                                        marginBottom:'0'
                                      }">
                                </ngx-skeleton-loader>
                            </th>

                            <th class="text-right">
                                <ngx-skeleton-loader [theme]="{
                                        height: '15px',
                                        width: '125px',
                                        marginBottom:'0'
                                      }">
                                </ngx-skeleton-loader>
                            </th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let number of [0,1,2,3,4,5,6]">
                            <td>
                                <ngx-skeleton-loader [theme]="{
                                            height: '15px',
                                            width: '100px',
                                        marginBottom:'0'
                                          }">
                                </ngx-skeleton-loader>
                            </td>
                            <td class="item-number">
                                <ngx-skeleton-loader [theme]="{
                                        height: '15px',
                                        width: '30px',
                                        marginBottom:'0'
                                      }">
                                </ngx-skeleton-loader>
                            </td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>
            </ng-container>

            <table class="bordered" *ngIf="!loading">
                <thead>
                    <tr>
                        <th>Usuário</th>
                        <!-- <th>E-mail</th> -->
                        <th>Empresa</th>
                        <th>Grupo de usuários</th>
                        <!-- <th>Departamento</th> -->
                        <th>Ultima atualização</th>
                        <th>Status</th>
                        <th></th>
                    </tr>
                </thead>

                <tbody>
                    <tr *ngFor="let item of users">
                        <td>{{item.user}}</td>

                        <!-- <td>{{item.email}}</td> -->

                        <td>{{item.group.company[0]}}</td>

                        <td>{{renderText(item.group.name)}}</td>

                        <!-- <td>{{renderText(item.group.department)}}</td> -->

                        <td>{{item.updated_at}}</td>

                        <td>
                            <div class="status-tag"
                                [class.-inactive]="item.status === 'Inativo'">
                                {{item.status}}</div>
                        </td>

                        <td>
                            <div class="dash-card-item-buttons">
                                <button class="dash-item-button"
                                    (click)="redirectToDetails(item.id)"
                                    *ngIf="permissionObj.canChange">
                                    <i class="fas fa-eye"></i>
                                </button>
                            </div>
                        </td>
                    </tr>

                    <tr *ngIf="users.length === 0">
                        <td>Nenhum registro encontrado</td>
                    </tr>
                </tbody>
                <app-spacer [bottom]="28"></app-spacer>
            </table>
        </div>
    </div>
</div>

<div class="col-12 ml-auto mr-auto">
    <div class="dash-card-pagination">
        <app-pagination [data]="users" [count]="count" [show]="!loading"
            [offset]="offset" (onPageChange)="changePageData($event)"
            [dashPagination]="true">
        </app-pagination>
    </div>
</div>

<app-side-modal [active]="addUser" title="Adicionar Usuário"
    (onClose)="addUser = false">
    <form [formGroup]="filterForm">
        <div class="row">
            <div class="col-12">
                <app-input [group]="form" controlName="name"
                    label="Nome completo" placeholder="Digite">
                </app-input>
                <app-spacer [bottom]="16"></app-spacer>
            </div>

            <div class="col-lg-6">
                <app-input [group]="form" controlName="email" label="E-mail"
                    placeholder="Digite">
                </app-input>
                <app-spacer [bottom]="16"></app-spacer>
            </div>

            <div class="col-lg-6">
                <app-masked-input [group]="form" controlName="document"
                    label="CPF / CNPJ" mask="CPF_CNPJ"
                    placeholder="Insira seu documento">
                </app-masked-input>
                <app-spacer [bottom]="16"></app-spacer>
            </div>

            <div class="col-12">
                <app-select [items]="companyOptions" [group]="form"
                    controlName="company" placeholder="Selecione"
                    label="Empresa">
                </app-select>
                <app-spacer [bottom]="16"></app-spacer>
            </div>

            <div class="col-12">
                <app-select [items]="permissionGroupsOptions" [multiple]="true"
                    [group]="form" controlName="permission_group"
                    placeholder="Selecione" label="Grupo de usuários">
                </app-select>
                <app-spacer [bottom]="16"></app-spacer>
            </div>

            <div class="col-12">
                <p>Tipo de usuário</p>
                <app-spacer [bottom]="16"></app-spacer>

                <div class="d-flex">
                    <app-radio [multiple]="true"
                        (onCheckChanged)="handleChangeValue('simple')"
                        [isChecked]="isSimple">
                        <div class="flex-1 d-flex flex-column">
                            <span class="pep-title">Usuário comum</span>
                        </div>
                    </app-radio>
                    <app-spacer [right]="24"></app-spacer>

                    <app-radio [multiple]="true"
                        (onCheckChanged)="handleChangeValue('owner')"
                        [isChecked]="isOwner">
                        <div class="flex-1 d-flex flex-column">
                            <span class="pep-title">Proprietário</span>
                        </div>
                    </app-radio>

                    <app-spacer [right]="24"></app-spacer>

                    <app-radio [multiple]="true"
                        (onCheckChanged)="handleChangeValue('manager')"
                        [isChecked]="isManager">
                        <div class="flex-1 d-flex flex-column">
                            <span class="pep-title">Usuário master</span>
                        </div>
                    </app-radio>
                </div>
                <app-spacer [bottom]="24"></app-spacer>
            </div>
        </div>

        <app-spacer [bottom]="32"></app-spacer>

        <div class="row button-sticky">
            <div class="col-12 col-lg-6">
                <app-button (onClick)="closeModal()" class="add-button"
                    [phantom]="true">
                    Cancelar
                </app-button>
            </div>

            <div class="col-12 col-lg-6">
                <div class="text-right">
                    <app-button (onClick)="submit()" [disabled]="getDisabled()"
                        class="add-button" [loading]="submiting">
                        Adicionar usuário
                    </app-button>
                </div>
            </div>
        </div>
    </form>
</app-side-modal>

<app-side-modal [active]="showFilterModal" title="Filtrar listagem"
    (onClose)="showFilterModal = false">
    <form [formGroup]="filterForm">
        <div class="row">
            <div class="col-12">
                <app-input [group]="filterForm" controlName="name"
                    placeholder="Digite" label="Usuário">
                </app-input>
                <app-spacer [bottom]="16"></app-spacer>
            </div>

            <div class="col-12">
                <app-input [group]="filterForm" controlName="company"
                    placeholder="Digite" label="Empresa">
                </app-input>
                <app-spacer [bottom]="16"></app-spacer>
            </div>

            <div class="col-12">
                <app-input [group]="filterForm" controlName="group_name"
                    placeholder="Digite" label="Grupo de permissão">
                </app-input>
                <app-spacer [bottom]="16"></app-spacer>
            </div>

            <div class="col-12">
                <app-select [items]="statusOptions" [group]="filterForm"
                    controlName="status" placeholder="Selecione" label="Status">
                </app-select>
                <app-spacer [bottom]="16"></app-spacer>
            </div>

            <div class="col-12">
                <app-masked-input [group]="filterForm" controlName="start_date"
                    mask="00/00/0000" label="Data de início"
                    placeholder="dd/mm/aaaa">
                </app-masked-input>
                <app-spacer [bottom]="16"></app-spacer>
            </div>

            <div class="col-12">
                <app-masked-input [group]="filterForm" controlName="end_date"
                    mask="00/00/0000" label="Data final"
                    placeholder="dd/mm/aaaa">
                </app-masked-input>
                <app-spacer [bottom]="16"></app-spacer>
            </div>
        </div>

        <app-spacer [bottom]="32"></app-spacer>

        <div class="row button-sticky">
            <div class="col-12 col-lg-6">
                <app-button (onClick)="resetForm()" class="add-button"
                    [phantom]="true">
                    Limpar filtros
                </app-button>
            </div>

            <div class="col-12 col-lg-6">
                <div class="text-right">
                    <app-button (onClick)="filterData()" [loading]="loading"
                        [disabled]="loading">
                        Aplicar filtros
                    </app-button>
                </div>
            </div>
        </div>
    </form>
</app-side-modal>