import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api/api.service';
import { SignupService } from 'src/app/services/signup/signup.service';
import { ToastService } from 'src/app/services/toast/toast.service';
import { Router } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';
import Utils, { GetHelper } from 'src/app/helpers/utils';
import { AuthService } from 'src/app/services/auth/auth.service';

type UserResponse = {
  email: string;
  id: number;
  status: string;
  updated_at: string;
  user: string;
  group: {
    name: string[];
    department: string[];
    company: string[];
  };
};

@Component({
  selector: 'app-dashboard-users',
  templateUrl: './dashboard-users.component.html',
  styleUrls: ['./dashboard-users.component.scss'],
})
export class DashboardUsersComponent implements OnInit {
  permissionObj = {
    canAdd: true,
    canChange: true,
    canDelete: true,
  };

  filterForm = this.formBuilder.group({
    name: [null, []],
    company: [null, []],
    group_name: [null, []],
    start_date: [null, []],
    end_date: [null, []],
    status: [null, []],
  });

  form = this.formBuilder.group({
    company: [null, [Validators.required]],
    permission_group: [null, [Validators.required]],
    name: [null, [Validators.required]],
    email: [null, [Validators.required]],
    document: [null, [Validators.required]],
  });

  statusOptions: SelectItem[] = [
    { label: 'Ativo', value: 'true' },
    { label: 'Inativo', value: 'false' },
  ];

  users: UserResponse[] = [];
  offset = 0;
  count = 0;
  loading = true;
  submiting = false;
  isSimple = false;
  isOwner = false;
  isManager = false;
  hasFilters = false;
  showFilterModal = false;

  addUser = false;

  companyOptions: SelectItem[] = [];
  permissionGroupsOptions: SelectItem[] = [];

  constructor(
    private api: ApiService,
    private toast: ToastService,
    public signupService: SignupService,
    private router: Router,
    private formBuilder: FormBuilder,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.setPermissions();
    this.getData();
    this.getCompanies();

    this.form.controls.company.valueChanges.subscribe((value) => {
      this.form.controls.permission_group.setValue(null);
      this.getPermissionGroups();
    });

    this.filterForm.valueChanges.subscribe((values) => {
      this.hasFilters = Object.values(values).some(
        (value) => value !== null && value !== ''
      );
    });
  }

  handleChangeValue(type: string) {
    if (type === 'manager') {
      this.isManager = !this.isManager;
      if (this.isManager) {
        this.isSimple = false;
        this.isOwner = false;
      }
    }

    if (type === 'simple') {
      this.isSimple = !this.isSimple;
      if (this.isSimple) {
        this.isManager = false;
        this.isOwner = false;
      }
    }

    if (type === 'owner') {
      this.isOwner = !this.isOwner;
      if (this.isOwner) {
        this.isSimple = false;
        this.isManager = false;
      }
    }
  }

  async getCompanies() {
    try {
      const res = await this.api.get({
        route: 'api/v2/company/',
        token: true,
      });

      this.companyOptions = res.map((item: PermissionCompany) => ({
        label: item.description,
        value: item.id,
      }));
    } catch (error) {
      console.log(error);
      this.toast.show('error', 'Erro', 'Ocorreu um erro ao carregar empresas.');
    }
  }

  async getPermissionGroups() {
    try {
      const company = this.form.controls.company.value;

      const res = await this.api.get({
        route: `api/v2/user_group/?company=${company}`,
        token: true,
      });

      this.permissionGroupsOptions = res.map((item: PermissionListValues) => ({
        label: item.group,
        value: item.id,
      }));
    } catch (error) {
      console.log(error);
      this.toast.show(
        'error',
        'Erro',
        'Ocorreu um erro ao carregar grupos de permissão.'
      );
    }
  }

  async getData(page: number = 1) {
    try {
      const filters = {};

      if (this.hasFilters) {
        const values = this.filterForm.value;
        Object.entries(values).forEach(([key, value]: any) => {
          if (value !== null && value !== '') {
            filters[key] = value;
          }
        });
      }

      if (filters['start_date'] && !filters['end_date']) {
        const atualDate = new Date().toISOString().split('T')[0];
        filters['end_date'] = atualDate;
      }

      if (filters['end_date'] && !filters['start_date']) {
        filters['start_date'] = '2000-01-01';
      }

      const { count, results, offset } = await this.api.get({
        route: 'api/v2/user_group_user/',
        token: true,
        params: {
          page,
          ...filters,
        },
      });

      this.users = results;
      this.offset = offset;
      this.count = count;
    } catch (error) {
      console.error(error);
      this.toast.show('error', 'Erro', 'Ocorreu um erro ao carregar os dados.');
    }
    this.loading = false;
  }

  redirectToDetails(id: any) {
    this.router.navigateByUrl(`/app/settings/groups/user/details/${id}`);
  }

  closeModal() {
    this.addUser = false;
  }

  changePageData(page: number) {
    this.loading = true;
    this.getData(page);
  }

  renderText(companies: string[]) {
    return companies.join(', ');
  }

  resetForm() {
    this.hasFilters = false;
    this.filterForm.reset();
    this.showFilterModal = false;
    this.loading = true;
    this.getData();
  }

  async submit() {
    this.submiting = true;
    try {
      const valuesHelper = new GetHelper(this.form.value);

      const last_name = valuesHelper.get('name').split(' ').slice(1).join(' ');
      const collaboratorPayload = {
        email: valuesHelper.get('email'),
        first_name: valuesHelper.get('name').split(' ')[0],
        last_name,
        username: Utils.onlyNumbers(valuesHelper.get('document')),
        is_approver: this.isManager,
        department: 1,
        type_dispay:
          valuesHelper.get('document').length === 14
            ? 'Pessoa Jurídica'
            : 'Pessoa Física',
      };

      const payload = {
        username: Utils.onlyNumbers(valuesHelper.get('document')),
        permission_group: valuesHelper.get('permission_group'),
        simple_user: this.isSimple,
        manage_user: this.isManager,
        owner: this.isOwner,
        company: valuesHelper.get('company'),
      };

      await this.api.post({
        route: 'api/collaborator/',
        token: true,
        body: collaboratorPayload,
      });

      await this.api.post({
        route: 'api/v2/user_group_user/',
        token: true,
        body: payload,
      });

      this.toast.show('info', 'Sucesso', 'Usuário cadastrado com sucesso.');

      this.addUser = false;
      this.form.reset();
      this.getData();
    } catch (error) {
      console.error(error);
      this.toast.show('error', 'Erro', 'Ocorreu um erro ao salvar os dados.');
    }
    this.submiting = false;
  }

  getDisabled() {
    const userType = this.isManager || this.isSimple || this.isOwner;

    return this.form.invalid || this.submiting || !userType;
  }

  async filterData() {
    this.loading = true;

    try {
      const values = this.filterForm.value;

      const filters = {};

      Object.entries(values).forEach(([key, value]: any) => {
        if (value !== null && value !== '') {
          let newValue = value;
          if (key === 'start_date' || key === 'end_date') {
            newValue = value.split('/').reverse().join('-');
          }
          filters[key] = newValue;
        }
      });

      if (filters['start_date'] && !filters['end_date']) {
        const atualDate = new Date().toISOString().split('T')[0];
        filters['end_date'] = atualDate;
      }

      if (filters['end_date'] && !filters['start_date']) {
        filters['start_date'] = '2000-01-01';
      }

      const { results, count, offset } = await this.api.get({
        route: 'api/v2/user_group_user/',
        token: true,
        params: {
          page: 1,
          ...filters,
        },
      });

      this.hasFilters = true;
      this.count = count;
      this.users = results;
      this.offset = offset;
      this.showFilterModal = false;
    } catch (error) {
      console.error(error);
      this.toast.show('error', 'Erro', 'Ocorreu um erro ao carregar os dados.');
    }
    this.loading = false;
  }

  setPermissions() {
    const permissions = Object.keys(this.permissionObj).map(
      (key) =>
        `de_can_${key.split('can').join('').toLowerCase()}_user_group_user`
    );

    permissions.forEach((permission, idx) => {
      this.permissionObj[Object.keys(this.permissionObj)[idx]] =
        this.authService.verifyPermission(permission);
    });
  }
}
