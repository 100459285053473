<div class="dash">
    <div class="row">
        <div class="col-12 ml-auto mr-auto">
            <app-tabs [tabs]="tabs" (onChangeTab)="onChangeTab($event)"
                [currentTab]="tabIndex" [dashboardTab]="true">
            </app-tabs>
            <app-spacer [bottom]="16"></app-spacer>

            <app-dashboard-permissions *ngIf="activedTab === 'permissoes'">
            </app-dashboard-permissions>

            <app-dashboard-companies *ngIf="activedTab === 'empresas'">
            </app-dashboard-companies>

            <app-dashboard-history *ngIf="activedTab === 'historico'">
            </app-dashboard-history>

            <app-dashboard-users-group
                *ngIf="activedTab === 'grupos-de-usuarios'">
            </app-dashboard-users-group>
        </div>
    </div>
</div>