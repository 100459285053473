import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ToastService } from 'src/app/services/toast/toast.service';
import { ApiService } from 'src/app/services/api/api.service';

type DepartmentProps = {
  id: number;
  name: string;
};

@Component({
  selector: 'app-dashboard-group-register',
  templateUrl: './dashboard-group-register.component.html',
  styleUrls: ['./dashboard-group-register.component.scss'],
})
export class DashboardGroupRegisterComponent implements OnInit {
  label: string = 'Adicionar conjunto de permissões';

  loading: boolean = true;

  form = this.formBuilder.group({
    name: [null, Validators.required],
    company: [null, Validators.required],
    department: [null],
  });

  searchForm = this.formBuilder.group({
    avaliableUsers: [null],
    selectedUsers: [null],
    avaliablePermissions: [null],
    selectedPermissions: [null],
  });

  user: NewLoginResponse | undefined;

  isEditing: boolean = false;
  createdBy: string = '';
  companyOptions: SelectItem[] = [];
  departmentOptions: SelectItem[] = [];
  users: SelectItem[] = [];
  permissions: SelectItem[] = [];

  //   loadingUsers: boolean = true;
  batchSize = 30;
  offset = 0;

  //   Users variables

  totalUsersSelectedOptions = 0;
  usersSelectedCheckedNumber = 0;
  totalUsersAvaliableOptions = 0;
  usersAvaliableCheckedNumber = 0;

  //   avaliableOrderAsc = true;
  //   selectedOrderAsc = true;

  usersSelectedPicked: SelectItem[] = [];
  usersAvaliablePicked: SelectItem[] = [];

  usersAvaliable: SelectItem[] = [];
  usersSelected: SelectItem[] = [];
  visibleUsersSelected: SelectItem[] = [];

  //   Permissionns variables

  totalPermissionsSelectedOptions = 0;
  permissionsSelectedCheckedNumber = 0;
  totalPermissionsAvaliableOptions = 0;
  permissionsAvaliableCheckedNumber = 0;

  permissionsSelectedPicked: SelectItem[] = [];
  permissionsAvaliablePicked: SelectItem[] = [];

  permissionsAvaliable: SelectItem[] = [];
  permissionsSelected: SelectItem[] = [];
  visiblePermissionsSelected: SelectItem[] = [];

  sendingRequest: boolean = false;

  constructor(
    public route: Router,
    public formBuilder: FormBuilder,
    private authService: AuthService,
    private toast: ToastService,
    private api: ApiService
  ) {}

  ngOnInit(): void {
    if (!this.authService.verifyPermission(['de_can_add_user_group'])) {
      this.toast.show(
        'error',
        'Aviso',
        'Você não tem permissão para acessar essa página.'
      );
      this.route.navigateByUrl('/app/dashboard');
    }

    this.isEditing = this.route.url.includes('update');

    this.getData();

    this.user = this.authService.getUser();

    this.label = this.route.url.includes('update')
      ? 'Atualizar grupo de usuários'
      : 'Criar grupo de usuários';

    this.form.controls.company.valueChanges.subscribe((value) => {
      this.form.controls.department.setValue(null);
    });

    this.searchForm.controls.avaliableUsers.valueChanges.subscribe(
      async (value) => {
        this.usersAvaliablePicked = [];
        await this.getUsers(value);

        this.filterUsers();
        this.handleSetNumbers(true, true);
      }
    );

    this.searchForm.controls.selectedUsers.valueChanges.subscribe((value) => {
      this.usersSelectedPicked = [];

      this.visibleUsersSelected = this.usersSelected.filter((item) =>
        item.label.toLowerCase().includes(value.toLowerCase())
      );
      this.handleSetNumbers(true);
    });

    this.searchForm.controls.avaliablePermissions.valueChanges.subscribe(
      async (value) => {
        this.permissionsAvaliablePicked = [];
        await this.getPermissionGroups(value);

        this.filterPermissions();
        this.handleSetNumbers(false, true);
      }
    );

    this.searchForm.controls.selectedPermissions.valueChanges.subscribe(
      (value) => {
        this.permissionsSelectedPicked = [];

        this.visiblePermissionsSelected = this.permissionsSelected.filter(
          (item) => item.label.toLowerCase().includes(value.toLowerCase())
        );
        this.handleSetNumbers();
      }
    );
  }

  filterUsers() {
    this.usersAvaliable = this.usersAvaliable.filter((item) => {
      return !this.usersSelected.some(
        (selected) => selected.value === item.value
      );
    });
  }

  filterPermissions() {
    this.permissionsAvaliable = this.permissionsAvaliable.filter((item) => {
      return !this.permissionsSelected.some(
        (selected) => selected.value === item.value
      );
    });
  }

  async getData() {
    await Promise.all([
      this.getCompanyOptions(),
      this.getDepartmentOptions(),
      this.getUsers(),
      this.getPermissionGroups(),
    ]);

    if (this.isEditing) await this.getGroupPermissionData();

    this.handleSetNumbers(true, true);
    this.handleSetNumbers(true);
    this.handleSetNumbers(false, true);
    this.handleSetNumbers();

    this.loading = false;
  }

  async getUsers(params?: string) {
    try {
      const res = await this.api.get({
        route: 'api/v2/register/',
        token: true,
        params: {
          search: params,
        },
      });

      this.users = res.map((item: PermissionCompany) => ({
        label: item.description,
        value: item.id,
      }));

      this.usersAvaliable = this.users;
    } catch (error) {
      console.error(error);
      this.toast.show('error', 'Erro', 'Erro ao buscar usuários');
    }
  }

  async getPermissionGroups(params?: string) {
    try {
      const res = await this.api.get({
        route: 'api/v2/group/',
        token: true,
        params: {
          name: params,
        },
      });

      this.permissions = res.map((item: PermissionListValues) => ({
        label: item.group,
        value: item.id,
      }));

      this.permissionsAvaliable = this.permissions;
    } catch (error) {
      console.error(error);
      this.toast.show('error', 'Erro', 'Erro ao buscar permissões');
    }
  }

  async getGroupPermissionData() {
    try {
      const id = this.route.url.split('/').pop();
      const res: PermissionDetailsValue = await this.api.get({
        route: `api/v2/user_group/${id}/`,
        params: {
          type: 'edit',
        },
        token: true,
      });

      //   const findedCompany = this.companyOptions.find(
      //     (item) => item.label === `${res.company} - ${res.document}`
      //   );

      this.form.controls.company.setValue(res.company_id);
      this.form.controls.department.setValue(res.department);
      this.form.controls.name.setValue(res.group);
      this.createdBy = res.created_by === '' ? 'Sistema' : res.created_by;

      res.users.forEach((item: string) => {
        const finded = this.users.find((user) => user.value === item);

        if (finded) this.usersSelected.push(finded);
      });

      this.usersSelected.forEach((permission) => {
        this.usersAvaliable = this.usersAvaliable.filter(
          (item) => item.value !== permission.value
        );
      });

      res.groups.forEach((item: number) => {
        const finded = this.permissionsAvaliable.find(
          (permission) => permission.value === item
        );

        if (finded) this.permissionsSelected.push(finded);
      });

      this.permissionsSelected.forEach((permission) => {
        this.permissionsAvaliable = this.permissionsAvaliable.filter(
          (item) => item.value !== permission.value
        );
      });

      this.visibleUsersSelected = this.usersSelected;
      this.visiblePermissionsSelected = this.permissionsSelected;
    } catch (error) {
      this.toast.show('error', 'Erro', 'Erro ao carregar os dados');
    }
  }

  async getDepartmentOptions() {
    try {
      const res = await this.api.get({
        route: 'department/',
        token: true,
      });

      this.departmentOptions = res.map((item: DepartmentProps) => ({
        label: item.name,
        value: item.id,
      }));
    } catch (error) {
      this.toast.show('error', 'Erro', 'Erro ao buscar departamentos');
    }
  }

  async getCompanyOptions() {
    try {
      const res = await this.api.get({
        route: 'api/v2/company/',
        token: true,
      });

      this.companyOptions = res.map((item: PermissionCompany) => ({
        label: item.description,
        value: item.id,
      }));
    } catch (error) {
      this.toast.show('error', 'Erro', 'Erro ao buscar empresas');
    }
  }

  toggleValue(
    item: SelectItem,
    isUsersCard: boolean = false,
    isAvaliable: boolean = false
  ) {
    if (isUsersCard) {
      if (isAvaliable) {
        if (this.isOptionsChecked(item, true, true)) {
          this.usersAvaliablePicked = this.usersAvaliablePicked.filter(
            (permission) => permission.value !== item.value
          );
        } else {
          this.usersAvaliablePicked.push(item);
        }

        this.handleSetNumbers(true, true);
      } else {
        if (this.isOptionsChecked(item, true)) {
          this.usersSelectedPicked = this.usersSelectedPicked.filter(
            (permission) => permission.value !== item.value
          );
        } else {
          this.usersSelectedPicked.push(item);
        }

        this.handleSetNumbers(true);
      }
    } else {
      if (isAvaliable) {
        if (this.isOptionsChecked(item, false, true)) {
          this.permissionsAvaliablePicked =
            this.permissionsAvaliablePicked.filter(
              (permission) => permission.value !== item.value
            );
        } else {
          this.permissionsAvaliablePicked.push(item);
        }

        this.handleSetNumbers(false, true);
      } else {
        if (this.isOptionsChecked(item)) {
          this.permissionsSelectedPicked =
            this.permissionsSelectedPicked.filter(
              (permission) => permission.value !== item.value
            );
        } else {
          this.permissionsSelectedPicked.push(item);
        }

        this.handleSetNumbers();
      }
    }
  }

  isOptionsChecked(
    item: SelectItem,
    isUsersCard: boolean = false,
    isAvaliable: boolean = false
  ) {
    if (isUsersCard) {
      if (isAvaliable) {
        return this.usersAvaliablePicked.some(
          (permission) => permission.value === item.value
        );
      } else {
        return this.usersSelectedPicked.some(
          (permission) => permission.value === item.value
        );
      }
    } else {
      if (isAvaliable) {
        return this.permissionsAvaliablePicked.some(
          (permission) => permission.value === item.value
        );
      } else {
        return this.permissionsSelectedPicked.some(
          (permission) => permission.value === item.value
        );
      }
    }
  }

  getDisabled(isUsersCard: boolean = false, isAvaliable: boolean = false) {
    if (isUsersCard) {
      return isAvaliable
        ? this.usersAvaliablePicked.length === 0
        : this.usersSelectedPicked.length === 0;
    } else {
      return isAvaliable
        ? this.permissionsAvaliablePicked.length === 0
        : this.permissionsSelectedPicked.length === 0;
    }
  }

  changeSide(isUsersCard: boolean = false, isAvaliable: boolean = false) {
    if (isUsersCard) {
      if (isAvaliable) {
        this.usersAvaliablePicked.forEach((permission) => {
          this.usersSelected.push(permission);
          this.visibleUsersSelected.push(permission);

          this.usersAvaliable = this.usersAvaliable.filter(
            (item) => item.value !== permission.value
          );
        });

        const searchFormValue = this.searchForm.controls.selectedUsers.value;

        if (searchFormValue)
          this.searchForm.controls.selectedUsers.setValue(searchFormValue);

        this.usersAvaliablePicked = [];
      } else {
        this.usersSelectedPicked.forEach((permission) => {
          //   this.usersAvaliable.push(permission);

          this.usersSelected = this.usersSelected.filter(
            (item) => item.value !== permission.value
          );

          this.visibleUsersSelected = this.visibleUsersSelected.filter(
            (item) => item.value !== permission.value
          );
        });

        this.usersSelectedPicked = [];
        const searchFormValue = this.searchForm.controls.avaliableUsers.value;
        this.searchForm.controls.avaliableUsers.setValue(searchFormValue);
      }

      this.handleSetNumbers(true, true);
      this.handleSetNumbers(true);
    } else {
      if (isAvaliable) {
        this.permissionsAvaliablePicked.forEach((permission) => {
          this.permissionsSelected.push(permission);
          this.visiblePermissionsSelected.push(permission);

          this.permissionsAvaliable = this.permissionsAvaliable.filter(
            (item) => item.value !== permission.value
          );
        });

        const searchFormValue =
          this.searchForm.controls.selectedPermissions.value;

        if (searchFormValue)
          this.searchForm.controls.selectedPermissions.setValue(
            searchFormValue
          );

        this.permissionsAvaliablePicked = [];
      } else {
        this.permissionsSelectedPicked.forEach((permission) => {
          //   this.permissionsAvaliable.push(permission);

          this.permissionsSelected = this.permissionsSelected.filter(
            (item) => item.value !== permission.value
          );

          this.visiblePermissionsSelected =
            this.visiblePermissionsSelected.filter(
              (item) => item.value !== permission.value
            );
        });

        this.permissionsSelectedPicked = [];
        const searchFormValue =
          this.searchForm.controls.avaliablePermissions.value;
        this.searchForm.controls.avaliablePermissions.setValue(searchFormValue);
      }

      this.handleSetNumbers(false, true);
      this.handleSetNumbers();
    }
  }

  getSubmitDisabled() {
    return (
      this.form.invalid ||
      this.sendingRequest ||
      this.usersSelected.length === 0 ||
      this.permissionsSelected.length === 0
    );
  }

  handleSetNumbers(isUsersCard: boolean = false, isAvaliable: boolean = false) {
    if (isUsersCard) {
      if (isAvaliable) {
        this.totalUsersAvaliableOptions = this.usersAvaliable.length;
        this.usersAvaliableCheckedNumber = this.usersAvaliablePicked.length;
      } else {
        this.totalUsersSelectedOptions = this.visibleUsersSelected.length;
        this.usersSelectedCheckedNumber = this.usersSelectedPicked.length;
      }
    } else {
      if (isAvaliable) {
        this.totalPermissionsAvaliableOptions =
          this.permissionsAvaliable.length;
        this.permissionsAvaliableCheckedNumber =
          this.permissionsAvaliablePicked.length;
      } else {
        this.totalPermissionsSelectedOptions =
          this.visiblePermissionsSelected.length;
        this.permissionsSelectedCheckedNumber =
          this.permissionsAvaliablePicked.length;
      }
    }
  }

  async submit() {
    this.sendingRequest = true;
    try {
      const payload = {
        name: this.form.controls.name.value,
        company: this.form.controls.company.value,
        groups: this.permissionsSelected.map((item) => item.value),
        users: this.usersSelected.map((item) => item.value),
        department: this.form.controls.department.value,
      };

      this.isEditing
        ? await this.api.put({
            route: `api/v2/user_group/${this.route.url.split('/').pop()}/`,
            body: payload,
            token: true,
          })
        : await this.api.post({
            route: 'api/v2/user_group/',
            body: payload,
            token: true,
          });

      this.toast.show('info', 'Sucesso', 'Permissão adicionada com sucesso');
      this.route.navigateByUrl('app/settings/groups');
    } catch (error) {
      this.toast.show('error', 'Erro', 'Erro ao adicionar permissão');
    }

    this.sendingRequest = false;
  }

  handleCancel() {
    this.route.navigateByUrl('app/settings/groups');
  }

  handleCheckAll(isAvaliable: boolean = false) {
    if (isAvaliable) {
      const valuesMissingInPicked = this.permissionsAvaliable.filter(
        (item) =>
          !this.permissionsAvaliablePicked.some(
            (picked) => picked.value === item.value
          )
      );

      if (valuesMissingInPicked.length === 0) {
        this.permissionsAvaliablePicked = [];
        this.handleSetNumbers(false, true);
        return;
      }

      valuesMissingInPicked.forEach((item) => {
        this.permissionsAvaliablePicked.push(item);
      });
      this.handleSetNumbers(false, true);
    } else {
      const valuesMissingInPicked = this.permissionsSelected.filter(
        (item) =>
          !this.permissionsSelectedPicked.some(
            (picked) => picked.value === item.value
          )
      );

      if (valuesMissingInPicked.length === 0) {
        this.permissionsSelectedPicked = [];
        this.handleSetNumbers();
        return;
      }

      valuesMissingInPicked.forEach((item) => {
        this.permissionsSelectedPicked.push(item);
      });
      this.handleSetNumbers();
    }
  }

  verifyCheckAll(isAvaliable: boolean = false) {
    if (isAvaliable) {
      return (
        this.permissionsAvaliablePicked.length ===
        this.permissionsAvaliable.length
      );
    }
    return (
      this.permissionsSelectedPicked.length ===
      this.visiblePermissionsSelected.length
    );
  }
}
