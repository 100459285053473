<div class="dash">
    <div class="dash-card no-padding">
        <div class=" dash-card-header d-block">
            <ng-container *ngIf="!loading">
                <h2>Alteração de perfil</h2>
            </ng-container>
        </div>

        <div class="dash-card-body padding-container mb-5">
            <div class="col-12" style="padding: 0 30px 20px;">
                <div class="row">
                    <div class="col-lg-3">
                        <p class="title">
                            Usuário responsável
                        </p>
                        <p class="label">
                            Usuário 1
                        </p>
                    </div>

                    <div class="col-lg-3">
                        <p class="title">Grupo</p>
                        <p class="label">Convites</p>
                    </div>

                    <div class="col-lg-3">
                        <p class="title">
                            Usuário
                        </p>
                        <p class="label">
                            Usuário 2
                        </p>
                    </div>

                    <div class="col-lg-3">
                        <p class="title">Data de alteração</p>
                        <p class="label">31/12/2024 - 23:59</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="dash-card no-padding">
    <div class=" dash-card-header d-block">
        <ng-container *ngIf="loading">
            <ngx-skeleton-loader [theme]="{
                    width: '80px',
                    height: '25px'
                  }">
            </ngx-skeleton-loader>
        </ng-container>

        <ng-container *ngIf="!loading">
            <h2>Permissões</h2>

            <div class="header-button-container justify-content-end">
                <div class="d-flex align-items-center justify-content-end">
                    <app-button [inverted]="true" class="add-button">
                        Exportar
                    </app-button>

                    <app-spacer [right]="16"></app-spacer>

                    <app-button [inverted]="true" class="add-button"
                        (onClick)="showFilterModal = true">
                        <svg-icon
                            src="assets/images/icon_filter.svg"></svg-icon>
                        Aplicar filtros
                    </app-button>
                </div>
            </div>
        </ng-container>
    </div>

    <div class="dash dash-card-body padding-container">
        <table class="bordered" *ngIf="!loading">
            <thead>
                <tr>
                    <th>Ação</th>
                    <th>Data e hora</th>
                </tr>
            </thead>

            <tbody>
                <tr *ngFor="let item of records">
                    <td>
                        {{item.action}}
                    </td>

                    <td>{{item.date | date:
                        'dd/MM/yyyy HH:mm'}}</td>
                </tr>

                <tr *ngIf="records.length === 0">
                    <td>Nenhum registro encontrado</td>
                </tr>

            </tbody>
            <app-spacer [bottom]="28"></app-spacer>
        </table>
    </div>
</div>

<app-side-modal [active]="showFilterModal" title="Filtrar listagem"
    (onClose)="showFilterModal = false">
    <form [formGroup]="filterForm">
        <div class="row">
            <div class="col-12">
                <app-select [items]="roleOptions" [multiple]="true"
                    [group]="filterForm" controlName="action"
                    placeholder="Selecione" label="Ação">
                </app-select>
                <app-spacer [bottom]="16"></app-spacer>
            </div>

            <div class="col-12">
                <app-masked-input [group]="filterForm"
                    controlName="initial_date" mask="00/00/0000"
                    label="Data de início" placeholder="dd/mm/aaaa">
                </app-masked-input>
                <app-spacer [bottom]="16"></app-spacer>
            </div>

            <div class="col-12">
                <app-masked-input [group]="filterForm" controlName="final_date"
                    mask="00/00/0000" label="Data final"
                    placeholder="dd/mm/aaaa">
                </app-masked-input>
                <app-spacer [bottom]="16"></app-spacer>
            </div>
        </div>

        <app-spacer [bottom]="32"></app-spacer>

        <div class="row button-sticky">
            <div class="col-12 col-lg-6">
                <app-button (onClick)="resetForm()" class="add-button"
                    [phantom]="true">
                    Limpar filtros
                </app-button>
            </div>

            <div class="col-12 col-lg-6">
                <div class="text-right">
                    <app-button (onClick)="filterData()" class="add-button">
                        Aplicar filtros
                    </app-button>
                </div>
            </div>
        </div>
    </form>
</app-side-modal>

<!-- <div class="col-10 ml-auto mr-auto">
    <div class="dash-card-pagination">
        <app-pagination [data]="permissions" [show]="!loading"
            [dashPagination]="true">
        </app-pagination>
    </div>
</div> -->