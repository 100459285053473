<div class="row">
    <div class="col-10 ml-auto mr-auto">
        <div class="dash-card">
            <!-- <app-back-button [defaultRoute]="getBackLink()"
                [useDefaultRoute]="true" [step]="9"></app-back-button> -->

            <ng-container *ngIf="loading">

                <div class="dash-card-header">
                    <ngx-skeleton-loader
                        [theme]="{width: '111px',height: '27px'}">
                    </ngx-skeleton-loader>
                </div>

                <div class="dash-card-body">
                    <div class="row">

                        <div class="col-12">
                            <ngx-skeleton-loader
                                [theme]="{height: '44px',marginBottom:'24px'}">
                            </ngx-skeleton-loader>
                        </div>

                        <div class="col-3">
                            <ngx-skeleton-loader
                                [theme]="{height: '44px',marginBottom:'24px'}">
                            </ngx-skeleton-loader>
                        </div>

                        <div class="col-3">
                            <ngx-skeleton-loader
                                [theme]="{height: '44px',marginBottom:'24px'}">
                            </ngx-skeleton-loader>
                        </div>

                        <div class="col-12">
                            <ngx-skeleton-loader
                                [theme]="{width: '165px',height: '44px',marginBottom:'24px'}">
                            </ngx-skeleton-loader>
                        </div>

                        <div class="col-12">
                            <ngx-skeleton-loader [theme]="{height: '500px'}">
                            </ngx-skeleton-loader>
                        </div>


                        <div class="col-12">

                            <ngx-skeleton-loader
                                [theme]="{width: '200px',height: '40px'}">
                            </ngx-skeleton-loader>

                            <div class="row">
                                <div class="col-12"
                                    *ngFor="let number of [0,1,2,3,4,5]">
                                    <ngx-skeleton-loader
                                        [theme]="{width: '100%',height: '25px'}">
                                    </ngx-skeleton-loader>
                                </div>
                            </div>

                        </div>

                    </div>

                    <div class="row">
                        <div
                            class="col-12 text-right d-flex align-items-center justify-content-end">

                            <ngx-skeleton-loader
                                [theme]="{width: '130px',height: '40px',marginRight: '16px'}">
                            </ngx-skeleton-loader>

                            <ngx-skeleton-loader
                                [theme]="{width: '130px',height: '40px'}">
                            </ngx-skeleton-loader>

                        </div>
                    </div>

                </div>
            </ng-container>

            <ng-container *ngIf="!loading">
                <div class="dash-card-header" *ngIf="!id">
                    <h2><span>Criar minuta</span></h2>
                </div>

                <div class="dash-card-header" *ngIf="id">
                    <h2>Editar minuta <span>{{title}}</span></h2>
                </div>

                <div class="dash-card-body">

                    <form [formGroup]="form">
                        <div class="row">

                            <div class="col-12">
                                <app-select [items]="fundsItems"
                                    [multiple]="true" [group]="form"
                                    controlName="fund"
                                    label="Fundo pré definido (opcional)"
                                    placeholder="selecione">
                                </app-select>
                                <app-spacer [bottom]="24"></app-spacer>
                            </div>

                            <div class="col-12 col-lg-8">
                                <app-input [group]="form" controlName="name"
                                    label="Nome da minuta" placeholder="Digite">
                                </app-input>
                                <app-spacer [bottom]="24"></app-spacer>
                            </div>


                            <div class="col-12 col-lg-4">
                                <app-select [items]="typeItems" [group]="form"
                                    controlName="type" label="Tipo de minuta"
                                    placeholder="Selecione">
                                </app-select>
                                <app-spacer [bottom]="24"></app-spacer>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-4">
                                <app-masked-input [group]="form"
                                    controlName="initial_date" mask="00/00/0000"
                                    label="Início da vigência"
                                    placeholder="dd/mm/aaaa">
                                </app-masked-input>

                                <app-spacer [bottom]="24"></app-spacer>
                            </div>

                            <div class="col-4">
                                <app-masked-input [group]="form"
                                    controlName="final_date" mask="00/00/0000"
                                    label="Fim da vigência (opcional)"
                                    placeholder="dd/mm/aaaa">
                                </app-masked-input>
                                <app-spacer [bottom]="24"></app-spacer>
                            </div>

                            <div class="col-4">
                                <app-select [items]="statusItems" [group]="form"
                                    controlName="enabled" label="Status"
                                    placeholder="Selecione">
                                </app-select>
                                <app-spacer [bottom]="24"></app-spacer>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-12">
                                <app-select [items]="applicableItems"
                                    [multiple]="true" [group]="form"
                                    controlName="roles"
                                    label="Aplicável aos papeis"
                                    placeholder="Selecione">
                                </app-select>
                                <app-spacer [bottom]="24"></app-spacer>
                            </div>

                            <ng-container
                                *ngIf="verifyIdIsApplicable('roles', 'provider')">
                                <div class="col-12">
                                    <app-select [items]="agentItems"
                                        [group]="form" [multiple]="true"
                                        controlName="agents"
                                        label="Aplicável aos prestadores"
                                        placeholder="Selecione">
                                    </app-select>
                                    <app-spacer [bottom]="24"></app-spacer>
                                </div>
                            </ng-container>

                            <ng-container
                                *ngIf="verifyIdIsApplicable('roles', 'assignor')">
                                <div class="col-12 col-lg-4">
                                    <app-select [items]="coobligationItems"
                                        [group]="form"
                                        controlName="coobligation"
                                        label="Coobrigação"
                                        placeholder="Selecione">
                                    </app-select>
                                    <app-spacer [bottom]="24"></app-spacer>
                                </div>
                            </ng-container>

                            <!-- <ng-container
                *ngIf="form.controls.applicable.value === 'assignor' || form.controls.applicable.value === 'shareholder'">
                <div class="col-12 col-lg-6">
                  <app-select
                    [items]="form.controls.applicable.value === 'shareholder' ? user_choices.shareholder : user_choices.assignor"
                    [group]="form" controlName="specific_profile"
                    [label]="form.controls.applicable.value === 'shareholder' ? 'Se necessário, selecione um cotista específico' : 'Se necessário, selecione um cedente específico'"
                    placeholder="Selecione"
                    [helpText]="form.controls.applicable.value === 'shareholder' ? 'Em caso de minuta exclusiva para um cotista, informe neste campo' : 'Em caso de minuta exclusiva para um cedente, informe neste campo'">
                  </app-select>
                  <app-spacer [bottom]="24"></app-spacer>
                </div>
              </ng-container> -->
                        </div>

                        <div class="row">

                            <div [class.d-none]="loading" class="w-100">
                                <editor
                                    apiKey="5m9k6tphmwjfj7nx3w4r4ge1ajwkumenc5nbx7qz4a3t0mhb"
                                    [init]="config" class="editor-container"
                                    formControlName="content">
                                </editor>
                            </div>

                        </div>

                        <app-spacer [bottom]="32"></app-spacer>

                        <h3>Variáveis disponíveis</h3>

                        <div class="row">
                            <div class="col-12"
                                *ngFor="let item of documentation">
                                <app-collapse [title]="item.title">
                                    <ul [ngbCollapse]="">
                                        <li *ngFor="let key of item.keys">
                                            {{renderValue(key.label)}}
                                        </li>
                                    </ul>
                                </app-collapse>

                                <app-spacer [bottom]="16"></app-spacer>
                            </div>

                        </div>



                        <app-spacer [bottom]="48"></app-spacer>

                        <div class="row">
                            <div
                                class="col-12 text-right d-flex align-items-center justify-content-end">

                                <app-button (onClick)="redirect()"
                                    class="add-button" [outline]="true">
                                    Cancelar
                                </app-button>
                                <app-spacer [right]="16"></app-spacer>

                                <app-button (onClick)="createTemplate()"
                                    class="add-button"
                                    [disabled]="getDisabled()">
                                    {{id ? 'Salvar template': 'Criar template'}}
                                </app-button>

                            </div>
                        </div>
                    </form>
                </div>

            </ng-container>

        </div>
    </div>
</div>