<div class="row align-items-start">
    <div class="col-lg-6" *ngIf="canView.agents">
        <app-dashboard-settings-agents></app-dashboard-settings-agents>
        <app-spacer [bottom]="16"></app-spacer>
    </div>

    <div class="col-lg-6" *ngIf="canView.documents">
        <app-dashboard-settings-documents></app-dashboard-settings-documents>
        <app-spacer [bottom]="16"></app-spacer>
    </div>

    <div class="col-lg-6" *ngIf="canView.group_permission">
        <app-dashboard-settings-permissions></app-dashboard-settings-permissions>
        <app-spacer [bottom]="16"></app-spacer>
    </div>

    <div class="col-lg-6" *ngIf="canView.user_group">
        <app-dashboard-settings-group-users></app-dashboard-settings-group-users>
        <app-spacer [bottom]="16"></app-spacer>
    </div>

    <div class="col-lg-6" *ngIf="canView.audit">
        <app-dashboard-settings-audit></app-dashboard-settings-audit>
        <app-spacer [bottom]="16"></app-spacer>
    </div>

    <div class="col-lg-6" *ngIf="canView.zeroing_fund">
        <app-dashboard-settings-zeroing-funds></app-dashboard-settings-zeroing-funds>
        <app-spacer [bottom]="16"></app-spacer>
    </div>
</div>