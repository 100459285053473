import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MaskPipe } from 'ngx-mask';
import { ApiService } from 'src/app/services/api/api.service';
import { ToastService } from 'src/app/services/toast/toast.service';

@Component({
  selector: 'app-dashboard-company-details',
  templateUrl: './dashboard-company-details.component.html',
  styleUrls: ['./dashboard-company-details.component.scss'],
})
export class DashboardCompanyDetailsComponent implements OnInit {
  loading = true;
  data: PermissionCompanyDetails;

  constructor(
    private api: ApiService,
    private toast: ToastService,
    private route: ActivatedRoute,
    private maskPipe: MaskPipe
  ) {}

  ngOnInit(): void {
    this.getData();
  }

  async getData() {
    try {
      const id = this.route.snapshot.params.id;

      const res = await this.api.get({
        route: `api/v2/company/${id}/?tab=company_data`,
        token: true,
      });

      this.data = res;
    } catch (error) {
      console.log(error);
      this.toast.show('error', 'Erro', 'Ocorreu um erro ao carregar os dados.');
    }
    this.loading = false;
  }

  formatDocument(item: string) {
    return item.replace(
      /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/,
      '$1.$2.$3/$4-$5'
    );
  }

  formatPhone(item: { ddi: string; number: string }) {
    const ddiCode = item.ddi ? `+${item.ddi} ` : '';
    const checkNumberLength = item.number.length === 9;
    const formattedNumber = this.maskPipe.transform(
      item.number,
      checkNumberLength ? '00000-0000' : '0000-0000'
    );

    return `${ddiCode}${formattedNumber}`;
  }
}
