<ng-container *ngIf="loading">
    <ngx-skeleton-loader [theme]="{
        width: '100%',
        height: '150px'
      }">
    </ngx-skeleton-loader>

    <app-spacer [bottom]="24"></app-spacer>

    <ngx-skeleton-loader [theme]="{
        width: '100%',
        height: '300px'
      }">
    </ngx-skeleton-loader>
</ng-container>

<ng-container *ngIf="!loading">
    <div class="dash-card no-padding">
        <div
            class=" dash-card-header d-flex align-items-start justify-content-between">
            <h2>{{label}}</h2>
        </div>

        <div class="dash-card-body padding-container mb-5">
            <div class="col-12" style="padding: 0 30px 20px;">
                <form [formGroup]="form">
                    <div class="row">
                        <div class="col-lg-4">
                            <app-input [group]="form" controlName="name"
                                label="Nome do grupo de permissões"
                                [maxLength]="60">
                            </app-input>
                        </div>

                        <div class="col-lg-2">
                            <app-spacer [top]="32"></app-spacer>
                            <app-radio
                                (onCheckChanged)="form.controls.active.setValue(!form.controls.active.value)"
                                [multiple]="true"
                                [isChecked]="form.controls.active.value">
                                Permissão ativa
                            </app-radio>
                        </div>

                        <div class="col-lg-6">
                            <p class="title">Criado por</p>
                            <p class="label">
                                {{isEditing ? createdBy :
                                user.active_register.register.name
                                }}</p>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>

    <div class="dash-card no-padding">
        <div class="dash-card-header d-block">
            <h2>Permissões</h2>
            <p>
                Utilize o controle abaixo para associar as
                permissções que gostaria de associar a este
                grupo
            </p>
        </div>

        <div class="dash-card-body padding-container mb-5">
            <div class="col-12 permission-container"
                style="padding: 0 30px 20px;">
                <div class="permission-container-header">
                    <div class="left pt-0" style="border:0;">
                        <h3>Permissões disponíveis</h3>
                        <app-spacer [bottom]="8"></app-spacer>

                        <div class="permission-container-header-search">
                            <i class="fas fa-search"></i>
                            <app-spacer [right]="8"></app-spacer>
                            <app-input [group]="searchForm" style="width: 100%;"
                                controlName="avaliablePermissions"
                                [maxLength]="60">
                            </app-input>
                            <!--   <button (click)="changeOrder(true)"
                                        [disabled]="getOrderDisabled(true)">
                                        <i class="fas fa-sort-amount-down"
                                            [class.fa-sort-amount-down]="avaliableOrderAsc"
                                            [class.fa-sort-amount-up]="!avaliableOrderAsc"></i></button>
                              -->
                        </div>
                    </div>
                    <div class="right pt-0" style="border:0;">
                        <h3>Permissões selecionadas</h3>
                        <app-spacer [bottom]="8"></app-spacer>

                        <div class="permission-container-header-search">
                            <i class="fas fa-search"></i>
                            <app-spacer [right]="8"></app-spacer>
                            <app-input [group]="searchForm" style="width: 100%;"
                                controlName="selectedPermissions"
                                [maxLength]="60">
                            </app-input>
                            <!--  <button (click)="changeOrder()"
                                        [disabled]="getOrderDisabled()">
                                        <i class="fas fa-sort-amount-down"
                                            [class.fa-sort-amount-down]="selectedOrderAsc"
                                            [class.fa-sort-amount-up]="!selectedOrderAsc"></i>
                                    </button> -->
                        </div>
                    </div>
                </div>

                <div class="permission-container-body">
                    <div class="left">
                        <p class="pl-4 d-flex">
                            <ng-container
                                *ngIf="permissionsAvaliable.length > 0">
                                <app-radio
                                    (onCheckChanged)="handleCheckAll(true)"
                                    [multiple]="true"
                                    [isChecked]="verifyCheckAll(true)">
                                </app-radio>
                            </ng-container>
                            {{avaliableCheckedNumber}} de
                            {{totalAvaliableOptions}} itens selecionados
                        </p>
                        <app-spacer [bottom]="16"></app-spacer>

                        <div class="permission-container-body-list">
                            <div class="permission-container-body-list-item"
                                *ngFor="let item of permissionsAvaliable; let i = index">
                                <app-radio
                                    (onCheckChanged)="toggleValue(item,true)"
                                    [multiple]="true"
                                    [isChecked]="isOptionsChecked(item,true)">
                                    {{item.label}}
                                </app-radio>
                            </div>
                        </div>
                    </div>
                    <div class="buttons-container">
                        <button [disabled]="getDisabled(true)"
                            (click)="changeSide(true)">
                            <i class="fas fa-chevron-right"></i>
                        </button>

                        <button [disabled]="getDisabled()"
                            (click)="changeSide()">
                            <i class="fas fa-chevron-left"></i>
                        </button>
                    </div>
                    <div class="right">
                        <p class="pl-4 d-flex">
                            <ng-container
                                *ngIf="visiblePermissionsSelected.length > 0">
                                <app-radio (onCheckChanged)="handleCheckAll()"
                                    [multiple]="true"
                                    [isChecked]="verifyCheckAll()">
                                </app-radio>
                            </ng-container>
                            {{selectedCheckedNumber}} de
                            {{totalSelectedOptions}} itens selecionados
                        </p>
                        <app-spacer [bottom]="16"></app-spacer>

                        <div class="permission-container-body-list">
                            <div class="permission-container-body-list-item"
                                *ngFor="let item of visiblePermissionsSelected; let i = index">
                                <app-radio (onCheckChanged)="toggleValue(item)"
                                    [multiple]="true"
                                    [isChecked]="isOptionsChecked(item)">
                                    {{item.label}}
                                </app-radio>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="text-right">
        <app-button (onClick)="handleAddPermission()"
            [disabled]="getSubmitDisabled()" class="add-button"
            [loading]="sendingRequest">
            Salvar grupo de permissões
        </app-button>
    </div>
</ng-container>