<ng-container *ngIf="loading">
    <ngx-skeleton-loader [theme]="{
        width: '100%',
        height: '150px'
      }">
    </ngx-skeleton-loader>

    <app-spacer [bottom]="24"></app-spacer>

    <ngx-skeleton-loader [theme]="{
        width: '100%',
        height: '300px'
      }">
    </ngx-skeleton-loader>
</ng-container>

<ng-container *ngIf="!loading">
    <div class="dash-card no-padding">
        <div
            class=" dash-card-header d-flex align-items-start justify-content-between">
            <h2>{{label}}</h2>
        </div>

        <div class="dash-card-body padding-container mb-5">
            <div class="col-12" style="padding: 0 30px 20px;">
                <form [formGroup]="form">
                    <div class="row">
                        <div class="col-lg-6">
                            <app-select [items]="companyOptions" [group]="form"
                                controlName="company" placeholder="Selecione"
                                label="Empresa">
                            </app-select>
                            <app-spacer [bottom]="16"></app-spacer>
                        </div>

                        <div class="col-lg-6">
                            <app-select [items]="departmentOptions"
                                [group]="form" controlName="department"
                                placeholder="Selecione" label="Departamento"
                                [readOnly]="!form.controls.company.value">
                            </app-select>
                            <app-spacer [bottom]="16"></app-spacer>
                        </div>

                        <div class="col-lg-6">
                            <app-input [group]="form" controlName="name"
                                label="Nome do grupo de usuários"
                                [maxLength]="60">
                            </app-input>
                        </div>

                        <div class="col-lg-6">
                            <p class="title">Criado por</p>
                            <p class="label">
                                {{isEditing ? createdBy :
                                user.active_register.register.name
                                }}</p>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>

    <div class="dash-card no-padding">
        <div class="dash-card-header d-block">
            <h2>Usuários</h2>
            <p>
                Utilize o controle abaixo para associar os usuário a este grupo,
                um usuário não pode estar associado a mais de um grupo ao mesmo
                tempo
            </p>
        </div>

        <div class="dash-card-body padding-container mb-5">
            <div class="col-12 permission-container"
                style="padding: 0 30px 20px;">
                <div class="permission-container-header">
                    <div class="left pt-0" style="border:0;">
                        <h3>Usuários disponíveis</h3>
                        <app-spacer [bottom]="8"></app-spacer>

                        <div class="permission-container-header-search">
                            <i class="fas fa-search"></i>
                            <app-spacer [right]="8"></app-spacer>

                            <app-input [group]="searchForm" style="width: 100%;"
                                controlName="avaliableUsers" [maxLength]="60">
                            </app-input>
                            <!-- <button (click)="changeOrder(true)"
                                        [disabled]="getOrderDisabled(true)">
                                        <i class="fas fa-sort-amount-down"
                                            [class.fa-sort-amount-down]="avaliableOrderAsc"
                                            [class.fa-sort-amount-up]="!avaliableOrderAsc"></i>
                                        </button> -->
                        </div>
                    </div>
                    <div class="right pt-0" style="border:0;">
                        <h3>Usuários selecionados</h3>
                        <app-spacer [bottom]="8"></app-spacer>

                        <div class="permission-container-header-search">
                            <i class="fas fa-search"></i>
                            <app-spacer [right]="8"></app-spacer>

                            <app-input [group]="searchForm" style="width: 100%;"
                                controlName="selectedUsers" [maxLength]="60">
                            </app-input>
                            <!-- <button (click)="changeOrder()"
                                        [disabled]="getOrderDisabled()">
                                        <i class="fas fa-sort-amount-down"
                                            [class.fa-sort-amount-down]="selectedOrderAsc"
                                            [class.fa-sort-amount-up]="!selectedOrderAsc"></i>
                                    </button> -->
                        </div>
                    </div>
                </div>

                <div class="permission-container-body">
                    <div class="left">
                        <p class="pl-4">{{usersAvaliableCheckedNumber}} de
                            {{totalUsersAvaliableOptions}} itens selecionados
                        </p>
                        <app-spacer [bottom]="16"></app-spacer>

                        <div class="permission-container-body-list">
                            <div class="permission-container-body-list-item"
                                *ngFor="let item of usersAvaliable; let i = index">
                                <app-radio
                                    (onCheckChanged)="toggleValue(item,true,true)"
                                    [multiple]="true"
                                    [isChecked]="isOptionsChecked(item,true,true)">
                                    {{item.label}}
                                </app-radio>
                            </div>
                            <!-- <div *ngIf="loadingUsers" class="loading-indicator">
                                Carregando mais usuários...
                            </div> -->
                        </div>
                    </div>
                    <div class="buttons-container">
                        <button [disabled]="getDisabled(true,true)"
                            (click)="changeSide(true,true)">
                            <i class="fas fa-chevron-right"></i>
                        </button>

                        <button [disabled]="getDisabled(true)"
                            (click)="changeSide(true)">
                            <i class="fas fa-chevron-left"></i>
                        </button>
                    </div>
                    <div class="right">
                        <p class="pl-4">{{usersSelectedCheckedNumber}} de
                            {{totalUsersSelectedOptions}} itens selecionados
                        </p>
                        <app-spacer [bottom]="16"></app-spacer>

                        <div class="permission-container-body-list">
                            <div class="permission-container-body-list-item"
                                *ngFor="let item of visibleUsersSelected; let i = index">
                                <app-radio
                                    (onCheckChanged)="toggleValue(item,true)"
                                    [multiple]="true"
                                    [isChecked]="isOptionsChecked(item,true)">
                                    {{item.label}}
                                </app-radio>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="dash-card no-padding">
        <div class="dash-card-header d-block">
            <h2>Grupo de permissões</h2>
            <p>
                Utilize o controle abaixo para vincular o grupo de permissões
                que gostaria de associar a este grupo de usuários
            </p>
        </div>

        <div class="dash-card-body padding-container mb-5">
            <div class="col-12 permission-container"
                style="padding: 0 30px 20px;">
                <div class="permission-container-header">
                    <div class="left pt-0" style="border:0;">
                        <h3>Grupo de permissões disponíveis</h3>
                        <app-spacer [bottom]="8"></app-spacer>

                        <div class="permission-container-header-search">
                            <i class="fas fa-search"></i>
                            <app-spacer [right]="8"></app-spacer>

                            <app-input [group]="searchForm" style="width: 100%;"
                                controlName="avaliablePermissions"
                                [maxLength]="60">
                            </app-input>
                            <!-- <button (click)="changeOrder(true)"
                                        [disabled]="getOrderDisabled(true)">
                                        <i class="fas fa-sort-amount-down"
                                            [class.fa-sort-amount-down]="avaliableOrderAsc"
                                            [class.fa-sort-amount-up]="!avaliableOrderAsc"></i>
                                        </button> -->
                        </div>
                    </div>
                    <div class="right pt-0" style="border:0;">
                        <h3>Grupo de permissões selecionados</h3>
                        <app-spacer [bottom]="8"></app-spacer>

                        <div class="permission-container-header-search">
                            <i class="fas fa-search"></i>
                            <app-spacer [right]="8"></app-spacer>

                            <app-input [group]="searchForm" style="width: 100%;"
                                controlName="selectedPermissions"
                                [maxLength]="60">
                            </app-input>
                            <!--   <button (click)="changeOrder()"
                                        [disabled]="getOrderDisabled()">
                                        <i class="fas fa-sort-amount-down"
                                            [class.fa-sort-amount-down]="selectedOrderAsc"
                                            [class.fa-sort-amount-up]="!selectedOrderAsc"></i>
                                    </button>-->
                        </div>
                    </div>
                </div>

                <div class="permission-container-body">
                    <div class="left">
                        <p class="pl-4 d-flex">
                            <ng-container
                                *ngIf="permissionsAvaliable.length > 0">
                                <app-radio
                                    (onCheckChanged)="handleCheckAll(true)"
                                    [multiple]="true"
                                    [isChecked]="verifyCheckAll(true)">
                                </app-radio>
                            </ng-container>
                            {{permissionsAvaliableCheckedNumber}} de
                            {{totalPermissionsAvaliableOptions}} itens
                            selecionados
                        </p>
                        <app-spacer [bottom]="16"></app-spacer>

                        <div class="permission-container-body-list">
                            <div class="permission-container-body-list-item"
                                *ngFor="let item of permissionsAvaliable; let i = index">
                                <app-radio
                                    (onCheckChanged)="toggleValue(item,false,true)"
                                    [multiple]="true"
                                    [isChecked]="isOptionsChecked(item,false,true)">
                                    {{item.label}}
                                </app-radio>
                            </div>
                        </div>
                    </div>
                    <div class="buttons-container">
                        <button [disabled]="getDisabled(false,true)"
                            (click)="changeSide(false,true)">
                            <i class="fas fa-chevron-right"></i>
                        </button>

                        <button [disabled]="getDisabled()"
                            (click)="changeSide()">
                            <i class="fas fa-chevron-left"></i>
                        </button>
                    </div>
                    <div class="right">
                        <p class="pl-4 d-flex">
                            <ng-container
                                *ngIf="visiblePermissionsSelected.length > 0">
                                <app-radio (onCheckChanged)="handleCheckAll()"
                                    [multiple]="true"
                                    [isChecked]="verifyCheckAll()">
                                </app-radio>
                            </ng-container>
                            {{permissionsSelectedCheckedNumber}} de
                            {{totalPermissionsSelectedOptions}} itens
                            selecionados
                        </p>
                        <app-spacer [bottom]="16"></app-spacer>

                        <div class="permission-container-body-list">
                            <div class="permission-container-body-list-item"
                                *ngFor="let item of visiblePermissionsSelected; let i = index">
                                <app-radio (onCheckChanged)="toggleValue(item)"
                                    [multiple]="true"
                                    [isChecked]="isOptionsChecked(item)">
                                    {{item.label}}
                                </app-radio>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="text-right">
        <app-button (onClick)="submit()" [disabled]="getSubmitDisabled()"
            class="add-button" [loading]="sendingRequest">
            Salvar grupo de permissões
        </app-button>
    </div>
</ng-container>