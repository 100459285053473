import {
  Component,
  OnInit,
  HostListener,
  ElementRef,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';

import { ApiService } from 'src/app/services/api/api.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { SignupService } from 'src/app/services/signup/signup.service';
import { ToastService } from 'src/app/services/toast/toast.service';

@Component({
  selector: 'app-dashboard-settings-permissions',
  templateUrl: './dashboard-settings-permissions.component.html',
  styleUrls: ['./dashboard-settings-permissions.component.scss'],
})
export class DashboardSettingsPermissionsComponent implements OnInit {
  @ViewChild('table') table: ElementRef<HTMLTableElement> | undefined;

  permissionObj: PermissionValues = {
    canAdd: true,
    canChange: true,
  };

  data: PermissionListValues[] = [];

  tableSize: any = 20;

  loading = true;

  constructor(
    private api: ApiService,
    private toast: ToastService,
    private router: Router,
    public signupService: SignupService,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.setPermissions();
    this.getData();
    this.setTableSize();
  }

  async getData() {
    try {
      const res = await this.api.get({
        route: 'api/v2/group/',
        token: true,
        params: { type: 'list' },
      });

      this.data = res.results.splice(0, 10);
    } catch (error) {
      this.toast.show('error', 'Erro', 'Ocorreu um erro ao carregar os dados.');
      console.error(error);
    }
    this.loading = false;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.setTableSize();
  }

  setTableSize() {
    this.tableSize = (this.table?.nativeElement.offsetWidth ?? 200) - 120;
  }

  redirect(url: string) {
    this.router.navigate([url]);
  }

  addPermission() {
    this.router.navigateByUrl('/app/settings/permissions/new');
  }

  editPermission(id: any) {
    this.router.navigateByUrl(`/app/settings/permissions/update/${id}`);
  }

  redirectToDetails(id: any) {
    this.router.navigateByUrl(`/app/settings/permissions/details/${id}`);
  }

  setPermissions() {
    const permissions = Object.keys(this.permissionObj).map(
      (key) =>
        `de_can_${key.split('can').join('').toLowerCase()}_group_permission`
    );

    permissions.forEach((permission, idx) => {
      this.permissionObj[Object.keys(this.permissionObj)[idx]] =
        this.authService.verifyPermission(permission);
    });
  }
}
