import { Component, OnInit } from '@angular/core';
import DDIOptions from 'src/app/data/ddi';
import { ApiService } from 'src/app/services/api/api.service';
import { SignupService } from 'src/app/services/signup/signup.service';
import { ToastService } from 'src/app/services/toast/toast.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { Router } from '@angular/router';
import { FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-dashboard-user-history-details',
  templateUrl: './dashboard-user-history-details.component.html',
  styleUrls: ['./dashboard-user-history-details.component.scss'],
})
export class DashboardUserHistoryDetailsComponent implements OnInit {
  permissionObj = {
    canAdd: true,
    canChange: true,
    canDelete: true,
  };

  filterForm = this.formBuilder.group({
    user: [null, []],
    action: [null, []],
    group: [null, []],
    initial_date: [null, []],
    final_date: [null, []],
  });

  DDIOptions = DDIOptions;

  records: any[] = [];

  loading = true;

  showFilterModal = false;
  roleOptions: SelectItem[] = [];

  constructor(
    private api: ApiService,
    private toast: ToastService,
    public signupService: SignupService,
    private authService: AuthService,
    private router: Router,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit(): void {
    // if (!this.authService.verifyPermission(['de_can_view_audit'])) {
    //   this.toast.show(
    //     'error',
    //     'Aviso',
    //     'Você não tem permissão para acessar essa página.'
    //   );
    //   this.router.navigateByUrl('/app/dashboard');
    // }

    this.getRequesteds();
  }

  addAudit() {
    return;
  }

  async getRequesteds() {
    try {
      //   const res = await this.api.get({
      //     route: 'api/registration/audit/',
      //     token: true,
      //   });

      this.records = [
        {
          id: 1,
          name: '#0000001',
          action: 'Alteração de perfil',
          group: 'Convites',
          user: 'Usuário 1',
          date: '2024-08-10',
        },
        {
          id: 1,
          name: '#0000001',
          action: 'Retirada de permissão',
          group: 'Fundos',
          user: 'Usuário 1',
          date: '2024-08-10',
        },
        {
          id: 1,
          name: '#0000001',
          action: 'Concessão de permissões',
          group: 'Relatórios',
          user: 'Usuário 1',
          date: '2024-08-10',
        },
        {
          id: 1,
          name: '#0000001',
          action: 'Acesso não autorizado',
          group: 'Controladoria',
          user: 'Usuário 1',
          date: '2024-08-10',
        },
      ];
      this.loading = false;
    } catch (error) {
      console.error(error);
      this.toast.show('error', 'Erro', 'Ocorreu um erro ao carregar os dados.');
    }
  }

  redirectToDetails(id: any) {
    this.router.navigateByUrl(`/app/settings/permissions/details/${id}`);
  }

  resetForm() {
    this.filterForm.reset();
    this.showFilterModal = false;
  }

  filterData() {}
}
