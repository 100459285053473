<div class="row">
    <div class="col-10 ml-auto mr-auto">
        <div class="dash-card">
            <!-- <app-back-button [defaultRoute]="getBackLink()"
                [useDefaultRoute]="true" [step]="5"></app-back-button> -->

            <ng-container *ngIf="loading">
                <div class="dash-card-header">
                    <ngx-skeleton-loader [theme]="{
                    width: '111px',
                    height: '27px'
                  }">
                    </ngx-skeleton-loader>
                </div>
            </ng-container>

            <ng-container *ngIf="!loading">
                <div class="dash-card-header" *ngIf="!templateId">
                    <span>Criar template</span>
                </div>

                <div class="dash-card-header" *ngIf="templateId">
                    <h2>Editar template <span>{{title}}</span></h2>
                </div>
            </ng-container>

            <div class="dash-card-body">
                <form [formGroup]="form">

                    <div class="row" *ngIf="loading">
                        <div class="col-12">
                            <ngx-skeleton-loader [theme]="{
                            width: '165px',
                            height: '44px',
                            marginBottom:'24px'
                          }">
                            </ngx-skeleton-loader>

                        </div>
                    </div>

                    <div class="row" *ngIf="!loading">
                        <div class="col-12 col-lg-3">
                            <app-select [items]="applicableItems" [group]="form"
                                controlName="applicable" label="Destino"
                                placeholder="Selecione">
                            </app-select>
                            <app-spacer [bottom]="24"></app-spacer>
                        </div>

                        <ng-container
                            *ngIf="form.controls.applicable.value === 'assignor'">
                            <div class="col-12 col-lg-3">
                                <app-select [items]="profileTypeItems"
                                    [group]="form" controlName="assignor_type"
                                    [readOnly]="this.form.controls.specific_profile.value"
                                    label="Perfil do cedente"
                                    placeholder="Selecione">
                                </app-select>
                                <app-spacer [bottom]="24"></app-spacer>
                            </div>
                        </ng-container>

                        <ng-container
                            *ngIf="form.controls.applicable.value === 'provider'">
                            <!-- <div class="col-12 col-lg-3">
                                <app-select [items]="occupationItems"
                                    [group]="form" controlName="occupation"
                                    label="Função" placeholder="Selecione"
                                    >
                                </app-select>
                                <app-spacer [bottom]="48"></app-spacer>
                            </div> -->

                            <div class="col-12 col-lg-6">
                                <app-select [items]="user_choices.provider"
                                    [group]="form"
                                    controlName="specific_profile"
                                    label="Prestador de serviço"
                                    placeholder="Selecione">
                                </app-select>
                                <app-spacer [bottom]="24"></app-spacer>
                            </div>
                        </ng-container>

                        <ng-container
                            *ngIf="form.controls.applicable.value === 'assignor' || form.controls.applicable.value === 'shareholder'">
                            <div class="col-12 col-lg-6">
                                <app-select
                                    [items]="form.controls.applicable.value === 'shareholder' ? user_choices.shareholder : user_choices.assignor"
                                    [group]="form"
                                    controlName="specific_profile"
                                    [label]="form.controls.applicable.value === 'shareholder' ? 'Se necessário, selecione um cotista específico' : 'Se necessário, selecione um cedente específico'"
                                    placeholder="Selecione"
                                    [helpText]="form.controls.applicable.value === 'shareholder' ? 'Em caso de minuta exclusiva para um cotista, informe neste campo' : 'Em caso de minuta exclusiva para um cedente, informe neste campo'">
                                </app-select>
                                <app-spacer [bottom]="24"></app-spacer>
                            </div>
                        </ng-container>
                    </div>

                    <div class="row" *ngIf="loading">
                        <div class="col-4">
                            <ngx-skeleton-loader [theme]="{
                            height: '44px',
                            marginBottom:'24px'
                          }">
                            </ngx-skeleton-loader>

                        </div>

                        <div class="col-4">
                            <ngx-skeleton-loader [theme]="{
                            height: '44px',
                            marginBottom:'24px'
                          }">
                            </ngx-skeleton-loader>

                        </div>

                        <div class="col-12">
                            <ngx-skeleton-loader [theme]="{
                            height: '44px',
                            marginBottom:'48px'
                          }">
                            </ngx-skeleton-loader>

                        </div>
                    </div>

                    <ng-container *ngIf="!loading">
                        <div class="row">
                            <div class="col-4">
                                <app-masked-input [group]="form"
                                    controlName="initial_date" mask="00/00/0000"
                                    label="Início da vigência"
                                    placeholder="dd/mm/aaaa">
                                </app-masked-input>

                                <app-spacer [bottom]="24"></app-spacer>
                            </div>

                            <div class="col-4">
                                <app-masked-input [group]="form"
                                    controlName="final_date" mask="00/00/0000"
                                    label="Fim da vigência"
                                    placeholder="dd/mm/aaaa">
                                </app-masked-input>
                                <app-spacer [bottom]="24"></app-spacer>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-12">
                                <app-input [group]="form" controlName="name"
                                    label="Nome da minuta" placeholder="Digite">
                                </app-input>
                                <app-spacer [bottom]="48"></app-spacer>
                            </div>
                        </div>
                    </ng-container>

                    <div class="row">
                        <ng-container *ngIf="loading">
                            <div class="col-12">
                                <ngx-skeleton-loader [theme]="{
                            height: '500px'
                          }">
                                </ngx-skeleton-loader>
                            </div>
                        </ng-container>

                        <div [class.d-none]="loading" class="w-100">
                            <editor
                                apiKey="5m9k6tphmwjfj7nx3w4r4ge1ajwkumenc5nbx7qz4a3t0mhb"
                                [init]="config" class="editor-container"
                                formControlName="content">
                            </editor>
                        </div>
                    </div>

                    <app-spacer [bottom]="16"></app-spacer>

                    <div class="variable-container" *ngIf="loading">
                        <ngx-skeleton-loader [theme]="{
                            width: '280px',
                            height: '25px'
                          }">
                        </ngx-skeleton-loader>

                        <div class="row">
                            <div class="col-12"
                                *ngFor="let number of [0,1,2,3,4,5]">
                                <ngx-skeleton-loader [theme]="{
                                    width: '180px',
                                    height: '25px'
                                  }">
                                </ngx-skeleton-loader>
                            </div>
                        </div>
                    </div>

                    <div class="variable-container" *ngIf="!loading">
                        <h3>Variáveis disponíveis</h3>

                        <div class="row">
                            <div class="col-12"
                                *ngFor="let item of documentation">
                                <app-collapse [title]="item.title">
                                    <ul [ngbCollapse]="">
                                        <li *ngFor="let key of item.keys">
                                            {{renderValue(key.label)}}
                                        </li>
                                    </ul>
                                </app-collapse>

                                <app-spacer [bottom]="16"></app-spacer>
                            </div>
                        </div>
                    </div>

                    <app-spacer [bottom]="48"></app-spacer>

                    <div class="row">
                        <div
                            class="col-12 text-right d-flex align-items-center justify-content-end">
                            <ng-container *ngIf="loading">
                                <ngx-skeleton-loader [theme]="{
                                width: '130px',
                                height: '40px',
                                marginRight: '16px'
                              }">
                                </ngx-skeleton-loader>

                                <ngx-skeleton-loader [theme]="{
                                    width: '130px',
                                height: '40px'
                                  }">
                                </ngx-skeleton-loader>
                            </ng-container>

                            <ng-container *ngIf="!loading">
                                <app-button (onClick)="redirect()"
                                    class="add-button" [outline]="true">
                                    Cancelar
                                </app-button>
                                <app-spacer [right]="16"></app-spacer>
                                <app-button (onClick)="createTemplate()"
                                    class="add-button"
                                    [disabled]="getDisabled()">
                                    {{templateId ? 'Salvar template':'Criar
                                    template'}}
                                </app-button>
                            </ng-container>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>