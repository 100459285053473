import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api/api.service';
import { SignupService } from 'src/app/services/signup/signup.service';
import { ToastService } from 'src/app/services/toast/toast.service';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { FormBuilder } from '@angular/forms';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-dashboard-permissions',
  templateUrl: './dashboard-permissions.component.html',
  styleUrls: ['./dashboard-permissions.component.scss'],
})
export class DashboardPermissionsComponent implements OnInit {
  permissionObj = {
    canAdd: true,
    canChange: true,
    canDelete: true,
  };

  data: PermissionListValues[] = [];

  filterForm = this.formBuilder.group({
    name: [null, []],
    start_date: [null, []],
    end_date: [null, []],
    status: [null, []],
  });

  statusOptions: SelectItem[] = [
    { label: 'Ativo', value: 'true' },
    { label: 'Inativo', value: 'false' },
  ];

  loading = true;
  hasFilters = false;
  showFilterModal = false;
  offset: number = 0;
  count: number = 0;

  constructor(
    private formBuilder: FormBuilder,
    private api: ApiService,
    private toast: ToastService,
    public signupService: SignupService,
    private router: Router,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.setPermissions();
    this.getData();

    this.filterForm.valueChanges.subscribe((values) => {
      this.hasFilters = Object.values(values).some(
        (value) => value !== null && value !== ''
      );
    });
  }

  addAudit() {
    return;
  }

  async getData(page: number = 1) {
    try {
      const filters = {};

      if (this.hasFilters) {
        const values = this.filterForm.value;
        Object.entries(values).forEach(([key, value]: any) => {
          if (value !== null && value !== '') {
            filters[key] = value;
          }
        });
      }

      if (filters['start_date'] && !filters['end_date']) {
        const atualDate = new Date().toISOString().split('T')[0];
        filters['end_date'] = atualDate;
      }

      if (filters['end_date'] && !filters['start_date']) {
        filters['start_date'] = '2000-01-01';
      }

      const { count, results, offset } = await this.api.get({
        route: 'api/v2/group/',
        token: true,
        params: { page: page, type: 'list', ...filters },
      });

      this.offset = offset;
      this.count = count;
      this.data = results;
    } catch (error) {
      console.error(error);
      this.toast.show('error', 'Erro', 'Ocorreu um erro ao carregar os dados.');
    }
    this.loading = false;
  }

  addPermission() {
    this.router.navigateByUrl('/app/settings/permissions/new');
  }

  async removePermission(id: any) {
    try {
      const { isConfirmed } = await Swal.fire({
        title: 'Deseja remover essa permissão?',
        text: 'Você não poderá reverter essa ação!',
        icon: 'warning',
        showConfirmButton: true,
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Sim, excluir!',
        showCancelButton: true,
        cancelButtonColor: '#d33',
        cancelButtonText: 'Cancelar',
      });

      if (!isConfirmed) {
        return;
      }

      await this.api.delete({
        route: `api/v2/group/${id}/`,
        token: true,
      });

      this.loading = true;

      this.getData();

      this.toast.show('info', 'Sucesso', 'Permissão removida com sucesso.');
    } catch (error) {
      console.error(error);
      this.toast.show(
        'error',
        'Erro',
        'Ocorreu um erro ao remover a permissão.'
      );
    }
  }

  editPermission(id: any) {
    this.router.navigateByUrl(`/app/settings/permissions/update/${id}`);
  }

  redirectToDetails(id: any) {
    this.router.navigateByUrl(`/app/settings/permissions/details/${id}`);
  }

  changePageData(page: number) {
    this.loading = true;
    this.getData(page);
  }

  resetForm() {
    this.hasFilters = false;
    this.filterForm.reset();
    this.showFilterModal = false;
    this.loading = true;
    this.getData();
  }

  async filterData() {
    this.loading = true;

    try {
      const values = this.filterForm.value;

      const filters = {};

      Object.entries(values).forEach(([key, value]: any) => {
        if (value !== null && value !== '') {
          let newValue = value;
          if (key === 'start_date' || key === 'end_date') {
            newValue = value.split('/').reverse().join('-');
          }
          filters[key] = newValue;
        }
      });

      if (filters['start_date'] && !filters['end_date']) {
        const atualDate = new Date().toISOString().split('T')[0];
        filters['end_date'] = atualDate;
      }

      if (filters['end_date'] && !filters['start_date']) {
        filters['start_date'] = '2000-01-01';
      }

      const { results, count, offset } = await this.api.get({
        route: 'api/v2/group/',
        token: true,
        params: {
          page: 1,
          type: 'list',
          ...filters,
        },
      });

      this.hasFilters = true;
      this.count = count;
      this.data = results;
      this.offset = offset;
      this.showFilterModal = false;
    } catch (error) {
      console.error(error);
      this.toast.show('error', 'Erro', 'Ocorreu um erro ao carregar os dados.');
    }
    this.loading = false;
  }

  setPermissions() {
    const permissions = Object.keys(this.permissionObj).map(
      (key) =>
        `de_can_${key.split('can').join('').toLowerCase()}_group_permission`
    );

    permissions.forEach((permission, idx) => {
      this.permissionObj[Object.keys(this.permissionObj)[idx]] =
        this.authService.verifyPermission(permission);
    });
  }
}
