import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api/api.service';
import { SignupService } from 'src/app/services/signup/signup.service';
import { ToastService } from 'src/app/services/toast/toast.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { Router } from '@angular/router';

type SettingsPermissions = {
  permission_history: boolean;
  user_group_company: boolean;
  group_permission: boolean;
  user_group: boolean;
};

@Component({
  selector: 'app-dashboard-permissions-group',
  templateUrl: './dashboard-permissions-group.component.html',
  styleUrls: ['./dashboard-permissions-group.component.scss'],
})
export class DashboardPermissionsGroupComponent implements OnInit {
  canView: SettingsPermissions = {
    permission_history: false,
    user_group_company: false,
    group_permission: false,
    user_group: false,
  };

  tabIndex = 0;
  activedTab = 'permissoes';
  tabs = [
    { label: 'Grupo de permissões', value: 'permissoes' },
    { label: 'Grupos de usuários', value: 'grupos-de-usuarios' },
    { label: 'Empresas', value: 'empresas' },
    { label: 'Histórico', value: 'historico' },
  ];

  constructor(
    public signupService: SignupService,
    private authService: AuthService,
    private toast: ToastService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.setPermissions();
    if (
      !this.canView.permission_history &&
      !this.canView.user_group_company &&
      !this.canView.group_permission &&
      !this.canView.user_group
    ) {
      this.toast.show(
        'error',
        'Aviso',
        'Você não tem permissão para acessar essa página.'
      );
      this.router.navigateByUrl('/app/dashboard');
    }

    this.tabs.filter((tab) => {
      if (tab.value === 'historico' && !this.canView.permission_history) {
        this.tabs = this.tabs.filter((tab) => tab.value !== 'historico');
      }

      if (tab.value === 'empresas' && !this.canView.user_group_company) {
        this.tabs = this.tabs.filter((tab) => tab.value !== 'empresas');
      }

      if (
        tab.value === 'grupos-de-usuarios' &&
        !this.canView.group_permission
      ) {
        this.tabs = this.tabs.filter(
          (tab) => tab.value !== 'grupos-de-usuarios'
        );
      }

      if (tab.value === 'permissoes' && !this.canView.user_group) {
        this.tabs = this.tabs.filter((tab) => tab.value !== 'permissoes');
      }
    });
  }

  onChangeTab(index: number) {
    this.activedTab = this.tabs[index].value;

    this.tabIndex = index;
  }

  setPermissions() {
    const permissionArr = [
      'de_can_view_permission_history',
      'de_can_view_user_group_company',
      'de_can_view_group_permission',
      'de_can_view_user_group',
    ];

    permissionArr.forEach((permission) => {
      const label = permission.split('_').slice(3).join('_');

      this.canView[`${label}`] = this.authService.verifyPermission([
        permission,
      ]);
    });
  }
}
