import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MaskPipe } from 'ngx-mask';
import { ApiService } from 'src/app/services/api/api.service';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-dashboard-approval-professional',
  templateUrl: './dashboard-approval-professional.component.html',
  styleUrls: ['./dashboard-approval-professional.component.scss'],
})
export class DashboardApprovalProfessionalComponent implements OnInit {
  data: ProfessionalResponse;
  loading = true;

  constructor(
    private maskPipe: MaskPipe,
    private api: ApiService,
    private route: ActivatedRoute,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    if (this.authService.approvalData.professional) {
      this.data = this.authService.approvalData.professional;
      this.loading = false;
    } else {
      this.getApprovalData();
    }
  }

  async getApprovalData() {
    try {
      const req_params = {
        register: this.route.snapshot.params.id,
        tab: 'professional',
      };

      if (this.authService.approvalData.revision) {
        req_params['revision'] = this.authService.approvalData.revision;
        req_params['version'] = this.authService.approvalData.version;
      }

      const data = await this.api.get<any[]>({
        route: `api/v2/approvals/version/`,
        token: true,
        params: req_params,
      });

      data[0].register.professional.length > 0
        ? data[0].register.professional[0]
        : null;

      this.data =
        data[0].register.professional.length > 0
          ? data[0].register.professional[0]
          : null;
      this.loading = false;
    } catch (error) {
      console.log(error);
    }
  }

  formatCnpj(value: string) {
    const newValue = this.maskPipe.transform(value, '00.000.000/0000-00');

    return newValue;
  }

  formatPhone(phone) {
    return `+${String(phone.code)} ${this.maskPipe.transform(
      phone.number,
      '(00) 00000-0000'
    )}`;
  }

  //   renderValue(parentPath: string[], key) {
  //     return Utils.renderValue(parentPath, key, this.approval);
  //   }
}
