<div class="dashboard">
    <div class="row w-100">
        <div class="col-12 ml-auto mr-auto">
            <div class="dash-card">
                <ng-container *ngIf="!loading">
                    <!-- <app-back-button></app-back-button> -->
                </ng-container>

                <!-- <ng-container *ngIf="!loading">
                    <app-table size="xl" [headers]>
                    </app-table>
                </ng-container> -->

                <div class="row no-gutters">
                    <div class="col-12">
                        <div class="dash-card-header">
                            <ng-container *ngIf="loading">
                                <ngx-skeleton-loader [theme]="{
                                    width: '150px'
                                  }">
                                </ngx-skeleton-loader>
                            </ng-container>

                            <ng-container *ngIf="!loading">
                                <span>Todos os registros</span>
                            </ng-container>
                        </div>

                        <div
                            class="fund-dash-header-buttons  justify-content-end">
                            <ng-container *ngIf="loading">
                                <ngx-skeleton-loader [theme]="{
                                        height: '41px',
                                                width: '180px',
                                    marginBottom:'0'
                                      }">
                                </ngx-skeleton-loader>

                                <ngx-skeleton-loader [theme]="{
                                        height: '41px',
                                        width: '100px',
                                        marginBottom:'0'
                                          }">
                                </ngx-skeleton-loader>
                            </ng-container>

                            <ng-container *ngIf="!loading">
                                <div class="d-flex align-items-center">
                                    <app-button [inverted]="true"
                                        class="add-button"
                                        (onClick)="showFilterModal = true">
                                        <svg-icon
                                            src="assets/images/icon_filter.svg"></svg-icon>
                                        Aplicar filtros
                                    </app-button>
                                </div>
                            </ng-container>
                        </div>

                        <app-spacer [bottom]="32"></app-spacer>
                    </div>

                    <div class="col-12 padding-container dash-card-body">
                        <div clsas="styled-scroll">
                            <table *ngIf="loading" class="skeleton-table">
                                <thead>
                                    <tr>
                                        <th *ngFor="let number of [0,1,2]">
                                            <ngx-skeleton-loader [theme]="{
                                                width: '100px'
                                              }">
                                            </ngx-skeleton-loader>
                                        </th>
                                    </tr>
                                </thead>

                                <tbody>
                                    <tr *ngFor="let number of [0,1,2]">
                                        <td>
                                            <div class="dash-card-item">
                                                <div
                                                    class="dash-card-item-initials">
                                                    <span>
                                                        <ngx-skeleton-loader
                                                            appearance="circle"
                                                            [theme]="{
                                                        height: '48px',
                                                        width: '48px'
                                                      }">
                                                        </ngx-skeleton-loader>
                                                    </span>
                                                </div>

                                                <app-spacer
                                                    [right]="16"></app-spacer>

                                                <div
                                                    class="dash-card-item-content">
                                                    <div
                                                        class="dash-card-item-content-title">
                                                        <ngx-skeleton-loader
                                                            [theme]="{
                                                            height: '21px',
                                                            width: '130px',
                                                            margin:'0'
                                                          }">
                                                        </ngx-skeleton-loader>
                                                    </div>
                                                    <div
                                                        class="dash-card-item-content-subtitle">
                                                        <ngx-skeleton-loader
                                                            [theme]="{
                                                            height: '18px',
                                                            width: '110px'
                                                          }">
                                                        </ngx-skeleton-loader>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>

                                        <td>
                                            <ngx-skeleton-loader [theme]="{
                                                height: '21px',
                                                width: '200px'
                                              }">
                                            </ngx-skeleton-loader>
                                        </td>

                                        <td>
                                            <ngx-skeleton-loader [theme]="{
                                                height: '21px',
                                                width: '100px'
                                              }">
                                            </ngx-skeleton-loader>
                                        </td>
                                    </tr>

                                </tbody>
                                <app-spacer [bottom]="28"></app-spacer>
                            </table>

                            <table *ngIf="!loading && users.length > 0">
                                <thead>
                                    <tr>
                                        <th>Empresa</th>
                                        <th>Papel</th>
                                        <th>Estágio Atual</th>
                                        <th></th>
                                    </tr>
                                </thead>

                                <tbody>
                                    <tr *ngFor="let item of users">
                                        <td>
                                            <div class="dash-card-item">
                                                <div
                                                    class="dash-card-item-initials">
                                                    <span>{{getInitials(item)}}</span>
                                                </div>

                                                <app-spacer
                                                    [right]="16"></app-spacer>

                                                <div
                                                    class="dash-card-item-content">

                                                    <div
                                                        class="dash-card-item-content-title">
                                                        {{getDisplayName(item)}}
                                                    </div>
                                                    <div
                                                        class="dash-card-item-content-subtitle">
                                                        {{getDisplayDocument(item)}}
                                                    </div>
                                                    <div
                                                        class="dash-card-item-content-subtitle">
                                                        Qtd Versões:
                                                        {{item.register.current_revision}}
                                                        |
                                                        Aprovação atualizada em
                                                        {{item.updated_at |
                                                        date: "dd/MM/yyyy"}}
                                                    </div>
                                                </div>
                                            </div>
                                        </td>

                                        <td [style.max-width]="'300px'"
                                            [style.word-break]="'break-all'">
                                            <div class="dash-card-item-content">
                                                <div
                                                    class="dash-card-item-content-title">
                                                    {{formatItemName(item)}}
                                                </div>

                                                <div
                                                    class="dash-card-item-content-subtitle">
                                                    {{item.register.role.name}}
                                                </div>
                                            </div>
                                        </td>

                                        <td>
                                            <span class="status">
                                                {{getStatusInfo(item)}}
                                            </span>
                                            <div class="status-aprovation">
                                                {{getStatus(item)}}
                                            </div>
                                            <div class="status-aprovation">
                                                <strong>Versão:
                                                    {{item.version_number}}</strong>
                                            </div>
                                        </td>

                                        <td style="text-align: right">
                                            <div
                                                class="d-flex justify-content-end">
                                                <a class="mr-2"
                                                    [routerLink]="renderLink(item)">
                                                    <i class="fas fa-eye"></i>
                                                </a>
                                                <ng-container
                                                    *ngIf="item.editing && permissionObj.canChange">
                                                    <button class="button mr-2"
                                                        title="Editar"
                                                        (click)="redirectToEdit(item)">
                                                        <i
                                                            class="fas fa-pen"></i>
                                                    </button>
                                                </ng-container>
                                            </div>
                                        </td>
                                    </tr>

                                </tbody>
                                <app-spacer [bottom]="28"></app-spacer>
                            </table>

                            <div class="row"
                                *ngIf="users.length === 0 && !loading">
                                <div class="col-12">
                                    <app-empty-content [isVisible]="true"
                                        title="Nenhum usuário encontrado"
                                        description="Não encontramos nenhum usuário com os filtros aplicados."
                                        icon="/assets/images/figure_3d_search.png">
                                    </app-empty-content>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="dash-card-pagination" *ngIf="users.length > 0">
                <app-pagination [data]="users" [count]="count" [show]="!loading"
                    [offset]="offset" (onPageChange)="changePageData($event)"
                    [dashPagination]="true">
                </app-pagination>
            </div>
        </div>
    </div>

    <app-side-modal [active]="showFilterModal" title="Filtrar listagem"
        (onClose)="showFilterModal = false">
        <form [formGroup]="filterForm">
            <div class="row">
                <div class="col-12">
                    <app-input [group]="filterForm" controlName="name"
                        label="Nome" placeholder="Digite">
                    </app-input>
                    <app-spacer [bottom]="16"></app-spacer>
                </div>

                <div class="col-12">
                    <app-masked-input [group]="filterForm" [alwaysValid]="true"
                        controlName="document" label="Documento"
                        helpText="Se PF inserir CPF, caso PJ inserir CNPJ"
                        mask="CPF_CNPJ" placeholder="Insira seu documento">
                    </app-masked-input>
                    <app-spacer [bottom]="16"></app-spacer>
                </div>

                <div class="col-12">
                    <app-select [items]="primaryRoleOptions"
                        [group]="filterForm" controlName="role"
                        placeholder="Selecione" label="Papel">
                    </app-select>
                    <app-spacer [bottom]="32"></app-spacer>
                </div>

                <div class="col-12" *ngIf="selectedApplicable === 'provider'">
                    <app-select [items]="roleOptions" [group]="filterForm"
                        controlName="agent" [multiple]="true"
                        placeholder="Selecione" label="Agente">
                    </app-select>
                    <app-spacer [bottom]="32"></app-spacer>
                </div>

                <!-- <div class="col-12">
                    <app-select [items]="statusOptions" [group]="filterForm"
                        controlName="status" placeholder="Selecione"
                        label="Status" [multiple]="true">
                    </app-select>
                    <app-spacer [bottom]="32"></app-spacer>
                </div> -->


                <div class="col-12">
                    <h3>Por data de cadastro</h3>
                    <app-spacer [bottom]="16"></app-spacer>
                </div>

                <div class="col-12 col-lg-6">
                    <app-input type="date" [group]="filterForm"
                        controlName="created_from" label="De" [max]="today">
                    </app-input>
                    <app-spacer [bottom]="16"></app-spacer>
                </div>

                <div class="col-12 col-lg-6">
                    <app-input type="date" label="Até" [group]="filterForm"
                        controlName="created_to" placeholder="">
                    </app-input>
                    <app-spacer [bottom]="16"></app-spacer>
                </div>


                <div class="col-12">
                    <h3>Por data de atualização</h3>
                    <app-spacer [bottom]="16"></app-spacer>
                </div>

                <div class="col-12 col-lg-6">
                    <app-input type="date" label="De" [group]="filterForm"
                        controlName="updated_from">
                    </app-input>
                    <app-spacer [bottom]="16"></app-spacer>
                </div>

                <div class="col-12 col-lg-6">
                    <app-input type="date" label="Até" [group]="filterForm"
                        controlName="updated_to" placeholder="">
                    </app-input>
                    <app-spacer [bottom]="16"></app-spacer>
                </div>
            </div>

            <app-spacer [bottom]="32"></app-spacer>

            <div class="row button-sticky">
                <div class="col-12 col-lg-6">
                    <app-button (onClick)="resetForm()" class="add-button"
                        [phantom]="true">
                        Limpar filtros
                    </app-button>
                </div>

                <div class="col-12 col-lg-6">
                    <div class="text-right">
                        <app-button (onClick)="filterData()" class="add-button">
                            Aplicar filtros
                        </app-button>
                    </div>
                </div>
            </div>
        </form>
    </app-side-modal>
</div>