<ng-container *ngIf="loading">
    <ngx-skeleton-loader [theme]="{width: '100%',height: '400px'}">
    </ngx-skeleton-loader>
</ng-container>

<div class="dash-card no-padding" *ngIf="!loading">

    <div class="dash-card-body">
        <div class="col-12" style="padding: 30px 30px 20px">
            <!-- <div>
                            <h3>Descrição da empresa (opcional)</h3>
                            <app-spacer [bottom]="8"></app-spacer>

                            <p>
                                Mussum Ipsum, cacilds vidis litro abertis. Copo
                                furadis é disculpa de bebadis, arcu quam euismod
                                magna. Quem num gosta di mim que vai caçá sua
                                turmis! Quem num gosta di mé, boa gentis num é.
                                Bota 1 metro de cachacis aí pra viagem! Mé faiz
                                elementum girarzis, nisi eros vermeio. Não sou
                                faixa preta cumpadi, sou preto inteiris,
                                inteiris. Pellentesque nec nulla ligula. Donec
                                gravida turpis a vulputate ultricies. Todo mundo
                                vê os porris que eu tomo, mas ninguém vê os
                                tombis que eu levo! vida litro abertis.
                            </p>
                        </div>
                        <app-spacer [bottom]="32"></app-spacer>

                        <div>
                            <h3>Área de atuação (opcional)</h3>
                            <app-spacer [bottom]="8"></app-spacer>

                            <p>
                                Mussum Ipsum, cacilds vidis litro abertis. Copo
                                furadis é disculpa de bebadis, arcu quam euismod
                                magna. Quem num gosta di mim que vai caçá sua
                                turmis! Quem num gosta di mé, boa gentis num é.
                                Bota 1 metro de cachacis aí pra viagem! Mé faiz
                                elementum girarzis, nisi eros vermeio. Não sou
                                faixa preta cumpadi, sou preto inteiris,
                                inteiris. Pellentesque nec nulla ligula. Donec
                                gravida turpis a vulputate ultricies. Todo mundo
                                vê os porris que eu tomo, mas ninguém vê os
                                tombis que eu levo! vida litro abertis.
                            </p>
                        </div>
                        <app-spacer [bottom]="16"></app-spacer> -->

            <div class="row">
                <div class="col-12">
                    <h2>Detalhes da empresa</h2>
                    <app-spacer [bottom]="24"></app-spacer>
                </div>

                <div class="col-lg-3">
                    <p class="title">
                        Nome da empresa
                    </p>
                    <p class="label">
                        {{data.corporate_name}}
                    </p>
                </div>

                <div class="col-lg-2">
                    <p class="title">
                        Nome fantasia
                    </p>
                    <p class="label">
                        {{data.brand_name ?? '-'}}
                    </p>
                </div>

                <div class="col-lg-2">
                    <p class="title">
                        CNPJ
                    </p>
                    <p class="label">
                        {{formatDocument(data.document_number)}}
                    </p>
                </div>

                <div class="col-lg-2">
                    <p class="title">
                        Telefone
                    </p>
                    <p class="label">
                        {{data.phone ? formatPhone(data.phone) :
                        '-'}}
                    </p>
                </div>

                <div class="col-lg-3">
                    <p class="title">
                        E-mail
                    </p>
                    <p class="label">
                        {{data.email ?? '-'}}
                    </p>
                </div>
            </div>
            <app-spacer [bottom]="24"></app-spacer>

            <div class="row">
                <div class="col-12">
                    <h2>Endereço</h2>
                    <app-spacer [bottom]="24"></app-spacer>
                </div>

                <div class="col-lg-3">
                    <p class="title">
                        CEP
                    </p>
                    <p class="label">
                        {{data.address ? data.address.cep : '-'}}
                    </p>
                </div>

                <div class="col-lg-2">
                    <p class="title">
                        Logradouro
                    </p>
                    <p class="label">
                        {{data.address ? data.address.public_place :
                        '-'}}
                    </p>
                </div>

                <div class="col-lg-2">
                    <p class="title">
                        Número
                    </p>
                    <p class="label">
                        {{data.address ? data.address.number : '-'}}
                    </p>
                </div>

                <div class="col-lg-2">
                    <p class="title">
                        Complemento
                    </p>
                    <p class="label">
                        {{data.address ? data.address.complement :
                        '-'}}
                    </p>
                </div>

                <div class="col-lg-3">
                    <p class="title">
                        Bairro
                    </p>
                    <p class="label">
                        {{data.address ? data.address.district :
                        '-'}}
                    </p>
                </div>
            </div>
            <app-spacer [bottom]="24"></app-spacer>

            <div class="row">
                <div class="col-lg-3">
                    <p class="title">
                        Cidade
                    </p>
                    <p class="label">
                        {{data.address ? data.address.city : '-'}}
                    </p>
                </div>

                <div class="col-lg-3">
                    <p class="title">
                        UF
                    </p>
                    <p class="label">
                        {{data.address ? data.address.uf : '-'}}
                    </p>
                </div>
            </div>
            <app-spacer [bottom]="64"></app-spacer>
        </div>
    </div>
</div>