<div class="dash">
    <div class="row">
        <div class="col-10 ml-auto mr-auto">
            <div class="dash-card no-padding">
                <ng-container *ngIf="!loading">
                    <!-- <app-back-button></app-back-button> -->
                </ng-container>

                <div
                    class=" dash-card-header d-flex align-items-start justify-content-between">

                    <ng-container *ngIf="loading">
                        <ngx-skeleton-loader [theme]="{
                            width: '80px',
                            height: '25px'
                          }">
                        </ngx-skeleton-loader>
                    </ng-container>

                    <ng-container *ngIf="!loading">
                        <h2>Agentes</h2>
                    </ng-container>

                    <ng-container *ngIf="loading">
                        <ngx-skeleton-loader [theme]="{
                        width: '180px',
                        height: '40px'
                      }">
                        </ngx-skeleton-loader>
                    </ng-container>

                    <ng-container *ngIf="!loading">
                        <app-button (onClick)="addAgentsToggle(true)"
                            class="add-button" *ngIf="permissionObj.canAdd">
                            <i class="fas fa-plus-circle mr-2"></i> Adicionar
                            agente
                        </app-button>
                    </ng-container>
                </div>

                <div class="dash-card-body padding-container">
                    <ng-container *ngIf="loading">
                        <table #table>
                            <thead>
                                <tr>
                                    <th>
                                        <ngx-skeleton-loader [theme]="{
                                        height: '15px',
                                        width: '125px',
                                        marginBottom:'0'
                                      }">
                                        </ngx-skeleton-loader>
                                    </th>

                                    <th class="text-right">
                                        <ngx-skeleton-loader [theme]="{
                                        height: '15px',
                                        width: '125px',
                                        marginBottom:'0'
                                      }">
                                        </ngx-skeleton-loader>
                                    </th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let number of [0,1,2,3,4,5,6]">
                                    <td>
                                        <ngx-skeleton-loader [theme]="{
                                            height: '15px',
                                            width: '100px',
                                        marginBottom:'0'
                                          }">
                                        </ngx-skeleton-loader>
                                    </td>
                                    <td class="item-number">
                                        <ngx-skeleton-loader [theme]="{
                                        height: '15px',
                                        width: '30px',
                                        marginBottom:'0'
                                      }">
                                        </ngx-skeleton-loader>
                                    </td>
                                    <td></td>
                                </tr>
                            </tbody>
                        </table>
                    </ng-container>

                    <table class="bordered" aria-describedby="Agents table"
                        *ngIf="!loading">
                        <thead>
                            <tr>
                                <th>Agente</th>
                                <th class="text-right">Vínculos</th>
                                <th></th>
                            </tr>
                        </thead>

                        <tbody>
                            <tr *ngFor="let agent of agents">
                                <td>{{agent.label}}</td>
                                <td class="text-right">
                                    <ng-container
                                        *ngIf="agent.users">{{agent.users.length}}</ng-container>
                                </td>
                                <td>
                                    <div class="dash-card-item-buttons">
                                        <!-- <button class="dash-item-button" (click)="removeAgent(agent.id)">
                                            <i class="fas fa-trash"></i>
                                        </button> -->
                                        <app-spacer [right]="16"></app-spacer>

                                        <button class="dash-item-button"
                                            (click)="setAgent(agent)"
                                            *ngIf="permissionObj.canChange">
                                            <i class="fas fa-pen"></i>
                                        </button>
                                    </div>
                                </td>
                            </tr>

                            <tr *ngIf="agents.length === 0">
                                <td>Nenhum agente adicionado</td>
                            </tr>

                        </tbody>
                        <app-spacer [bottom]="28"></app-spacer>
                    </table>
                </div>
            </div>
        </div>

        <div class="col-10 ml-auto mr-auto">
            <div class="dash-card-pagination">
                <app-pagination [data]="agents" [show]="!loading"
                    [dashPagination]="true">
                </app-pagination>
            </div>
        </div>
    </div>
</div>

<app-side-modal [active]="addAgents" (onClose)="addAgentsToggle(false)">
    <ng-container *ngIf="addAgents">
        <form class="row" [formGroup]="agentsForm">
            <div class="col-12">
                <span class="settings-form-title">{{agent ? 'Atualize o' :
                    'Novo'}} agente</span>
                <app-spacer [bottom]="8"></app-spacer>
            </div>

            <div class="col-12">
                <span class="settings-form-description">
                    {{agent ? 'Atualize o' : 'Adicione um novo'}} agente ao seu
                    sistema.
                </span>
                <app-spacer [bottom]="48"></app-spacer>
            </div>

            <div class="col-12">
                <app-input label="Nome" [group]="agentsForm" controlName="name">
                </app-input>
                <app-spacer [bottom]="24"></app-spacer>
            </div>

            <div class="col-12 col-lg-6">
                <app-button [disabled]="agentsForm.invalid"
                    (click)="addAgent()">Salvar novo
                    agente
                </app-button>
            </div>
        </form>
    </ng-container>


</app-side-modal>