<div class="dash">
    <div class="row">
        <div class="col-12 ml-auto mr-auto">
            <app-tabs [tabs]="tabs" (onChangeTab)="onChangeTab($event)"
                [currentTab]="tabIndex" [dashboardTab]="true">
            </app-tabs>
            <app-spacer [bottom]="16"></app-spacer>

            <!-- <app-back-button></app-back-button> -->

            <app-dashboard-company-details
                *ngIf="activedTab === 'dados-da-empresa'">
            </app-dashboard-company-details>

            <app-dashboard-company-bond-users
                *ngIf="activedTab === 'usuarios-associados'">
            </app-dashboard-company-bond-users>

            <app-dashboard-company-bond-funds
                *ngIf="activedTab === 'fundos-associados'">
            </app-dashboard-company-bond-funds>

            <app-dashboard-company-permissions-groups
                *ngIf="activedTab === 'grupos-de-permissao'">
            </app-dashboard-company-permissions-groups>
        </div>
    </div>
</div>