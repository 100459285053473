
const gitInfo = {
  branch: "master",
  commit: "fc1fa9a",
  origin: "https://bitbucket.org/prosperitainova/dataengine-web-angular",
  url: "https://bitbucket.org/prosperitainova/dataengine-web-angular/commits/fc1fa9aef573fcb8be832e3fb6d3defce617c95d",
  date: "12/12/2024",
};

export default gitInfo;
  