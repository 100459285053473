import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api/api.service';
import { SignupService } from 'src/app/services/signup/signup.service';
import { ToastService } from 'src/app/services/toast/toast.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';
import { MaskPipe } from 'ngx-mask';

type UserPermissions = {
  company_name: string;
  permissions: GroupPermission[];
};

type UserResponse = {
  email: string;
  id: number;
  company_id: string;
  status: string;
  updated_at: string;
  user: string;
  group: any[];
  type: string;
  company: string;
  department: string;
  document_number: string;
  user_permissions: UserPermissions[];
  phone: {
    ddi: string;
    number: string;
  };
  created_at: string;
  status_changed_at: string;
};

@Component({
  selector: 'app-dashboard-user-details',
  templateUrl: './dashboard-user-details.component.html',
  styleUrls: ['./dashboard-user-details.component.scss'],
})
export class DashboardUserDetailsComponent implements OnInit {
  tabIndex = 0;
  activedTab = 'data';
  tabs = [
    { label: 'Dados', value: 'data' },
    { label: 'História', value: 'history' },
  ];

  permissionObj = {
    canChange: true,
  };

  form = this.formBuilder.group({
    company: [null, [Validators.required]],
    user_group: [null, [Validators.required]],
  });

  filterForm = this.formBuilder.group({
    user: [null, []],
    action: [null, []],
    group: [null, []],
    initial_date: [null, []],
    final_date: [null, []],
  });

  companyOptions: SelectItem[] = [];
  userGroupsOptions: SelectItem[] = [];

  isSimple = false;
  isManager = false;
  isOwner = false;
  isActive = false;
  isInactive = false;
  submiting = false;

  records: any[] = [];
  data: Partial<UserResponse> = {
    company: '',
    company_id: '',
    created_at: '',
    department: '',
    document_number: '',
    email: '',
    group: [],
    id: 0,
    phone: {
      ddi: '',
      number: '',
    },
    status: '',
    type: '',
    updated_at: '',
    user: '',
  };

  loading = true;

  showRecords = true;

  showFilterModal = false;
  roleOptions: SelectItem[] = [];
  permissionsArray: UserPermissions[] = [];

  confirmChangePassword = false;
  changePassword = false;
  editUser = false;

  constructor(
    private toast: ToastService,
    public signupService: SignupService,
    private router: Router,
    private formBuilder: FormBuilder,
    private api: ApiService,
    private route: ActivatedRoute,
    private maskPipe: MaskPipe,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    if (!this.authService.verifyPermission(['de_can_view_user_group_user'])) {
      this.toast.show(
        'error',
        'Aviso',
        'Você não tem permissão para acessar essa página.'
      );
      this.router.navigateByUrl('/app/dashboard');
    }

    this.setPermissions();
    this.getOptions();

    this.form.controls.company.valueChanges.subscribe((value) => {
      this.getUserGroups();
    });
  }

  async getOptions() {
    this.getData();
    await Promise.all([this.getCompanies(), this.getUserGroups()]);

    setTimeout(() => {
      this.loading = false;
    }, 1000);
  }

  async getCompanies() {
    try {
      const res = await this.api.get({
        route: 'api/v2/company/',
        token: true,
      });

      this.companyOptions = res.map((item: PermissionCompany) => ({
        label: item.description,
        value: item.id,
      }));
    } catch (error) {
      console.log(error);
      this.toast.show('error', 'Erro', 'Ocorreu um erro ao carregar empresas.');
    }
  }

  async getUserGroups() {
    try {
      const company = this.form.controls.company.value;

      const res = await this.api.get({
        route: `api/v2/user_group/?company=${company}`,
        token: true,
      });

      this.userGroupsOptions = res.map((item: PermissionListValues) => ({
        label: item.group,
        value: item.id,
      }));
    } catch (error) {
      console.log(error);
      this.toast.show(
        'error',
        'Erro',
        'Ocorreu um erro ao carregar grupos de permissão.'
      );
    }
  }

  handleChangeValue(type: string) {
    if (type === 'manager') {
      this.isManager = !this.isManager;
      if (this.isManager) {
        this.isSimple = false;
        this.isOwner = false;
      }
    }

    if (type === 'simple') {
      this.isSimple = !this.isSimple;
      if (this.isSimple) {
        this.isManager = false;
        this.isOwner = false;
      }
    }

    if (type === 'owner') {
      this.isOwner = !this.isOwner;
      if (this.isOwner) {
        this.isSimple = false;
        this.isManager = false;
      }
    }
  }

  handleChangeStatus(isActive: boolean = false) {
    if (isActive) {
      this.isActive = !this.isActive;
      if (this.isActive) {
        this.isInactive = false;
      }
    } else {
      this.isInactive = !this.isInactive;
      if (this.isInactive) {
        this.isActive = false;
      }
    }
  }

  async getData() {
    try {
      const res = await this.api.get({
        route: 'api/v2/user_group_user/' + this.route.snapshot.params.id,
        token: true,
      });

      this.form.controls.company.setValue(res.company_id);

      this.data = res;

      this.formatList(this.data.user_permissions);
    } catch (error) {
      console.error(error);
      this.toast.show('error', 'Erro', 'Ocorreu um erro ao carregar os dados.');
    }
  }

  formatList(list: UserPermissions[]) {
    let arr = list.map((item) => {
      return {
        company_name: item.company_name,
        permissions: this.formatPermissions(item.permissions),
      };
    });

    this.permissionsArray = arr;
  }

  formatPermissions(list: any[]) {
    let arr: any[] = [];

    list.forEach((item) => {
      const finded = arr.find((el) => el.type === item.type);

      if (finded) {
        finded.permissions.push(item);
      } else {
        arr.push({
          type: item.type,
          permissions: [item],
        });
      }
    });

    return arr;
  }

  redirectToDetails(id: any, historyId: any) {
    this.router.navigateByUrl(
      `/app/settings/groups/user/details/${id}/history/${historyId}`
    );
  }

  resetForm() {
    this.filterForm.reset();
    this.showFilterModal = false;
  }

  formatDocument(document: string) {
    return document.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})$/, '$1.$2.$3-$4');
  }

  renderType(type: string) {
    let label = '';

    switch (type) {
      case 'can_view':
        label = 'Visualizar';
        break;
      case 'can_add':
        label = 'Adicionar';
        break;
      case 'can_change':
        label = 'Editar';
        break;
      case 'can_remove':
        label = 'Remover';
        break;
    }

    return label;
  }

  formatPhone(item: { ddi: string; number: string }) {
    if (!item.ddi || !item.number) return '-';

    const ddiCode = item.ddi ? `+${item.ddi} ` : '';
    const checkNumberLength = item.number.length === 9;
    const formattedNumber = this.maskPipe.transform(
      item.number,
      checkNumberLength ? '00 00000-0000' : '00 0000-0000'
    );

    return `${ddiCode}${formattedNumber}`;
  }

  filterData() {}

  handleEditUser(open: boolean = false) {
    this.editUser = open;

    if (open) {
      let userGroups = [];

      this.data.group.forEach((group) => {
        const finded = this.userGroupsOptions.find(
          (item) => item.label === group
        );

        if (finded) {
          userGroups.push(finded.value);
        }
      });

      this.form.controls.company.setValue(this.data.company_id);

      this.form.controls.user_group.setValue(userGroups);
      this.isSimple = this.data.type === 'Comum';
      this.isOwner = this.data.type === 'Owner';
      this.isManager = this.data.type === 'Master';
      this.isActive = this.data.status === 'Ativo';
      this.isInactive = this.data.status !== 'Ativo';
    } else {
      this.form.reset();
    }
  }

  async submit() {
    this.submiting = true;
    try {
      const payload = {
        simple_user: this.isSimple,
        manage_user: this.isManager,
        owner: this.isOwner,
        status: this.isActive ? 'Ativo' : 'Inativo',
        company: this.form.value.company,
        permission_group: this.form.value.user_group,
      };

      const id = this.route.snapshot.params.id;

      await this.api.put({
        route: `api/v2/user_group_user/${id}/`,
        token: true,
        body: payload,
      });

      this.toast.show('info', 'Sucesso', 'Usuário editado com sucesso.');

      this.editUser = false;
      this.form.reset();
      this.router.navigateByUrl('/app/settings/groups');
    } catch (error) {
      console.error(error);
      this.toast.show('error', 'Erro', 'Ocorreu um erro ao editar o usuário.');
    }
    this.submiting = false;
  }

  onChangeTab(index: number) {
    this.activedTab = this.tabs[index].value;

    this.tabIndex = index;
  }

  rediretToEdit() {}

  closeModal() {
    this.changePassword = false;
    this.confirmChangePassword = false;
    this.editUser = false;
  }

  getDisabled() {
    const userType = this.isManager || this.isSimple || this.isOwner;
    const userStatus = this.isActive || this.isInactive;

    return this.form.invalid || this.submiting || !userType || !userStatus;
  }

  async sendChangePasswordToken() {
    try {
      await this.api.post({
        route: 'request-token/',
        body: {
          username: this.data.document_number,
          channel: 'all',
          reset: false,
        },
      });

      this.toast.show('info', 'Sucesso', 'Token enviado com sucesso.');
      this.confirmChangePassword = false;
      this.changePassword = true;
    } catch (error) {
      console.log(error);
      this.toast.show('error', 'Erro', 'Ocorreu um erro ao enviar o token.');
    }
  }

  setPermissions() {
    const permissions = Object.keys(this.permissionObj).map(
      (key) =>
        `de_can_${key.split('can').join('').toLowerCase()}_user_group_user`
    );

    permissions.forEach((permission, idx) => {
      this.permissionObj[Object.keys(this.permissionObj)[idx]] =
        this.authService.verifyPermission(permission);
    });
  }
}
