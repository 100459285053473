import {
  Component,
  OnInit,
  HostListener,
  ElementRef,
  ViewChild,
} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';

import { ApiService } from 'src/app/services/api/api.service';
import { SignupService } from 'src/app/services/signup/signup.service';
import { ToastService } from 'src/app/services/toast/toast.service';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-dashboard-settings-group-users',
  templateUrl: './dashboard-settings-group-users.component.html',
  styleUrls: ['./dashboard-settings-group-users.component.scss'],
})
export class DashboardSettingsGroupUsersComponent implements OnInit {
  @ViewChild('table') table: ElementRef<HTMLTableElement> | undefined;

  permissionObj: PermissionValues = {
    canAdd: true,
    canChange: true,
    canDelete: true,
  };

  groups: GroupUsers[] = [];

  visible_groups: GroupUsers[] = [];

  tableSize: any = 20;

  loading = false;

  constructor(
    private formBuilder: FormBuilder,
    private api: ApiService,
    private toast: ToastService,
    private router: Router,
    public signupService: SignupService,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.getData();
    this.setTableSize();
    this.setGroups();
  }

  async getData() {
    this.loading = true;

    try {
      const { results } = await this.api.get({
        route: 'api/v2/user_group',
        token: true,
        params: {
          type: 'list',
        },
      });

      this.groups = results;
      this.visible_groups = [...this.groups].splice(0, 10);
    } catch (error) {
      this.toast.show(
        'error',
        'Erro',
        'Ocorreu um erro ao carregar grupos de usuários.'
      );
      console.error(error);
    }
    this.loading = false;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.setTableSize();
  }

  setTableSize() {
    this.tableSize = (this.table?.nativeElement.offsetWidth ?? 200) - 120;
  }

  redirect(url: string) {
    this.router.navigate([url]);
  }

  editGroup(id: number) {
    this.router.navigateByUrl('/app/settings/groups/update/' + id);
  }

  addPermission() {
    this.router.navigateByUrl('/app/settings/groups/new');
  }

  setGroups() {
    const groups = Object.keys(this.permissionObj).map(
      (key) => `de_can_${key.split('can').join('').toLowerCase()}_user_group`
    );
    groups.forEach((permission, idx) => {
      this.permissionObj[Object.keys(this.permissionObj)[idx]] =
        this.authService.verifyPermission(permission);
    });
  }
}
