<ng-container *ngIf="loading">
    <ngx-skeleton-loader [theme]="{width: '100%',height: '400px'}">
    </ngx-skeleton-loader>
</ng-container>

<div class="dash-card no-padding" *ngIf="!loading">
    <div class="dash-card-header d-block">
        <h2>Permissões associadas</h2>
        <app-spacer [bottom]="16"></app-spacer>

        <div class="d-flex align-items-center justify-content-end">
            <app-button [inverted]="true" class="add-button"
                (onClick)="showFilterModal = true">
                <svg-icon src="assets/images/icon_filter.svg"></svg-icon>
                Aplicar filtros
            </app-button>
        </div>
    </div>

    <div class="dash-card-body padding-container">

        <table class="bordered">
            <thead>
                <tr>
                    <th>Grupo de permissão</th>
                    <th>Grupos associados</th>
                </tr>
            </thead>

            <tbody>
                <tr *ngFor="let item of data">
                    <td>
                        {{item.group}}
                    </td>
                    <td>
                        {{item.group_permission}}
                    </td>
                </tr>

                <tr *ngIf="data.length === 0">
                    <td>Nenhum registro adicionado</td>
                </tr>
            </tbody>
            <app-spacer [bottom]="28"></app-spacer>
        </table>
    </div>
</div>

<div class="col-12 ml-auto mr-auto">
    <div class="dash-card-pagination">
        <app-pagination [data]="data" [count]="count" [show]="!loading"
            [offset]="offset" (onPageChange)="changePageData($event)"
            [dashPagination]="true">
        </app-pagination>
    </div>
</div>

<app-side-modal [active]="showFilterModal" title="Filtrar listagem"
    (onClose)="showFilterModal = false">
    <form [formGroup]="filterForm">
        <div class="row">
            <div class="col-12">
                <app-input [group]="filterForm" controlName="name"
                    placeholder="Digite" label="Grupo de permissão">
                </app-input>
                <app-spacer [bottom]="16"></app-spacer>
            </div>
        </div>

        <app-spacer [bottom]="32"></app-spacer>

        <div class="row button-sticky">
            <div class="col-12 col-lg-6">
                <app-button (onClick)="resetForm()" class="add-button"
                    [phantom]="true">
                    Limpar filtros
                </app-button>
            </div>

            <div class="col-12 col-lg-6">
                <div class="text-right">
                    <app-button (onClick)="filterData()" [loading]="loading"
                        [disabled]="loading">
                        Aplicar filtros
                    </app-button>
                </div>
            </div>
        </div>
    </form>
</app-side-modal>