<div class="shareholder">
    <div class="row">
        <div class="col-12 ml-auto mr-auto">
            <ng-container *ngIf="!loading && !self_view">
                <!-- <app-back-button></app-back-button> -->
            </ng-container>

            <div class="shareholder-card">
                <div class="row w-100 no-gutters">
                    <div class="col-12 col-lg-8 d-flex flex-row"
                        *ngIf="loading">
                        <div class="shareholder-profile-initials bg-none">
                            <ngx-skeleton-loader appearance="circle" [theme]="{
                                    width: '64px',
                                    height: '64px',
                                    margin:'0'
                                  }">
                            </ngx-skeleton-loader>
                        </div>
                        <app-spacer [right]="32"></app-spacer>
                        <div class="d-flex flex-column">
                            <ngx-skeleton-loader [theme]="{                                    
                                    height: '44px',
                                    width: '500px',
                                    margin:'0'
                                  }">
                            </ngx-skeleton-loader>
                            <div class="d-flex flex-row align-items-center">
                                <ngx-skeleton-loader [theme]="{                                    
                                    height: '24px',
                                    width:'150px'
                                }"></ngx-skeleton-loader>
                            </div>
                        </div>
                    </div>

                    <div class="col-12 col-lg-8 d-flex flex-row"
                        *ngIf="!loading">
                        <div class="shareholder-profile-initials">
                            <span>
                                {{representative.person.full_name ?
                                getInitials(representative.person.full_name) :
                                '0*'}}
                            </span>
                        </div>
                        <app-spacer [right]="32"></app-spacer>
                        <div class="d-flex flex-column">
                            <span class="shareholder-profile-name">
                                {{ representative.person.full_name ?? '0*'}}
                            </span>
                            <div class="d-flex flex-row align-items-center">
                                <span class="shareholder-profile-type">
                                    Representante
                                </span>
                            </div>
                        </div>
                    </div>

                    <!-- <div class="col-12 col-lg-4 d-flex justify-content-end">
                        <ng-container *ngIf="loading">
                            <ngx-skeleton-loader [theme]="{                                    
                            height: '44px',
                            width: '139px',
                            margin:'0'
                          }">
                            </ngx-skeleton-loader>
                        </ng-container>
                    </div> -->
                </div>
            </div>
            <app-spacer [bottom]="24"></app-spacer>

            <!-- <ng-container *ngIf="loading">
                <ngx-skeleton-loader [theme]="{                                    
                height: '140px',
                width: '100%',
                margin:'0'
              }">
                </ngx-skeleton-loader>
            </ng-container> -->

            <app-spacer [bottom]="60"></app-spacer>

            <ng-container *ngIf="loading">
                <div class="row">
                    <div class="col-2" *ngFor="let number of [0,1]">
                        <ngx-skeleton-loader [theme]="{                                    
                height: '38px',
                margin:'0'
              }">
                        </ngx-skeleton-loader>
                    </div>
                </div>
            </ng-container>

            <ng-container *ngIf="!loading">
                <div class="d-flex">
                    <app-spacer [left]="20"></app-spacer>
                    <app-tabs [tabs]="tabs" (onChangeTab)="onChangeTab($event)"
                        [currentTab]="tabIndex" [dashboardTab]="true">
                    </app-tabs>
                </div>
            </ng-container>

            <app-spacer [bottom]="24"></app-spacer>

            <div [class.d-none]="activedTab !== 'dados-basicos'">
                <app-dashboard-representative-approval-basic
                    [approval]="representative">
                </app-dashboard-representative-approval-basic>
            </div>

            <ng-container *ngIf="!loading">
                <div [class.d-none]="activedTab !== 'vinculos'">
                    <app-dashboard-representative-approval-bonds
                        [approval]="representative">
                    </app-dashboard-representative-approval-bonds>
                </div>
            </ng-container>
        </div>
    </div>
</div>