import {
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/services/api/api.service';
import { SignupService } from 'src/app/services/signup/signup.service';
import { DomSanitizer } from '@angular/platform-browser';
import Utils from 'src/app/helpers/utils';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-dashboard-approval-signatures',
  templateUrl: './dashboard-approval-signatures.component.html',
  styleUrls: ['./dashboard-approval-signatures.component.scss'],
})
export class DashboardApprovalSignaturesComponent implements OnInit, OnDestroy {
  @ViewChild('modalRoot') modalRoot;
  private observer: MutationObserver;

  type_dict = {
    assignor: 'Cedente',
    representative: 'Representante',
    provider: 'Prestador de serviço',
  };

  columns: TableColumnList<any>[] = [
    {
      name: 'Nome',
      cell: (row) => {
        const is_person = row.register.type == 'PF';
        const person = is_person ? row.register.person : row.register.company;
        const name = is_person ? person.full_name : person.corporate_name;

        return `
        <div class="d-flex align-items-center">
            <span class="app-table-details-body-row-initials">
                ${this.getInitials(name)}
            </span>

            <div class="dash-card-item-content">
                <div class="dash-card-item-content-title">
                ${name}
                </div>

                <div class="dash-card-item-content-subtitle">
                ${row.type_display}
                </div>
            </div>

        </div>
        `;
      },
      size: 2,
    },
    {
      name: 'Vínculo',
      cell: (row) => row.type_display,
      size: 2,
    },
    {
      name: 'Data',
      cell: (row) => this.formatDate(row.updated_at),
    },
    {
      name: 'Assinado',
      align: 'center',
      cell: (row) => {
        if (row.signed) {
          if (row.approved) {
            return `
            <i class="fas fa-check text-center text-success h4 m-0"></i>
          `;
          }

          return `
            <i class="fas fa-times text-center text-danger h4 m-0"></i>
          `;
        } else {
          return `
            <i class="fas fa-exclamation-triangle text-center text-warning h4 m-0"></i>
          `;
        }
      },
    },
    {
      name: '',
      cell: (row) => {
        return `
          <div class="d-flex align-items-center icon-container">
            ${row.signed ? 'Visualizar documento' : 'Aguardando assinatura'}
          </div>
        `;
      },
    },
  ];
  data: any = [];
  loading = true;

  file: any = null;

  isPDF = false;
  url: any;
  isXLS = false;

  constructor(
    private signupService: SignupService,
    private router: Router,
    private api: ApiService,
    private sanitizer: DomSanitizer,
    private route: ActivatedRoute,
    private authService: AuthService,
    private el: ElementRef
  ) {}

  ngOnInit(): void {
    if (this.authService.approvalData.signatures) {
      this.data = this.authService.approvalData.signatures;
      this.loading = false;
    } else {
      this.getApprovalData();
    }

    this.observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        const item = this.el.nativeElement.querySelector(
          '.document-visualizer'
        );

        if (item) {
          this.setHandlers();
        }
      });
    });

    this.observer.observe(this.el.nativeElement, {
      childList: true,
      subtree: true,
    });
  }

  ngOnDestroy() {
    if (this.observer) {
      this.observer.disconnect();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    this.data = changes.approval.currentValue.signatures;
    setTimeout(() => {
      this.setHandlers();
    }, 1000);
  }

  async getApprovalData() {
    try {
      const req_params = {
        register: this.route.snapshot.params.id,
        tab: 'signatures',
      };

      if (this.authService.approvalData.revision) {
        req_params['revision'] = this.authService.approvalData.revision;
        req_params['version'] = this.authService.approvalData.version;
      }

      const data = await this.api.get<UserApprovalResponse[]>({
        route: `api/v2/approvals/version/`,
        token: true,
        params: req_params,
      });

      this.authService.approvalData.signatures = data[0].register.signatures;
      this.data = data[0].register.signatures ?? [];
      this.loading = false;
    } catch (error) {
      console.log(error);
    }
  }

  setHandlers() {
    const visualizer = document.querySelectorAll('.document-visualizer');

    visualizer.forEach((button) => {
      button.removeAllListeners();

      button.addEventListener('click', (event) => {
        const el: any = event.currentTarget;
        const idClass: any = el.classList.item(1);
        const id = idClass.split('-')[1];

        const finded = this.data.find((item: any) => {
          if (item.id) {
            return Number(item.id) === Number(id);
          }

          return false;
        });

        if (finded) {
          this.openFile(finded);
        }
      });
    });

    const userId = document.querySelectorAll('.user-visualizer');

    userId.forEach((button) => {
      button.removeAllListeners();

      button.addEventListener('click', (event) => {
        const el: any = event.currentTarget;
        const idClass: any = el.classList.item(1);
        const id = idClass.split('-')[1];

        const finded = this.data.find((item: any) => {
          if (item.id) {
            return Number(item.id) === Number(id);
          }

          return false;
        });

        if (finded) {
          this.redirectUser(finded);
        }
      });
    });
  }

  openFile(attachment: any) {
    this.file = attachment;
    this.getImgUrl();
  }

  redirectUser(item: any) {
    this.router.navigateByUrl(
      'lorem'
      // `/app/details/${this.users[value].applicable}/${this.users[value].id}`
    );
  }

  openDocumentInNewTab(url: string) {
    window.open(`${url}`, '_blank');
  }

  getInitials(name: string) {
    if (name === undefined) {
      return '';
    }

    return Utils.getInitials(name);
  }

  getLinkName(value: number) {
    const link = this.signupService
      .choices()
      .representative_link.find((item) => item.value == value.toString());

    return link?.label;
  }

  formatDate(date: string) {
    const day = date.split('T').shift()?.split('-').reverse().join('/');
    const hours = date.split('T').pop()?.slice(0, 5);

    return `${day} às ${hours}`;
  }

  getImgUrl() {
    const regex = /\.([0-9a-z]+)(?:[\?#]|$)/i;

    const resultado = regex.exec(this.file.file);

    if (resultado && resultado[1]) {
      const extension = resultado[1].toLowerCase();

      switch (extension) {
        case 'pdf':
          this.isPDF = true;
          break;
        case 'xls':
        case 'xlsx':
          this.isXLS = true;
          break;
        default:
          this.isPDF = false;
          this.isXLS = false;
          break;
      }
    }

    let url: any = this.file.file;

    if (this.isPDF) {
      url = this.sanitizer.bypassSecurityTrustResourceUrl(this.file.file);
    }

    if (this.isXLS) {
      url = this.sanitizer.bypassSecurityTrustResourceUrl(
        `https://view.officeapps.live.com/op/embed.aspx?src=${this.file.file}`
      );
    }

    this.url = url;

    this.modalRoot.show();
  }
}
