<ng-container *ngIf="loading">
    <ngx-skeleton-loader [theme]="{
        width: '100%',
        height: '150px'
      }">
    </ngx-skeleton-loader>

    <app-spacer [bottom]="24"></app-spacer>

    <ngx-skeleton-loader [theme]="{
        width: '100%',
        height: '300px'
      }">
    </ngx-skeleton-loader>
</ng-container>

<ng-container *ngIf="!loading">
    <div class="dash-card no-padding">
        <div
            class=" dash-card-header d-flex align-items-start justify-content-between">
            <h2>Grupos de permissões</h2>
        </div>

        <div class="dash-card-body padding-container mb-5">
            <div class="col-12" style="padding: 0 30px 20px;">
                <div class="row">
                    <div class="col-lg-3">
                        <p class="title">
                            Nome do grupo de permissões</p>
                        <p class="label">{{data.group}}</p>
                    </div>

                    <div class="col-lg-3">
                        <p class="title">Criado por</p>
                        <p class="label">
                            {{data.created_by === '' ? 'Sistema' :
                            data.created_by}}
                        </p>
                    </div>

                    <div class="col-lg-3">
                        <p class="title">Última atualização</p>
                        <p class="label">{{data.updated_at}}</p>
                    </div>

                    <div class="col-lg-3 text-right">
                        <app-button (onClick)="addPermission()"
                            class="add-button" *ngIf="permissionObj.canChange">
                            Editar grupo de permissões
                        </app-button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="dash-card no-padding" *ngIf="permissionsArray.length > 0">
        <div
            class=" dash-card-header d-flex align-items-start justify-content-between">
            <h2>Permissões</h2>
        </div>

        <div class="permission-list">
            <ul class="permission-group">
                <ng-container *ngFor="let group of permissionsArray;">
                    <li class="permission-group-title">
                        {{group.type}}
                    </li>

                    <li *ngFor="let item of group.permissions;">
                        {{item.description}}
                    </li>
                </ng-container>
            </ul>
        </div>
    </div>
</ng-container>

<!-- <div class="col-12 ml-auto mr-auto mb-4">
    <div class="dash-card-pagination">
        <app-pagination [data]="[]" [show]="!loading" [dashPagination]="true">
        </app-pagination>
    </div>
</div> -->