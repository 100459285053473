import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api/api.service';
import { SignupService } from 'src/app/services/signup/signup.service';
import { ToastService } from 'src/app/services/toast/toast.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { Router } from '@angular/router';
import { FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-dashboard-groups',
  templateUrl: './dashboard-groups.component.html',
  styleUrls: ['./dashboard-groups.component.scss'],
})
export class DashboardGroupsComponent implements OnInit {
  permissionObj = {
    canAdd: true,
    canChange: true,
  };

  filterForm = this.formBuilder.group({
    name: [null, []],
    start_date: [null, []],
    end_date: [null, []],
  });

  data: GroupUsers[] = [];
  count = 0;
  offset = 0;

  showFilterModal = false;
  loading = true;
  hasFilters = false;

  constructor(
    private api: ApiService,
    private toast: ToastService,
    public signupService: SignupService,
    private router: Router,
    private formBuilder: FormBuilder,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.setPermissions();
    this.getData();

    this.filterForm.valueChanges.subscribe((values) => {
      this.hasFilters = Object.values(values).some(
        (value) => value !== null && value !== ''
      );
    });
  }

  async getData(page: number = 1) {
    try {
      const filters = {};

      if (this.hasFilters) {
        const values = this.filterForm.value;
        Object.entries(values).forEach(([key, value]: any) => {
          if (value !== null && value !== '') {
            filters[key] = value;
          }
        });
      }

      if (filters['start_date'] && !filters['end_date']) {
        const atualDate = new Date().toISOString().split('T')[0];
        filters['end_date'] = atualDate;
      }

      if (filters['end_date'] && !filters['start_date']) {
        filters['start_date'] = '2000-01-01';
      }

      const { offset, results, count } = await this.api.get({
        route: 'api/v2/user_group',
        token: true,
        params: {
          page: page,
          type: 'list',
          ...filters,
        },
      });

      this.count = count;
      this.data = results;
      this.offset = offset;
    } catch (error) {
      console.error(error);
      this.toast.show('error', 'Erro', 'Ocorreu um erro ao carregar os dados.');
    }
    this.loading = false;
  }

  redirectToDetails(id: any) {
    this.router.navigateByUrl(
      `/app/settings/permissions/history/details/${id}`
    );
  }

  addGroup() {
    this.router.navigateByUrl('/app/settings/groups/new');
  }

  editGroup(id: number) {
    this.router.navigateByUrl('/app/settings/groups/update/' + id);
  }

  changePageData(page: number) {
    this.loading = true;
    this.getData(page);
  }

  resetForm() {
    this.hasFilters = false;
    this.filterForm.reset();
    this.showFilterModal = false;
    this.loading = true;
    this.getData();
  }

  async filterData() {
    this.loading = true;

    try {
      const values = this.filterForm.value;

      const filters = {};

      Object.entries(values).forEach(([key, value]: any) => {
        if (value !== null && value !== '') {
          let newValue = value;
          if (key === 'start_date' || key === 'end_date') {
            newValue = value.split('/').reverse().join('-');
          }
          filters[key] = newValue;
        }
      });

      if (filters['start_date'] && !filters['end_date']) {
        const atualDate = new Date().toISOString().split('T')[0];
        filters['end_date'] = atualDate;
      }

      if (filters['end_date'] && !filters['start_date']) {
        filters['start_date'] = '2000-01-01';
      }

      const { results, count, offset } = await this.api.get({
        route: 'api/v2/user_group/',
        token: true,
        params: {
          type: 'list',
          page: 1,
          ...filters,
        },
      });

      this.hasFilters = true;
      this.count = count;
      this.data = results;
      this.offset = offset;
      this.showFilterModal = false;
    } catch (error) {
      console.error(error);
      this.toast.show('error', 'Erro', 'Ocorreu um erro ao carregar os dados.');
    }
    this.loading = false;
  }

  setPermissions() {
    const permissions = Object.keys(this.permissionObj).map(
      (key) => `de_can_${key.split('can').join('').toLowerCase()}_user_group`
    );

    permissions.forEach((permission, idx) => {
      this.permissionObj[Object.keys(this.permissionObj)[idx]] =
        this.authService.verifyPermission(permission);
    });
  }
}
