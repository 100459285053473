<div class="dashboard">
    <div class="row w-100">
        <div class="col-10 ml-auto mr-auto">
            <div class="dash-card">
                <!-- <app-back-button></app-back-button> -->

                <div class="row no-gutters">
                    <div class="col-12">
                        <div class="dash-card-header">
                            <span>Cedentes</span>
                        </div>
                    </div>

                    <div class="col-12 padding-container dash-card-body">
                        <div class="dash-card-statistics">
                            <div class="dash-card-statistics-item">
                                <span
                                    class="dash-card-statistics-title">Cadastros
                                    em aberto</span>

                                <span
                                    class="dash-card-statistics-value">{{assignors_statistics.initialized}}</span>
                            </div>

                            <div class="dash-card-statistics-item">
                                <span
                                    class="dash-card-statistics-title">Cadastros
                                    concluidos</span>
                                <app-spacer [bottom]="4"></app-spacer>

                                <span
                                    class="dash-card-statistics-value">{{assignors_statistics.completed}}</span>
                            </div>

                            <div class="dash-card-statistics-item">
                                <span class="dash-card-statistics-title">Total
                                    de cedentes</span>
                                <app-spacer [bottom]="4"></app-spacer>

                                <span
                                    class="dash-card-statistics-value">{{assignors_statistics.total}}</span>
                            </div>
                        </div>

                        <div clsas="styled-scroll">
                            <table class="bordered"
                                *ngIf="assignors.length > 0 && !loading">
                                <thead>
                                    <tr>
                                        <th>Cedente</th>
                                        <th>Estágio Atual</th>
                                        <th></th>
                                    </tr>
                                </thead>

                                <tbody>
                                    <tr *ngFor="let item of assignors">
                                        <td>
                                            <div class="dash-card-item">
                                                <div
                                                    class="dash-card-item-initials">
                                                    <span>{{getInitials(item)}}</span>
                                                </div>

                                                <app-spacer
                                                    [right]="16"></app-spacer>

                                                <div
                                                    class="dash-card-item-content">
                                                    <div
                                                        class="dash-card-item-content-title">
                                                        {{item.user.first_name}}
                                                        {{item.user.last_name}}
                                                    </div>
                                                    <div
                                                        class="dash-card-item-content-subtitle">
                                                        em
                                                        {{item.user.created_at |
                                                        date:'dd/MM/yyyy'}}
                                                    </div>
                                                </div>
                                            </div>
                                        </td>

                                        <td>
                                            <span
                                                class="status">{{getStatus(item)}}
                                            </span>
                                            <div class="status-aprovation">
                                                {{getStatusInfo(item)}}
                                            </div>
                                        </td>

                                        <!-- <td>
                                            <span class="status">{{item.step !== -1 ? item.department.department.name :
                                                'Aprovado'}}
                                            </span>
                                            <div class="status-aprovation">Aguardando aprovação
                                                ({{item.step}}/{{item.departments.length}})</div>
                                        </td> -->

                                        <td style="text-align: right;">
                                            <a
                                                [routerLink]="renderLink(item.user.role.applicable,item.id)">
                                                <button class="button">
                                                    <i class="fas fa-pen"></i>
                                                </button>
                                            </a>
                                        </td>
                                    </tr>

                                    <tr *ngIf="assignors.length === 0">
                                        <td colspan="3">
                                            <span
                                                class="dash-card-item-content-subtitle">Nenhum
                                                cedente
                                                adicionado</span>
                                        </td>
                                    </tr>

                                </tbody>
                                <app-spacer [bottom]="28"></app-spacer>
                            </table>

                            <div *ngIf="assignors.length === 0 && !loading">
                                <span
                                    class="dash-card-item-content-title text-center d-block">
                                    Nenhum cedente adicionado
                                </span>

                                <app-spacer [bottom]="30"></app-spacer>
                            </div>

                            <div class="pt-2 pb-2 text-center" *ngIf="loading">
                                <i
                                    class="fas fa-circle-notch fa-spin fa-xl"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="dash-card-pagination">
                <app-pagination [data]="assignors" [dashPagination]="true">
                </app-pagination>
            </div>

        </div>
    </div>
</div>