import { Component, OnInit } from '@angular/core';
import { SignupService } from 'src/app/services/signup/signup.service';
import { ToastService } from 'src/app/services/toast/toast.service';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import Swal from 'sweetalert2';
import { ApiService } from 'src/app/services/api/api.service';

@Component({
  selector: 'app-dashboard-company-permissions-groups',
  templateUrl: './dashboard-company-permissions-groups.component.html',
  styleUrls: ['./dashboard-company-permissions-groups.component.scss'],
})
export class DashboardCompanyPermissionsGroupsComponent implements OnInit {
  permissionObj = {
    canAdd: true,
    canChange: true,
    canDelete: true,
  };

  filterForm = this.formBuilder.group({
    name: [null, []],
  });

  data: any[] = [];
  loading = true;

  count = 0;
  offset = 0;

  hasFilters = false;
  showFilterModal = false;
  showDetails = false;
  roleOptions: SelectItem[] = [];

  constructor(
    private toast: ToastService,
    public signupService: SignupService,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private api: ApiService
  ) {}

  ngOnInit(): void {
    // if (!this.authService.verifyPermission(['de_can_view_audit'])) {
    //   this.toast.show(
    //     'error',
    //     'Aviso',
    //     'Você não tem permissão para acessar essa página.'
    //   );
    //   this.router.navigateByUrl('/app/dashboard');
    // }

    this.getData();

    this.filterForm.valueChanges.subscribe((values) => {
      this.hasFilters = Object.values(values).some(
        (value) => value !== null && value !== ''
      );
    });
  }

  async getData(page: number = 1) {
    try {
      const filters = {};

      if (this.hasFilters) {
        const values = this.filterForm.value;
        Object.entries(values).forEach(([key, value]: any) => {
          if (value !== null && value !== '') {
            filters[key] = value;
          }
        });
      }

      const hasFileters = Object.keys(filters).length > 0;

      const id = this.route.snapshot.params.id;
      const { results, count, offset } = await this.api.get(
        hasFileters
          ? {
              route: `api/v2/company/${id}/`,
              token: true,
              params: {
                tab: 'permissions',
                ...filters,
              },
            }
          : {
              route: `api/v2/company/${id}/?tab=permissions`,
              token: true,
            }
      );

      this.count = count;
      this.offset = offset;
      this.data = results ?? [];
    } catch (error) {
      console.error(error);
      this.toast.show('error', 'Erro', 'Ocorreu um erro ao carregar os dados.');
    }

    this.loading = false;
  }

  unboundFund(id: any) {
    Swal.fire({
      title: 'Atenção',
      text: 'Desassociar fundo?',
      icon: 'warning',
      showConfirmButton: true,
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'Sim, desassociar fundo',
      showCancelButton: true,
      cancelButtonColor: '#d33',
      cancelButtonText: 'Não, manter como está',

      showLoaderOnConfirm: true,
    });
  }

  resetForm() {
    this.hasFilters = false;
    this.filterForm.reset();
    this.showFilterModal = false;
    this.loading = true;
    this.getData();
  }

  async filterData() {
    this.loading = true;

    try {
      const values = this.filterForm.value;

      const filters = {};

      Object.entries(values).forEach(([key, value]: any) => {
        if (value !== null && value !== '') {
          let newValue = value;
          if (key === 'start_date' || key === 'end_date') {
            newValue = value.split('/').reverse().join('-');
          }
          filters[key] = newValue;
        }
      });

      const id = this.route.snapshot.params.id;
      const { results, count, offset } = await this.api.get({
        route: `api/v2/company/${id}/`,
        token: true,
        params: {
          tab: 'permissions',
          ...filters,
        },
      });

      this.hasFilters = true;
      this.count = count;
      this.offset = offset;
      this.data = results ?? [];
      this.showFilterModal = false;
    } catch (error) {
      console.error(error);
      this.toast.show('error', 'Erro', 'Ocorreu um erro ao carregar os dados.');
    }
    this.loading = false;
  }

  formatDocument(document: string) {
    return document.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
  }

  changePageData(page: number) {
    this.loading = true;
    this.getData(page);
  }
}
