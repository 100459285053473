import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import Utils from 'src/app/helpers/utils';
import { ApiService } from 'src/app/services/api/api.service';
import { FundService } from 'src/app/services/fund/fund.service';
import { ToastService } from 'src/app/services/toast/toast.service';
import { distinctUntilChanged } from 'rxjs/operators';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-dashboard-drafts-form',
  templateUrl: './dashboard-drafts-form.component.html',
  styleUrls: ['./dashboard-drafts-form.component.scss'],
})
export class DashboardDraftsFormComponent implements OnInit {
  form = this.formBuilder.group({
    fund: [null, []],
    name: [null, [Validators.required]],
    type: [null, [Validators.required]],

    initial_date: [
      null,
      [Validators.required, Utils.validateMaxMinToday, Utils.validateMinDate],
    ],
    final_date: [null, []],
    enabled: [true, []],

    roles: [[], [Validators.required]],
    agents: [[], []],
    coobligation: [null, []],

    content: [null, [Validators.required]],
  });

  applicableItems = [];

  coobligationItems = [
    { label: 'Sem coobrigação', value: false },
    { label: 'Com coobrigação', value: true },
  ];

  agentItems = [];

  typeItems = [];

  statusItems = [
    { label: 'Ativo', value: true },
    { label: 'Inativo', value: false },
  ];

  fundsItems = [];

  id = null;
  fundId = null;

  templateId = null;

  config = {
    theme: 'silver',
    height: '500',
    width: '100%',
    plugins:
      'print preview fullpage searchreplace autolink directionality visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists textcolor wordcount imagetools contextmenu colorpicker textpattern help',
    toolbar:
      'formatselect fontselect fontsizeselect | bold italic strikethrough forecolor backcolor | link | alignleft aligncenter alignright alignjustify indent outdent | numlist bullist outdent indent | removeformat',
    menubar: true,
    statusbar: true,
    branding: true,
  };

  shareholders: any[] = [];
  assignors: any[] = [];
  fund: any = {};
  roles: Role[] = [];
  agents: Agent[] = [];
  types: any[] = [];
  funds = [];

  user_choices: { [key: string]: SelectItem[] } = {};

  loading = true;

  title = '';

  documentation: any;
  baseDocumentation: any;

  categorized = {};

  constructor(
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private routeParams: ActivatedRoute,
    private router: Router,
    private api: ApiService,
    private toast: ToastService,
    public fundService: FundService,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    if (
      !this.authService.verifyPermission(['de_can_add_draft']) &&
      !this.authService.verifyPermission(['de_can_edit_draft'])
    ) {
      this.toast.show(
        'error',
        'Aviso',
        'Você não tem permissão para acessar essa página.'
      );
      this.router.navigateByUrl('/app/dashboard');
    }

    this.form.valueChanges.subscribe((values) => {
      Utils.getErrors(this.form);
    });

    this.fetchData();

    this.route.params.subscribe(({ id, fundId }) => {
      if (id !== 'new') {
        this.id = this.routeParams.snapshot.params.id;
        this.setData();
      } else {
        this.id = null;
      }

      if (fundId) {
        this.fundId = fundId;

        this.form.controls.fund.setValue(Number(fundId));
      }
    });

    this.getDocumentVariables();
    this.generateDocumentation();

    this.form.controls.final_date.valueChanges
      .pipe(distinctUntilChanged((a: any, b: any) => a === b))
      .subscribe((value) => {
        if (value) {
          this.form.controls.final_date.setValidators([
            (control: FormControl) => Utils.validateMaxMinToday(control, false),
            Utils.validateMaxDate,
          ]);
        } else {
          this.form.controls.final_date.setValidators([]);
        }

        this.form.controls.final_date.updateValueAndValidity();
      });

    this.form.controls.roles.valueChanges.subscribe((value) => {
      const roles = this.roles.filter((item) => value.includes(item.id));

      if (roles.some((item) => item.applicable === 'provider')) {
        this.form.controls.agents.setValidators([Validators.required]);
      } else {
        this.form.controls.agents.setValidators([]);
      }

      if (roles.some((item) => item.applicable === 'assignor')) {
        this.form.controls.coobligation.setValidators([Validators.required]);
      } else {
        this.form.controls.coobligation.setValidators([]);
      }

      this.form.controls.agents.updateValueAndValidity();
      this.form.controls.coobligation.updateValueAndValidity();
    });
  }

  async fetchData() {
    await Promise.all([
      this.fetchRoles(),
      this.fetchAgents(),
      this.fetchTypes(),
      this.fetchFunds(),
    ]);

    this.loading = false;
  }

  async fetchFunds() {
    try {
      const { data } = await this.api.get<ApiResponse<Fund[]>>({
        route: 'api/registration/new/fund/',
        token: true,
      });

      this.funds = data;

      const mappedData = data.map((item) => ({
        label: item.name,
        value: item.id,
      }));

      this.fundsItems = mappedData;
    } catch (error) {
      console.log(error);

      this.toast.show(
        'error',
        'Erro',
        'Ocorreu um erro ao carregar os dados de papeis.'
      );
    }
  }

  async fetchRoles() {
    try {
      const response = await this.api.get<Role[]>({
        route: 'api/roles',
        token: true,
      });

      this.roles = response;

      const acceptedApplicables = ['shareholder', 'assignor', 'provider'];

      const mappedData = response
        .filter((item) => acceptedApplicables.includes(item.applicable))
        .map((item) => ({
          label: `${item.applicable_display} - ${item.name}`,
          value: item.id,
        }))
        .sort((a, b) => {
          if (a.label < b.label) return -1;
          if (a.label > b.label) return 1;
          return 0;
        });

      this.applicableItems = mappedData;
    } catch (error) {
      console.log(error);

      this.toast.show(
        'error',
        'Erro',
        'Ocorreu um erro ao carregar os dados de papeis.'
      );
    }
  }

  async fetchAgents() {
    try {
      const response = await this.api.get<Agent[]>({
        route: 'api/registration/agent',
        token: true,
      });

      this.agents = response;

      const mappedData = response
        .map((item) => ({
          label: item.name,
          value: item.id,
        }))
        .sort((a, b) => {
          if (a.label < b.label) return -1;
          if (a.label > b.label) return 1;
          return 0;
        });

      this.agentItems = mappedData;
    } catch (error) {
      console.log(error);

      this.toast.show(
        'error',
        'Erro',
        'Ocorreu um erro ao carregar os dados de agentes.'
      );
    }
  }

  async fetchTypes() {
    try {
      const { data } = await this.api.get<ApiResponse<any[]>>({
        route: 'api/document-type',
        token: true,
      });

      this.types = data;

      const mappedData = data
        .map((item) => ({
          label: item.name,
          value: item.id,
        }))
        .sort((a, b) => {
          if (a.label < b.label) return -1;
          if (a.label > b.label) return 1;
          return 0;
        });

      this.typeItems = mappedData;
    } catch (error) {
      console.log(error);

      this.toast.show(
        'error',
        'Erro',
        'Ocorreu um erro ao carregar os dados de tipos de documento.'
      );
    }
  }

  async setData() {
    try {
      const { data } = await this.api.get({
        route: `api/draft/${this.id}/`,
        token: true,
      });

      this.form.patchValue({
        ...data,
        type: data.type.id,
        agents: data.agents.map((item) => item.id),
        roles: data.roles.map((item) => item.id),
        initial_date: data.initial_date
          ? data.initial_date.split('-').reverse().join('/')
          : null,
        final_date: data.final_date
          ? data.final_date.split('-').reverse().join('/')
          : null,
      });

      this.title = data.name;
    } catch (error) {
      console.error(error);
    }
  }

  getBackLink() {
    if (this.fundId) {
      return `/app/funds/approval/${this.fundId}`;
    } else {
      return '/app/drafts';
    }
  }

  redirect() {
    if (this.fundId) {
      this.router.navigate([`/app/funds/approval/${this.fundId}`], {
        state: { step: 9 },
      });
    } else {
      this.router.navigate(['/app/drafts']);
    }
  }

  getDisabled() {
    return !this.form.valid;
  }

  async createTemplate() {
    try {
      const payload = {
        ...this.form.value,
        coobligation: this.form.controls.coobligation.value ?? false,
        final_date: this.form.controls.final_date.value
          ? this.form.controls.final_date.value.split('/').reverse().join('-')
          : null,
        initial_date: this.form.controls.initial_date.value
          .split('/')
          .reverse()
          .join('-'),
      };

      let response = null;

      if (this.id) {
        response = await this.api.patch({
          route: `api/draft/${this.id}/`,
          token: true,
          body: payload,
        });
      } else {
        response = await this.api.post({
          route: `api/draft/`,
          token: true,
          body: payload,
        });
      }

      this.toast.show(
        'info',
        'Sucesso',
        this.id
          ? 'Template atualizado, já está disponível para ser utilizado pelo sistema'
          : 'Um novo template foi criado e já está disponível para ser utilizado pelo sistema'
      );

      const { data } = response;

      this.redirect();
    } catch (error) {
      console.error(error);
    }
  }

  generateEndereco(tipo) {
    return {
      logradouro: `Logradouro do ${tipo}`,
      numero: `Número do ${tipo}`,
      complemento: `Complemento do ${tipo}`,
      bairro: `Bairro do ${tipo}`,
      cidade: `Cidade do ${tipo}`,
      estado: `Estado do ${tipo}`,
      cep: `CEP do ${tipo}`,
    };
  }

  generateUsuario(tipo) {
    return {
      nome: `Nome completo do ${tipo}`,
      documento: `Número do documento do ${tipo}`,
      endereco: this.generateEndereco(tipo),
    };
  }

  generateData() {
    return {
      dia: `Dia`,
      mes: `Mês`,
      ano: `Ano`,
      hora: `Hora`,
      minutos: `Minutos`,
    };
  }

  generateFundo(tipo) {
    return {
      nome: `Nome do ${tipo}`,
      documento: `Número do documento do ${tipo}`,
    };
  }

  generateBank(tipo) {
    return {
      banco: `Nome do banco do ${tipo}`,
      agencia: `Número da agência do ${tipo}`,
      conta: `Número da conta do ${tipo}`,
    };
  }

  generateDocumentationArray(obj, prefix) {
    const result = [];
    Object.keys(obj).forEach((key) => {
      if (typeof obj[key] === 'object') {
        result.push(
          ...this.generateDocumentationArray(obj[key], `${prefix}${key}.`)
        );
      } else {
        result.push(`{{${prefix}${key}}}: ${obj[key]}`);
      }
    });
    return result;
  }

  generatePrimaryArray = (obj) => {
    const array = [];

    Object.keys(obj).forEach((key) => {
      array.push({
        name: key,
        children: this.generateDocumentationArray(obj[key], `${key}.`),
      });
    });

    return array;
  };

  generateDocumentation() {
    const documentation = {
      fundo: { ...this.generateFundo('Fundo'), ...this.generateBank('Fundo') },
      cedente: {
        ...this.generateUsuario('Cedente'),
        ...this.generateBank('Cedente'),
      },
      administrador: this.generateUsuario('Administrador'),
      gestor: this.generateUsuario('Gestor'),
      consultor: this.generateUsuario('Consultor'),
      data: this.generateData(),
    };

    this.documentation = this.generatePrimaryArray(documentation);
  }

  async getDocumentVariables() {
    try {
      // arrumar url padrão
      const res = await this.api.get({
        route: `api/registration/new/fund/${
          this.fundId ? this.fundId + '/' : 'me/'
        }variables/`,
        token: true,
      });

      const flatted = this.flattenObject(res);

      let newCategorized = [];

      Object.keys(flatted).forEach((key) => {
        const category = key.split('.')[0];

        const findedIndex = newCategorized.findIndex(
          (item) => item.title === category
        );

        if (findedIndex !== -1) {
          const index = newCategorized.findIndex(
            (item) => item.title === category
          );

          newCategorized[index].keys.push({
            label: key,
            value: flatted[key],
          });
        } else {
          newCategorized = [
            ...newCategorized,
            {
              title: category,
              keys: [
                {
                  label: key,
                  value: flatted[key],
                },
              ],
            },
          ];
        }
      });

      this.documentation = newCategorized;
      this.baseDocumentation = newCategorized;
    } catch (error) {
      console.error(error);
    }
  }

  flattenObject(ob) {
    var toReturn = {};

    for (var i in ob) {
      if (!ob.hasOwnProperty(i)) continue;

      if (typeof ob[i] == 'object' && ob[i] !== null) {
        var flatObject = this.flattenObject(ob[i]);
        for (var x in flatObject) {
          if (!flatObject.hasOwnProperty(x)) continue;

          toReturn[i + '.' + x] = flatObject[x];
        }
      } else {
        toReturn[i] = ob[i];
      }
    }
    return toReturn;
  }

  renderValue(value) {
    return `{{${value}}}`;
  }

  verifyIdIsApplicable(field: string, applicable: string) {
    const fieldValue = this.form.controls[field].value;

    const roles = this.roles.filter((item) => fieldValue.includes(item.id));

    return roles.some((item) => item.applicable === applicable);
  }
}
