import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api/api.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ToastService } from 'src/app/services/toast/toast.service';

type ContributorsPermissions = {
  department: boolean;
  collaborator: boolean;
  witnesses: boolean;
};
@Component({
  selector: 'app-dashboard-contributors',
  templateUrl: './dashboard-contributors.component.html',
  styleUrls: ['./dashboard-contributors.component.scss'],
})
export class DashboardContributorsComponent implements OnInit {
  canView: ContributorsPermissions = {
    department: false,
    witnesses: false,
    collaborator: false,
  };

  departments: any[] = [];
  departmentLoading = true;

  constructor(
    private authService: AuthService,
    private api: ApiService,
    private toast: ToastService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.setPermissions();

    if (
      !this.canView.department &&
      !this.canView.witnesses &&
      !this.canView.collaborator
    ) {
      this.toast.show(
        'error',
        'Aviso',
        'Você não tem permissão para acessar essa página.'
      );
      this.router.navigateByUrl('/app/dashboard');
    }

    this.getDepartments();
  }

  setPermissions() {
    const permissionArr = [
      'de_can_view_department',
      'de_can_view_witnesses',
      'de_can_view_collaborator',
    ];

    permissionArr.forEach((permission) => {
      const label = permission.split('_').slice(3).join('_');

      this.canView[`${label}`] = this.authService.verifyPermission([
        permission,
      ]);
    });
  }

  async getDepartments() {
    try {
      const res = await this.api.get({
        route: 'api/departments',
        token: true,
      });

      this.departments = res;
      this.departmentLoading = false;
    } catch (error) {
      this.toast.show('error', 'Erro!', error.error.detail);
    }
  }
}
