import { Component, OnInit } from '@angular/core';
import { SignupService } from 'src/app/services/signup/signup.service';
import { ToastService } from 'src/app/services/toast/toast.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/services/api/api.service';
import { MaskPipe } from 'ngx-mask';
import { FormBuilder } from '@angular/forms';
import Swal from 'sweetalert2';
import { AuthService } from 'src/app/services/auth/auth.service';

type DepartmentProps = {
  id: number;
  name: string;
};

@Component({
  selector: 'app-dashboard-company-bond-users',
  templateUrl: './dashboard-company-bond-users.component.html',
  styleUrls: ['./dashboard-company-bond-users.component.scss'],
})
export class DashboardCompanyBondUsersComponent implements OnInit {
  permissionObj = {
    canChange: true,
  };

  filterForm = this.formBuilder.group({
    user_name: [null, []],
    user_document: [null, []],
    user_status: [null, []],
    user_department: [null, []],
    user_group_permission: [null, []],
  });

  statusOptions: SelectItem[] = [
    { label: 'Ativo', value: 'true' },
    { label: 'Inativo', value: 'false' },
  ];

  departmentOptions: SelectItem[] = [];

  permissionGroupOptions: SelectItem[] = [];

  data: any[] = [];
  loading = true;
  hasFilters = false;
  showFilterModal = false;
  count = 0;
  offset = 0;

  constructor(
    private toast: ToastService,
    private api: ApiService,
    public signupService: SignupService,
    private route: ActivatedRoute,
    private maskPipe: MaskPipe,
    private router: Router,
    private formBuilder: FormBuilder,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.setPermissions();
    this.getData();

    this.getOptions();

    this.filterForm.valueChanges.subscribe((values) => {
      this.hasFilters = Object.values(values).some(
        (value) => value !== null && value !== ''
      );
    });
  }

  async getOptions() {
    await Promise.all([
      this.getDepartmentOptions(),
      this.getPermissionGroups(),
    ]);
  }

  async getPermissionGroups() {
    try {
      const id = this.route.snapshot.params.id;

      const res = await this.api.get({
        route: `api/v2/user_group/?company=${id}`,
        token: true,
      });

      this.permissionGroupOptions = res.map((item: PermissionListValues) => ({
        label: item.group,
        value: item.id,
      }));
    } catch (error) {
      console.log(error);
      this.toast.show(
        'error',
        'Erro',
        'Ocorreu um erro ao carregar grupos de permissão.'
      );
    }
  }

  async getData(page: number = 1) {
    try {
      const filters = {};

      if (this.hasFilters) {
        const values = this.filterForm.value;
        Object.entries(values).forEach(([key, value]: any) => {
          if (value !== null && value !== '') {
            filters[key] = value;
          }
        });
      }

      const id = this.route.snapshot.params.id;

      const { results, count, offset } = await this.api.get({
        route: `api/v2/company/${id}/`,
        token: true,
        params: {
          tab: 'users',
          page,
          ...filters,
        },
      });

      this.count = count;
      this.offset = offset;
      this.data = results ?? [];
    } catch (error) {
      console.error(error);
      this.toast.show('error', 'Erro', 'Ocorreu um erro ao carregar os dados.');
    }
    this.loading = false;
  }

  resetForm() {
    this.hasFilters = false;
    this.filterForm.reset();
    this.showFilterModal = false;
    this.loading = true;
    this.getData();
  }

  formatPhone(item: { ddi: string; number: string }) {
    const ddiCode = item.ddi ? `+${item.ddi} ` : '';
    const checkNumberLength = item.number.length === 9;
    const formattedNumber = this.maskPipe.transform(
      item.number,
      checkNumberLength ? '00000-0000' : '0000-0000'
    );

    return `${ddiCode}${formattedNumber}`;
  }

  async getDepartmentOptions() {
    try {
      const res = await this.api.get({
        route: 'department/',
        token: true,
      });

      this.departmentOptions = res.map((item: DepartmentProps) => ({
        label: item.name,
        value: item.id,
      }));
    } catch (error) {
      this.toast.show('error', 'Erro', 'Erro ao buscar departamentos');
    }
  }

  formatDocument(item: string) {
    if (item.length === 11) {
      return item.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})$/, '$1.$2.$3-$4');
    } else {
      return item.replace(
        /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/,
        '$1.$2.$3/$4-$5'
      );
    }
  }

  changePageData(page: number) {
    this.loading = true;
    this.getData(page);
  }

  async filterData() {
    this.loading = true;

    try {
      const values = this.filterForm.value;

      const filters = {};

      Object.entries(values).forEach(([key, value]: any) => {
        if (value !== null && value !== '') {
          let newValue = value;
          if (key === 'start_date' || key === 'end_date') {
            newValue = value.split('/').reverse().join('-');
          }
          filters[key] = newValue;
        }
      });

      if (filters['start_date'] && !filters['end_date']) {
        const atualDate = new Date().toISOString().split('T')[0];
        filters['end_date'] = atualDate;
      }

      if (filters['end_date'] && !filters['start_date']) {
        filters['start_date'] = '2000-01-01';
      }

      const id = this.route.snapshot.params.id;

      const { results, count, offset } = await this.api.get({
        route: `api/v2/company/${id}/`,
        token: true,
        params: {
          tab: 'users',
          page: 1,
          ...filters,
        },
      });

      this.count = count;
      this.offset = offset;
      this.data = results ?? [];
      this.hasFilters = true;
      this.showFilterModal = false;
    } catch (error) {
      console.error(error);
      this.toast.show('error', 'Erro', 'Ocorreu um erro ao carregar os dados.');
    }
    this.loading = false;
  }

  async handleChangeStatus(id: string) {
    try {
      const finded = this.data.find((user) => user.id === id);
      const isActive = finded.status === 'Ativo' ? true : false;

      const { isConfirmed } = await Swal.fire({
        title: isActive
          ? 'Deseja desativar esse usuário?'
          : 'Deseja ativar esse usuário?',
        text: isActive
          ? 'O usuário não poderá mais acessar o sistema com as permissões atuais.'
          : 'O usuário poderá acessar o sistema com as permissões atuais.',
        icon: 'warning',
        showConfirmButton: true,
        confirmButtonColor: '#3085d6',
        confirmButtonText: isActive ? 'Desativar' : 'Ativar',
        showCancelButton: true,
        cancelButtonColor: '#d33',
        cancelButtonText: 'Cancelar',
      });

      if (!isConfirmed) {
        return;
      }

      await this.api.patch({
        route: `api/v2/user_group_user/${id}/`,
        token: true,
        body: {
          status: isActive ? 'Inativo' : 'Ativo',
        },
      });

      const index = this.data.findIndex((user) => user.id === id);
      this.data[index].status = isActive ? 'Inativo' : 'Ativo';

      this.toast.show(
        'info',
        'Sucesso',
        'Status do usuário alterado com sucesso.'
      );
    } catch (error) {
      console.error(error);
      this.toast.show(
        'error',
        'Erro',
        'Ocorreu um erro ao mudar status do usuário.'
      );
    }
  }

  setPermissions() {
    const permissions = Object.keys(this.permissionObj).map(
      (key) =>
        `de_can_${key.split('can').join('').toLowerCase()}_user_group_company`
    );

    permissions.forEach((permission, idx) => {
      this.permissionObj[Object.keys(this.permissionObj)[idx]] =
        this.authService.verifyPermission(permission);
    });
  }

  redirectToDetails(id: any) {
    this.router.navigateByUrl(`/app/settings/groups/user/details/${id}`);
  }
}
