import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api/api.service';
import { SignupService } from 'src/app/services/signup/signup.service';
import { ToastService } from 'src/app/services/toast/toast.service';
import { Router } from '@angular/router';
import { FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-dashboard-users-group',
  templateUrl: './dashboard-users-group.component.html',
  styleUrls: ['./dashboard-users-group.component.scss'],
})
export class DashboardUsersGroupComponent implements OnInit {
  filterForm = this.formBuilder.group({
    name: [null, []],
    company: [null, []],
    start_date: [null, []],
    end_date: [null, []],
  });

  data: GroupUsers[] = [];

  loading = true;
  hasFilters = false;
  showFilterModal = false;
  count = 0;
  offset = 0;

  constructor(
    private toast: ToastService,
    public signupService: SignupService,
    private router: Router,
    private api: ApiService,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit(): void {
    // if (!this.authService.verifyPermission(['de_can_view_audit'])) {
    //   this.toast.show(
    //     'error',
    //     'Aviso',
    //     'Você não tem permissão para acessar essa página.'
    //   );
    //   this.router.navigateByUrl('/app/dashboard');
    // }

    this.getData();

    this.filterForm.valueChanges.subscribe((values) => {
      this.hasFilters = Object.values(values).some(
        (value) => value !== null && value !== ''
      );
    });
  }

  addAudit() {
    return;
  }

  async getData(page: number = 1) {
    try {
      const filters = {};

      if (this.hasFilters) {
        const values = this.filterForm.value;
        Object.entries(values).forEach(([key, value]: any) => {
          if (value !== null && value !== '') {
            filters[key] = value;
          }
        });
      }

      if (filters['start_date'] && !filters['end_date']) {
        const atualDate = new Date().toISOString().split('T')[0];
        filters['end_date'] = atualDate;
      }

      if (filters['end_date'] && !filters['start_date']) {
        filters['start_date'] = '2000-01-01';
      }

      const { count, results, offset } = await this.api.get({
        route: 'api/v2/user_group/',
        token: true,
        params: {
          page,
          type: 'list',
          ...filters,
        },
      });

      this.count = count;
      this.offset = offset;
      this.data = results ?? [];
    } catch (error) {
      console.error(error);
      this.toast.show('error', 'Erro', 'Ocorreu um erro ao carregar os dados.');
    }

    this.loading = false;
  }

  resetForm() {
    this.hasFilters = false;
    this.filterForm.reset();
    this.showFilterModal = false;
    this.loading = true;
    this.getData();
  }

  redirectToDetails(id: any) {
    this.router.navigateByUrl(`/app/settings/permissions/group/details/${id}`);
  }

  changePageData(page: number) {
    this.loading = true;
    this.getData(page);
  }

  async filterData() {
    this.loading = true;

    try {
      const values = this.filterForm.value;

      const filters = {};

      Object.entries(values).forEach(([key, value]: any) => {
        if (value !== null && value !== '') {
          let newValue = value;
          if (key === 'start_date' || key === 'end_date') {
            newValue = value.split('/').reverse().join('-');
          }
          filters[key] = newValue;
        }
      });

      if (filters['start_date'] && !filters['end_date']) {
        const atualDate = new Date().toISOString().split('T')[0];
        filters['end_date'] = atualDate;
      }

      if (filters['end_date'] && !filters['start_date']) {
        filters['start_date'] = '2000-01-01';
      }

      const { results, count, offset } = await this.api.get({
        route: 'api/v2/user_group/',
        token: true,
        params: {
          page: 1,
          type: 'list',
          ...filters,
        },
      });

      this.hasFilters = true;
      this.count = count;
      this.data = results;
      this.offset = offset;
      this.showFilterModal = false;
    } catch (error) {
      console.error(error);
      this.toast.show('error', 'Erro', 'Ocorreu um erro ao carregar os dados.');
    }
    this.loading = false;
  }
}
