import { TypeGuard } from './guards/type/type.guard';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { Routes, RouterModule } from '@angular/router';

// Guards
import { AuthGuard } from './guards/auth/auth.guard';
import { RoleGuard } from './guards/role/role.guard';
import { CompleteGuard } from './guards/complete/complete.guard';
import { UnauthGuard } from './guards/unauth/unauth.guard';

// Auth
import { LoginComponent } from './pages/login/login.component';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
import { SignupStatusComponent } from './pages/signup-status/signup-status.component';

// Signup Unified
import { SignupUnifiedBasicComponent } from './pages/signup-unified-basic/signup-unified-basic.component';
import { SignupUnifiedPersonComponent } from './pages/signup-unified-person/signup-unified-person.component';
import { SignupUnifiedCompanyComponent } from './pages/signup-unified-company/signup-unified-company.component';

// Dashboard
import { DashSidebarComponent } from './components/dash-sidebar/dash-sidebar.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { DashboardShareholderComponent } from './pages/dashboard-shareholder/dashboard-shareholder.component';

import { DashboardSecurityComponent } from './pages/dashboard-security/dashboard-security.component';
import { DashboardRequestAcessComponent } from './pages/dashboard-request-acess/dashboard-request-acess.component';
import { DashboardOrganizationComponent } from './pages/dashboard-organization/dashboard-organization.component';
import { DashboardSettingsComponent } from './pages/dashboard-settings/dashboard-settings.component';
import { DashboardAgentsComponent } from './pages/dashboard-agents/dashboard-agents.component';
import { DashboardDocumentsComponent } from './pages/dashboard-documents/dashboard-documents.component';
import { DashboardZeroingFundsComponent } from './pages/dashboard-zeroing-funds/dashboard-zeroing-funds.component';
import { DashboardAuditComponent } from './pages/dashboard-audit/dashboard-audit.component';

import { DashboardContributorsComponent } from './pages/dashboard-contributors/dashboard-contributors.component';
import { DashboardDepartmentsComponent } from './pages/dashboard-departments/dashboard-departments.component';
import { DashboardCollaboratorsComponent } from './pages/dashboard-collaborators/dashboard-collaborators.component';
import { DashboardApprovalComponent } from './pages/dashboard-approval/dashboard-approval.component';
import { DashboardFundApprovalComponent } from './pages/dashboard-fund-approval/dashboard-fund-approval.component';
import { DashboardDocumentModelComponent } from './pages/dashboard-document-model/dashboard-document-model.component';
import { DashboardAssignorComponent } from './pages/dashboard-assignor/dashboard-assignor.component';
import { DashboardFundsComponent } from './pages/dashboard-funds/dashboard-funds.component';
import { DashboardAssignorsComponent } from './pages/dashboard-assignors/dashboard-assignors.component';
import { DashboardProvidersComponent } from './pages/dashboard-providers/dashboard-providers.component';
import { DashboardShareholdersComponent } from './pages/dashboard-shareholders/dashboard-shareholders.component';
import { DashboardUserListComponent } from './pages/dashboard-user-list/dashboard-user-list.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { RedirectUserComponent } from './pages/redirect-user/redirect-user.component';

import { DashboardFundApprovalRegisterComponent } from './pages/dashboard-fund-approval-register/dashboard-fund-approval-register.component';
import { DashboardAccountsComponent } from './pages/dashboard-accounts/dashboard-accounts.component';
import { DashboardWitnessesComponent } from './pages/dashboard-witnesses/dashboard-witnesses.component';
import { DashboardRepresentativeApprovalComponent } from './pages/dashboard-representative-approval/dashboard-representative-approval.component';
import { DashboardDraftsComponent } from './pages/dashboard-drafts/dashboard-drafts.component';
import { DashboardDraftsFormComponent } from './pages/dashboard-drafts-form/dashboard-drafts-form.component';
import { DashboardInvitesComponent } from './pages/dashboard-invites/dashboard-invites.component';
import { DashboardPermissionsDetailsComponent } from './pages/dashboard-permissions-group/dashboard-permissions/dashboard-permissions-details/dashboard-permissions-details.component';
import { DashboardPermissionsRegisterComponent } from './pages/dashboard-permissions-group/dashboard-permissions/dashboard-permissions-register/dashboard-permissions-register.component';
import { DashboardPermissionsGroupComponent } from './pages/dashboard-permissions-group/dashboard-permissions-group.component';
import { DashboardCompanyComponent } from './pages/dashboard-permissions-group/dashboard-companies/dashboard-company/dashboard-company.component';
import { DashboardUsersGroupDetailsComponent } from './pages/dashboard-permissions-group/dashboard-users-group/dashboard-users-group-details/dashboard-users-group-details.component';
import { DashboardHistoryDetailsComponent } from './pages/dashboard-permissions-group/dashboard-history/dashboard-history-details/dashboard-history-details.component';
import { DashboardGroupUsersComponent } from './pages/dashboard-group-users/dashboard-group-users.component';
import { DashboardUserDetailsComponent } from './pages/dashboard-group-users/dashboard-users/dashboard-user-details/dashboard-user-details.component';
import { DashboardUserHistoryDetailsComponent } from './pages/dashboard-group-users/dashboard-users/dashboard-user-details/dashboard-user-history-details/dashboard-user-history-details.component';
import { DashboardGroupRegisterComponent } from './pages/dashboard-group-users/dashboard-groups/dashboard-group-register/dashboard-group-register.component';
const getSignupRoutes = (name: string, role: string): Routes => [
  {
    path: 'basic',
    canActivate: [UnauthGuard],
    component: SignupUnifiedBasicComponent,
    data: {
      title: `Cadastro ${name}`,
      redirectTo: '/app/dashboard',
    },
  },
  {
    path: 'basic/:token',
    canActivate: [],
    component: SignupUnifiedBasicComponent,
    data: {
      title: `Cadastro ${name}`,
      redirectTo: '/app/dashboard',
    },
  },
  {
    path: 'pf',
    canActivate: [AuthGuard, TypeGuard],
    component: SignupUnifiedPersonComponent,
    data: {
      title: `Cadastro ${name} - PF`,
      guardType: 'PF',
      redirectTo: `/signup/${role}/pj`,
    },
  },
  {
    path: 'pj',
    canActivate: [AuthGuard, TypeGuard],
    component: SignupUnifiedCompanyComponent,
    data: {
      title: `Cadastro ${name} - PJ`,
      guardType: 'PJ',
      redirectTo: `/signup/${role}/pf`,
    },
  },
];

const routes: Routes = [
  {
    path: 'app',
    component: DashSidebarComponent,
    children: [
      {
        path: 'me',
        component: RedirectUserComponent,
      },
      {
        path: 'details',
        children: [
          {
            path: 'assignor/:id',
            component: DashboardApprovalComponent,
            data: {
              title: 'Painel Cedente',
              guardRole: 'ADM',
              redirectTo: '/app/dashboard',
            },
          },
          {
            path: 'shareholder/:id',
            component: DashboardApprovalComponent,
            data: {
              title: 'Painel Cotista',
              guardRole: 'ADM',
              redirectTo: '/app/dashboard',
            },
          },
          {
            path: 'representative/:id',
            component: DashboardRepresentativeApprovalComponent,
            data: {
              title: 'Painel Representante',
              guardRole: 'ADM',
              redirectTo: '/app/dashboard',
            },
          },
          {
            path: 'provider/:id',
            component: DashboardApprovalComponent,
            data: {
              title: 'Painel Prestador de Serviço',
              guardRole: 'ADM',
              redirectTo: '/app/dashboard',
            },
          },
          {
            path: 'assignor/:id/:token',
            component: DashboardApprovalComponent,
            data: {
              title: 'Painel Cedente',
            },
          },
          {
            path: 'shareholder/:id/:token',
            component: DashboardApprovalComponent,
            data: {
              title: 'Painel Cotista',
            },
          },
          {
            path: 'provider/:id/:token',
            component: DashboardApprovalComponent,
            data: {
              title: 'Painel Prestador de Serviço',
            },
          },
          {
            path: 'representative/:id/:token',
            component: DashboardApprovalComponent,
            data: {
              title: 'Painel Representante',
            },
          },
        ],
      },

      {
        path: 'dashboard',
        canActivate: [AuthGuard],
        data: {
          redirectTo: '/login',
        },
        children: [
          {
            path: '',
            // canActivate: ,
            component: DashboardComponent,
            data: {
              title: 'Painel',
              redirectTo: '/login',
            },
          },
          {
            path: 'assignor/:id',
            canActivate: [RoleGuard],
            component: DashboardAssignorComponent,
            data: {
              title: 'Painel Cedente',
              guardRole: 'ADM',
              redirectTo: '/app/dashboard',
            },
          },
          {
            path: 'shareholder/:id',
            canActivate: [RoleGuard],
            component: DashboardShareholderComponent,
            data: {
              title: 'Painel Cotista',
              guardRole: 'ADM',
              redirectTo: '/app/dashboard',
            },
          },
          {
            path: 'fund/approval/:id',
            component: DashboardFundApprovalComponent,
            data: {
              title: 'Painel Fundo',
              guardRole: 'ADM',
              redirectTo: '/app/dashboard',
            },
          },
          {
            path: 'fund/approval/:id/model',
            canActivate: [RoleGuard],
            component: DashboardDocumentModelComponent,
            data: {
              title: 'Modelo de documento',
              guardRole: 'ADM',
              redirectTo: '/app/dashboard',
            },
          },
          {
            path: 'fund/approval/:id/model/:templateId',
            canActivate: [RoleGuard],
            component: DashboardDocumentModelComponent,
            data: {
              title: 'Modelo de documento',
              guardRole: 'ADM',
              redirectTo: '/app/dashboard',
            },
          },
          {
            path: 'funds',
            canActivate: [RoleGuard],
            component: DashboardFundsComponent,
            data: {
              title: 'Painel Fundos',
              guardRole: 'ADM',
              redirectTo: '/app/dashboard',
            },
          },
          {
            path: 'assignors',
            canActivate: [RoleGuard],
            component: DashboardAssignorsComponent,
            data: {
              title: 'Painel Cedente',
              guardRole: 'ADM',
              redirectTo: '/app/dashboard',
            },
          },
          {
            path: 'providers',
            canActivate: [RoleGuard],
            component: DashboardProvidersComponent,
            data: {
              title: 'Painel Prestador de Serviço',
              guardRole: 'ADM',
              redirectTo: '/app/dashboard',
            },
          },
          {
            path: ':role/list',
            canActivate: [RoleGuard],
            component: DashboardUserListComponent,
            data: {
              title: 'Lista de pendentes',
              guardRole: 'ADM',
              redirectTo: '/app/dashboard',
            },
          },
          {
            path: 'shareholders',
            canActivate: [RoleGuard],
            component: DashboardShareholdersComponent,
            data: {
              title: 'Painel Cotistas',
              guardRole: 'ADM',
              redirectTo: '/app/dashboard',
            },
          },
        ],
      },
      {
        path: 'registers',
        children: [
          {
            path: '',
            canActivate: [AuthGuard],
            component: DashboardUserListComponent,
            data: {
              title: 'Lista de cadastros',
              redirectTo: '/login',
            },
          },
        ],
      },
      {
        path: 'security',
        children: [
          {
            path: '',
            canActivate: [CompleteGuard],
            component: DashboardSecurityComponent,
            data: {
              title: 'Segurança',
              redirectTo: '/app/dashboard',
            },
          },
        ],
      },
      {
        path: 'organizations',
        children: [
          {
            path: '',
            // canActivate: [RoleGuard],
            component: DashboardContributorsComponent,
            data: {
              title: 'Contribuidores',
              guardRole: 'ADM',
              redirectTo: '/app/dashboard',
            },
          },
          {
            path: 'departments',
            // canActivate: [RoleGuard],
            component: DashboardDepartmentsComponent,
            data: {
              title: 'Departamentos',
              guardRole: 'ADM',
              redirectTo: '/app/dashboard',
            },
          },
          {
            path: 'collaborators',
            // canActivate: [RoleGuard],
            component: DashboardCollaboratorsComponent,
            data: {
              title: 'Colaboradores',
              guardRole: 'ADM',
              redirectTo: '/app/dashboard',
            },
          },
          {
            path: 'witnesses',
            // canActivate: [RoleGuard],
            component: DashboardWitnessesComponent,
            data: {
              title: 'Testemunhas',
              guardRole: 'ADM',
              redirectTo: '/app/dashboard',
            },
          },
        ],
        data: {
          title: 'Usuários',
        },
      },
      {
        path: 'funds',
        data: {
          title: 'Produtos',
        },
        children: [
          {
            path: '',
            // canActivate: [CompleteGuard, RoleGuard],
            component: DashboardOrganizationComponent,
            data: {
              title: 'Produtos',
              guardRole: 'ADM',
              redirectTo: '/app/dashboard',
            },
          },
          {
            path: 'new',
            // canActivate: [RoleGuard],
            component: DashboardFundApprovalRegisterComponent,
            data: {
              title: 'Painel Fundo',
              guardRole: 'ADM',
              redirectTo: '/app/dashboard',
            },
          },
          {
            path: 'approval/:id',
            // canActivate: [RoleGuard],
            component: DashboardFundApprovalComponent,
            data: {
              title: 'Painel Fundo',
              guardRole: 'ADM',
              redirectTo: '/app/dashboard',
            },
          },
          {
            path: 'approval/update/:id',
            // canActivate: [RoleGuard],
            component: DashboardFundApprovalRegisterComponent,
            data: {
              title: 'Edição Fundo',
              guardRole: 'ADM',
              redirectTo: '/app/dashboard',
            },
          },
          {
            path: 'approval/:id/model',
            canActivate: [RoleGuard],
            component: DashboardDocumentModelComponent,
            data: {
              title: 'Modelo de documento',
              guardRole: 'ADM',
              redirectTo: '/app/dashboard',
            },
          },
          {
            path: 'approval/:id/model/:templateId',
            canActivate: [RoleGuard],
            component: DashboardDocumentModelComponent,
            data: {
              title: 'Modelo de documento',
              guardRole: 'ADM',
              redirectTo: '/app/dashboard',
            },
          },
        ],
      },
      {
        path: 'request-access',
        data: {
          title: 'Solicições de acesso',
          guardRole: 'ADM',
          redirectTo: '/app/dashboard',
        },
        children: [
          {
            path: '',
            component: DashboardRequestAcessComponent,
            data: {
              title: 'Solicições de acesso',
            },
          },
        ],
      },
      {
        path: 'drafts',
        data: {
          title: 'Minutas',
          guardRole: 'ADM',
          redirectTo: '/app/dashboard',
        },
        children: [
          {
            path: '',
            component: DashboardDraftsComponent,
            data: {
              title: 'Minutas',
            },
          },
          {
            path: ':id',
            component: DashboardDraftsFormComponent,
            data: {
              title: 'Formulário de minutas',
            },
          },
          {
            path: ':id/:fundId',
            component: DashboardDraftsFormComponent,
            data: {
              title: 'Formulário de minutas',
            },
          },
        ],
      },
      {
        path: 'settings',
        // canActivate: [RoleGuard],
        data: {
          title: 'Configurações',
          guardRole: 'ADM',
          redirectTo: '/app/dashboard',
        },
        children: [
          {
            path: '',
            component: DashboardSettingsComponent,
            data: {
              title: 'Configurações',
            },
          },
          {
            path: 'agents',
            component: DashboardAgentsComponent,
            data: {
              title: 'Agentes',
            },
          },
          {
            path: 'groups',
            children: [
              {
                path: '',
                component: DashboardGroupUsersComponent,
                data: {
                  title: 'Usuários e Grupos',
                },
              },
              {
                path: 'new',
                component: DashboardGroupRegisterComponent,
                data: {
                  title: 'Novo grupo',
                },
              },
              {
                path: 'update/:id',
                component: DashboardGroupRegisterComponent,
                data: {
                  title: 'Edição de grupo',
                },
              },
              {
                path: 'user/details/:id',
                component: DashboardUserDetailsComponent,
                data: {
                  title: 'Usuários e Grupos',
                },
              },
              {
                path: 'user/details/:id/history/:historyId',
                component: DashboardUserHistoryDetailsComponent,
                data: {
                  title: 'Usuários e Grupos',
                },
              },
            ],
          },
          {
            path: 'permissions',
            children: [
              {
                path: '',
                component: DashboardPermissionsGroupComponent,
                data: {
                  title: 'Permissões',
                },
              },
              {
                path: 'details/:id',
                component: DashboardPermissionsDetailsComponent,
                data: {
                  title: 'Grupo de permissões',
                },
              },
              {
                path: 'new',
                component: DashboardPermissionsRegisterComponent,
                data: {
                  title: 'Novo grupo de permissões',
                },
              },
              {
                path: 'update/:id',
                component: DashboardPermissionsRegisterComponent,
                data: {
                  title: 'Edição de grupo de permissões',
                },
              },
              {
                path: 'history',
                children: [
                  {
                    path: 'details/:id',
                    component: DashboardHistoryDetailsComponent,
                    data: {
                      title: 'Detalhes do histórico',
                    },
                  },
                ],
              },

              {
                path: 'group',
                children: [
                  {
                    path: 'details/:id',
                    component: DashboardUsersGroupDetailsComponent,
                    data: {
                      title: 'Detalhes do grupo',
                    },
                  },
                ],
              },

              {
                path: 'company',
                children: [
                  {
                    path: 'details/:id',
                    component: DashboardCompanyComponent,
                    data: {
                      title: 'Detalhes da empresa',
                    },
                  },
                ],
              },
            ],
          },
          {
            path: 'funds',
            component: DashboardZeroingFundsComponent,
            data: {
              title: 'Fundos de zeragem',
            },
          },
          {
            path: 'audits',
            component: DashboardAuditComponent,
            data: {
              title: 'Auditorias',
            },
          },
          {
            path: 'documents',
            component: DashboardDocumentsComponent,
            data: {
              title: 'Documentos',
            },
          },
        ],
      },
      {
        path: 'accounts',
        data: {
          title: 'Meus cadastros',
        },
        children: [
          {
            path: '',
            component: DashboardAccountsComponent,
          },
        ],
      },
      {
        path: 'invites',
        data: {
          title: 'Convites',
        },
        children: [
          {
            path: '',
            component: DashboardInvitesComponent,
          },
        ],
      },
    ],
  },
  {
    path: '',
    children: [
      {
        path: '',
        redirectTo: 'login',
        pathMatch: 'full',
      },
      {
        path: 'login',
        component: LoginComponent,
        canActivate: [UnauthGuard],
        data: {
          title: 'Autenticação',
          redirectTo: '/app/dashboard',
        },
      },
      {
        path: 'login/:token',
        component: LoginComponent,
        canActivate: [UnauthGuard],
        data: {
          title: 'Autenticação',
          redirectTo: '/app/dashboard',
        },
      },
      {
        path: 'reset-password',
        component: ResetPasswordComponent,
        canActivate: [UnauthGuard],
        data: {
          title: 'Recuperação de senha',
          redirectTo: '/app/dashboard',
        },
      },
      {
        path: 'signup',
        children: [
          {
            path: 'assignor',
            children: getSignupRoutes('Cedente', 'assignor'),
          },
          {
            path: 'provider',
            children: getSignupRoutes('Prestador de Serviço', 'provider'),
          },
          {
            path: 'shareholder',
            children: getSignupRoutes('Cotista', 'shareholder'),
          },
          {
            path: 'guarantor',
            children: getSignupRoutes('Avalista', 'guarantor'),
          },
        ],
      },
      {
        path: 'status',
        canActivate: [AuthGuard],
        data: { redirectTo: '/login' },
        children: [
          {
            path: 'pf',
            component: SignupStatusComponent,
            data: {
              title: 'Status PF',
            },
          },
          {
            path: 'pj',
            component: SignupStatusComponent,
            data: {
              title: 'Status PJ',
            },
          },
        ],
      },
    ],
  },
  { path: '**', pathMatch: 'full', component: NotFoundComponent },
];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
