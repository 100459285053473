<div class="row">
    <div class="col-12 ml-auto mr-auto">
        <div class="dash-card fund-dash">
            <ng-container *ngIf="!loading">
                <!-- <app-back-button></app-back-button> -->
            </ng-container>
            <div class="dash-card-header">
                <ng-container *ngIf="loading">
                    <ngx-skeleton-loader [theme]="{
              height: '31px',
              width: '100px',
              margin: '0'
            }">
                    </ngx-skeleton-loader>
                </ng-container>

                <ng-container *ngIf="!loading">
                    <span>
                        {{ applicableRole === "ADM" ? "Fundos" : "Vínculos" }}
                    </span>
                </ng-container>
            </div>

            <div class="fund-dash-header-buttons">
                <ng-container *ngIf="loading">
                    <ngx-skeleton-loader [theme]="{
              height: '41px',
              width: '180px',
              marginBottom: '0'
            }">
                    </ngx-skeleton-loader>

                    <ngx-skeleton-loader [theme]="{
              height: '41px',
              width: '180px',
              marginBottom: '0'
            }">
                    </ngx-skeleton-loader>
                </ng-container>

                <ng-container *ngIf="!loading">
                    <div class="d-flex align-items-center">
                        <!-- <app-button [inverted]="true" (onClick)="getData()"
                            class="add-button reverse">
                            <i class="fa-solid fa-rotate-right"></i>
                            Atualizar
                        </app-button>
                        <app-spacer [right]="32"></app-spacer> -->

                        <app-button [inverted]="true" class="add-button"
                            (onClick)="showFilterModal = true">
                            <svg-icon
                                src="assets/images/icon_filter.svg"></svg-icon>
                            Aplicar filtros
                        </app-button>
                        <!-- <app-spacer [right]="16"></app-spacer>

                        <app-select [items]="selectItems" controlName="perPage"
                            [group]="perPageForm"
                            placeholder="Selecione a opção">
                        </app-select> -->
                    </div>

                    <ng-container *ngIf="applicableRole === 'ADM'">
                        <app-button (click)="redirectToNewFund()"
                            class="add-button" *ngIf="permissionObj.canAdd">
                            <i class="fas fa-plus-circle mr-2"></i>
                            Adicionar Fundo
                        </app-button>
                    </ng-container>
                </ng-container>
            </div>

            <app-spacer [bottom]="32"></app-spacer>

            <div class="dash-card-body">
                <div class="dash-card-scroll">
                    <table class="skeleton-table" *ngIf="loading">
                        <thead>
                            <tr>
                                <th colspan="2">
                                    <ngx-skeleton-loader [theme]="{
                      height: '35px',
                      width: '100px',
                      marginBottom: '0'
                    }">
                                    </ngx-skeleton-loader>
                                </th>
                                <th>
                                    <ngx-skeleton-loader [theme]="{
                      height: '35px',
                      width: '100px',
                      marginBottom: '0'
                    }">
                                    </ngx-skeleton-loader>
                                </th>
                                <th>
                                    <ngx-skeleton-loader [theme]="{
                      height: '35px',
                      width: '100px',
                      marginBottom: '0'
                    }">
                                    </ngx-skeleton-loader>
                                </th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let number of [0, 1, 2]">
                                <td colspan="2">
                                    <div class="dash-card-item">
                                        <div class="dash-card-item-initials">
                                            <span>
                                                <ngx-skeleton-loader
                                                    appearance="circle" [theme]="{
                            height: '48px',
                            width: '48px'
                          }">
                                                </ngx-skeleton-loader>
                                            </span>
                                        </div>

                                        <app-spacer [right]="16"></app-spacer>

                                        <div class="dash-card-item-content">
                                            <div
                                                class="dash-card-item-content-title">
                                                <ngx-skeleton-loader [theme]="{
                            height: '21px',
                            width: '130px',
                            margin: '0'
                          }">
                                                </ngx-skeleton-loader>
                                            </div>
                                            <div
                                                class="dash-card-item-content-subtitle">
                                                <ngx-skeleton-loader [theme]="{
                            height: '18px',
                            width: '110px'
                          }">
                                                </ngx-skeleton-loader>
                                            </div>
                                        </div>
                                    </div>
                                </td>

                                <td>
                                    <ngx-skeleton-loader [theme]="{
                      height: '21px',
                      width: '100px'
                    }">
                                    </ngx-skeleton-loader>
                                </td>

                                <td>
                                    <ngx-skeleton-loader [theme]="{
                      height: '21px',
                      width: '200px'
                    }">
                                    </ngx-skeleton-loader>
                                </td>
                                <td></td>
                            </tr>
                        </tbody>
                        <app-spacer [bottom]="28"></app-spacer>
                    </table>

                    <table *ngIf="funds.length > 0 && !loading">
                        <thead>
                            <tr>
                                <th>Fundo</th>
                                <th>Status</th>
                                <!--   <th>cedentes</th>
                                    <th>cotistas</th> -->
                                <th>Iniciado em</th>
                                <th>última atualização</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let approval of funds">
                                <td>
                                    <div class="dash-card-item">
                                        <div class="dash-card-item-initials">
                                            <span>{{ getFundInitials(approval)
                                                }}</span>
                                        </div>

                                        <app-spacer [right]="16"></app-spacer>

                                        <div class="dash-card-item-content">
                                            <div
                                                class="dash-card-item-content-title">
                                                {{ approval.fund.name }}
                                            </div>
                                            <div
                                                class="dash-card-item-content-subtitle">
                                                {{
                                                formatDocument(approval.fund.document)
                                                }} •
                                                {{
                                                approval.fund.anbima_classification_display
                                                }}
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <span class="status">{{ getStatus(approval)
                                        }} </span>
                                    <div class="status-aprovation">
                                        {{ getStatusInfo(approval) }}
                                    </div>
                                </td>
                                <!--  <td></td>
                                    <td></td> -->
                                <td>
                                    {{ approval.created_at | date : "dd/MM/yyyy
                                    'às' HH:mm" }}
                                </td>
                                <td>
                                    {{ approval.updated_at | date : "dd/MM/yyyy
                                    'às' HH:mm" }}
                                </td>
                                <td>
                                    <div class="text-right d-flex">
                                        <a [routerLink]="[
                        '/app/funds/approval/update/',
                        approval.fund.id]" *ngIf="permissionObj.canChange">
                                            <i class="fas fa-pen"></i>
                                        </a>
                                        <a style="margin-left: 20px"
                                            [routerLink]="[
                                            '/app/funds/approval/',
                                            approval.fund.id]">
                                            <i class="fas fa-eye"></i>
                                        </a>
                                    </div>
                                </td>
                            </tr>

                            <tr *ngIf="funds.length === 0">
                                <td colspan="3">
                                    <span
                                        class="dash-card-item-content-subtitle">Nenhum
                                        fundo adicionado</span>
                                </td>
                            </tr>
                        </tbody>
                        <app-spacer [bottom]="28"></app-spacer>
                    </table>

                    <div *ngIf="funds.length === 0 && !loading">
                        <span
                            class="dash-card-item-content-title text-center d-block">
                            Nenhum fundo
                            {{ applicableRole !== "ADM" ? "vinculado" :
                            "adicionado" }}
                        </span>

                        <app-spacer [bottom]="30"></app-spacer>
                    </div>
                </div>
            </div>
        </div>
        <div class="dash-card-pagination" *ngIf="funds.length > 0">
            <app-pagination [data]="funds" [show]="!loading" [count]="count"
                [offset]="offset" (onPageChange)="changePageData($event)"
                [resultsPerPage]="50" [dashPagination]="true">
            </app-pagination>
        </div>
    </div>

    <app-side-modal [active]="showFilterModal" title="Filtrar listagem"
        (onClose)="showFilterModal = false">
        <form [formGroup]="filterForm">
            <div class="row">
                <div class="col-12">
                    <app-input label="Por nome ou documento"
                        [group]="filterForm" controlName="search"
                        placeholder="Ex.: ACME LTDA">
                    </app-input>
                    <app-spacer [bottom]="16"></app-spacer>
                </div>

                <!-- <div class="col-12">
                    <app-select [items]="selectItems" [group]="filterForm"
                        controlName="roles" placeholder="Selecione"
                        label="Por status" [multiple]="true">
                    </app-select>
                    <app-spacer [bottom]="32"></app-spacer>
                </div> -->

                <div class="col-12">
                    <app-select [items]="anbimaChoices" [group]="filterForm"
                        controlName="role" placeholder="Selecione"
                        label="Por classificação ANBIMA" [multiple]="true">
                    </app-select>
                    <app-spacer [bottom]="32"></app-spacer>
                </div>

                <div class="col-12">
                    <h3>Por data de cadastro</h3>
                    <app-spacer [bottom]="16"></app-spacer>
                </div>

                <div class="col-12 col-lg-6">
                    <app-masked-input [group]="filterForm"
                        controlName="created_from" mask="00/00/0000" label="De"
                        placeholder="dd/mm/aaaa">
                    </app-masked-input>
                    <app-spacer [bottom]="16"></app-spacer>
                </div>

                <div class="col-12 col-lg-6">
                    <app-masked-input [group]="filterForm"
                        controlName="created_to" mask="00/00/0000" label="Até"
                        placeholder="dd/mm/aaaa">
                    </app-masked-input>
                    <app-spacer [bottom]="32"></app-spacer>
                </div>

                <div class="col-12">
                    <h3>Por data de atualização</h3>
                    <app-spacer [bottom]="16"></app-spacer>
                </div>

                <div class="col-12 col-lg-6">
                    <app-masked-input [group]="filterForm"
                        controlName="updated_from" mask="00/00/0000" label="De"
                        placeholder="dd/mm/aaaa">
                    </app-masked-input>
                    <app-spacer [bottom]="16"></app-spacer>
                </div>

                <div class="col-12 col-lg-6">
                    <app-masked-input [group]="filterForm"
                        controlName="updated_to" mask="00/00/0000" label="Até"
                        placeholder="dd/mm/aaaa">
                    </app-masked-input>
                    <app-spacer [bottom]="16"></app-spacer>
                </div>
            </div>

            <app-spacer [bottom]="32"></app-spacer>

            <div class="row button-stic ky">
                <div class="col-12 col-lg-6">
                    <app-button (onClick)="resetForm()" class="add-button"
                        [phantom]="true">
                        Limpar filtros
                    </app-button>
                </div>

                <div class="col-12 col-lg-6">
                    <div class="text-right">
                        <app-button (onClick)="filterData()" class="add-button">
                            Aplicar filtros
                        </app-button>
                    </div>
                </div>
            </div>
        </form>
    </app-side-modal>
</div>