import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import Utils from 'src/app/helpers/utils';
import { ApiService } from 'src/app/services/api/api.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { StorageService } from 'src/app/services/storage/storage.service';
import { ToastService } from 'src/app/services/toast/toast.service';
import { MaskPipe } from 'ngx-mask';
import { permission } from 'src/app/data/permissions';

@Component({
  selector: 'app-dashboard-user-list',
  templateUrl: './dashboard-user-list.component.html',
  styleUrls: ['./dashboard-user-list.component.scss'],
})
export class DashboardUserListComponent implements OnInit {
  role: string;

  users: UserApprovalResponse[] = [];
  filteredUsers: UserApprovalResponse[] = [];
  count: number = 0;

  loading = true;
  offset = 0;

  permissionObj: PermissionValues = {
    canChange: false,
  };

  showFilterModal = false;

  today = Utils.todayString();

  statusOptions: SelectItem[] = [];

  dataFilters: any = null;
  primaryRoleOptions: SelectItem[] = [];
  roles: Role[] = [];
  selectedApplicable: string = null;
  roleOptions: SelectItem[] = [];

  filterForm = this.formBuilder.group({
    name: [null, []],
    document: [null, []],
    role: [null, []],
    agent: [null, []],
    status: [null, []],
    created_from: [null, []],
    created_to: [null, []],
    updated_from: [null, []],
    updated_to: [null, []],
  });

  constructor(
    private routeParams: ActivatedRoute,
    private api: ApiService,
    private toast: ToastService,
    private router: Router,
    private authService: AuthService,
    private storageService: StorageService,
    private formBuilder: FormBuilder,
    public maskPipe: MaskPipe
  ) {}

  ngOnInit(): void {
    if (!this.authService.verifyPermission(['de_can_view_register'])) {
      this.toast.show(
        'error',
        'Aviso',
        'Você não tem permissão para acessar essa página.'
      );
      this.router.navigateByUrl('/app/dashboard');
    }

    this.role = this.routeParams.snapshot.params.role?.slice(0, -1);
    this.getData();
    this.getRoles();

    this.setAgents();
    this.setPermissions();

    this.filterForm.controls.role.valueChanges.subscribe((value) => {
      const selectedRole = this.roles.find((item) => item.id === Number(value));

      this.selectedApplicable = selectedRole ? selectedRole.applicable : null;

      if (this.selectedApplicable !== 'provider') {
        this.filterForm.controls.agent.setValue(null);

        this.filterForm.controls.agent.updateValueAndValidity();
      }
    });
  }

  async setAgents() {
    try {
      const res = await this.api.get({
        route: 'api/registration/agent/',
        token: true,
      });

      this.roleOptions = res.map((item: any) => {
        return {
          label: item.name,
          value: item.id,
        };
      });
    } catch (error) {
      console.warn(error);
      this.toast.show('error', 'Erro', 'Ocorreu um erro ao carregar os dados.');
    }
  }

  async getRoles() {
    const res = await this.api.get<ApiResponse<Role[]>>({
      route: 'role/',
    });

    const { data } = res;

    this.roles = data;

    const userRoles = data
      .filter(
        (_item) =>
          _item.applicable === 'assignor' ||
          _item.applicable === 'provider' ||
          _item.applicable === 'shareholder'
      )
      .sort((a, b) => {
        if (a.applicable_display < b.applicable_display) {
          return -1;
        }
        if (a.applicable_display > b.applicable_display) {
          return 1;
        }
        return 0;
      })
      .map((item) => {
        return {
          label: `${item.applicable_display} - ${item.name}`,
          value: String(item.id),
        };
      });

    this.primaryRoleOptions = userRoles;
  }

  changePageData(page: number) {
    this.loading = true;
    this.getData(page);
  }

  async getData(page: number = 1) {
    const rolesToFilter = [];
    const roles = [
      'assignor',
      'shareholder',
      'provider',
      'representative',
      'fund',
    ];

    const params = {
      ...this.dataFilters,
      page,
      type: 'register',
      return_distinct: true,
    };

    try {
      const { count, results, offset } = await this.api.get<
        ApiResponse<UserApprovalResponse[]>
      >({
        route: `api/v2/registration/registers/`,
        params,
        token: true,
      });

      this.count = count;
      this.offset = offset;

      roles.forEach((role) => {
        const slug = `de_can_view_${role}`;
        if (!this.authService.verifyPermission(slug)) {
          rolesToFilter.push(role);
        }
      });

      const filteredData = results.filter((item) => {
        return item.register
          ? !rolesToFilter.includes(item.register?.role?.applicable)
          : !rolesToFilter.includes(item.type);
      });

      this.users = filteredData;

      //   this.setModel();
    } catch (error) {
      console.error(error);
      this.toast.show('error', 'Erro', 'Ocorreu um erro ao carregar os dados.');
    }
    this.loading = false;
  }

  getDisplayName(item: UserApprovalResponse) {
    if (item.register) {
      if (item.register.type && item.register.type.toUpperCase() === 'PF') {
        return item.register.person?.full_name;
      }

      if (item.register.type && item.register.type.toUpperCase() === 'PJ') {
        return item.register.company?.corporate_name;
      }
    }

    if (item.fund) {
      return item.fund.fantasy_name;
    }

    return '';
  }

  getDisplayDocument(item: UserApprovalResponse) {
    if (item.register) {
      if (item.register.type && item.register.type.toUpperCase() === 'PF') {
        return item.register.person.document
          ? 'CPF: ' +
              this.maskPipe.transform(
                item.register.person.document.number,
                '000.000.000-00'
              )
          : '-';
      }

      if (item.register.type && item.register.type.toUpperCase() === 'PJ') {
        return item.register.company.document
          ? 'CNPJ: ' +
              this.maskPipe.transform(
                item.register.company.document.number,
                '00.000.000/0000-00'
              )
          : '-';
      }
    }
    return '';
  }

  formatItemName(item: UserApprovalResponse) {
    if (item.register) {
      const name = item.register?.role?.applicable.toUpperCase();

      switch (name) {
        case 'ASSIGNOR':
          return 'Cedente';
        case 'SHAREHOLDER':
          return 'Cotista';
        case 'PROVIDER':
          let providerLabel =
            item.register.agent.length > 0
              ? item.register.agent.map((_) => _.name).join(', ')
              : 'Prestador de serviço';
          return providerLabel;

        default:
          return 'Representante';
      }
    }

    return 'Não identificado';
  }

  getStatus(item: UserApprovalResponse) {
    const register = item.register ?? item.fund;

    let last_approval: Approval = item;

    if (last_approval.completed) {
      if (last_approval.is_approved) {
        return 'Aprovado internamente';
      } else {
        return `${register ? 'Cadastro' : 'Fundo'} rejeitado`;
      }
    } else {
      if (last_approval.current_department !== null) {
        return last_approval.current_department.type === 'department'
          ? last_approval.current_department.department?.name
          : last_approval.current_department.agent?.name;
      } else if (last_approval.step === 0) {
        return 'Edição';
      } else {
        return 'Departamento não definido';
      }
    }
  }

  getStatusInfo(item: UserApprovalResponse) {
    const register = item.register ?? item.fund;

    let last_approval: Approval = item;
    if (last_approval.completed) {
      if (last_approval.is_approved) {
        return 'Integração ao fundo';
      } else {
        if (last_approval.current_department !== null) {
          return last_approval.current_department.type === 'department'
            ? last_approval.current_department.department?.name
            : last_approval.current_department.agent?.name;
        } else if (last_approval.step === 0) {
          return 'Edição';
        } else {
          return 'Departamento não definido';
        }
      }
    } else if (last_approval.step === 0) {
      return item.register ? 'Aguardando representantes' : '';
    } else {
      return `Aguardando aprovação (${last_approval.step})`;
    }
  }

  setPermissions() {
    const permissions = Object.keys(this.permissionObj).map(
      (key) => `de_can_${key.split('can').join('').toLowerCase()}_fund`
    );

    permissions.forEach((permission, idx) => {
      this.permissionObj[Object.keys(this.permissionObj)[idx]] =
        this.authService.verifyPermission(permission);
    });
  }

  getInitials(item: UserApprovalResponse) {
    let name = null;

    if (item.fund) {
      name = item.fund.fantasy_name;
    }

    if (item.register) {
      if (item.register.type && item.register.type.toUpperCase() === 'PF') {
        name = item.register.person?.full_name;
      }

      if (item.register.type && item.register.type.toUpperCase() === 'PJ') {
        name = item.register.company?.corporate_name;
      }
    }

    return name ? Utils.getInitials(name) : '-';
  }

  getName(item: any) {
    if ((item.user.type === 'PJ' || item.user.type === 'pj') && item.general) {
      return item.general.corporate_name;
    }

    return item.user.first_name + ' ' + item.user.last_name;
  }

  renderLink(item: UserApprovalResponse) {
    if (item.register) {
      return [
        `/app/details/${item.register?.role?.applicable}/`,
        item.register.id,
      ];
    }

    if (item.fund) {
      return [`/app/funds/approval/`, item.fund.id];
    }

    return '';
  }

  redirectTo(route: string) {
    this.router.navigate([route]);
  }

  async redirectToEdit(item) {
    await this.handleGetAcessToAccount(item);

    await this.api.patch({
      route: 'api/registration/trace_step/me/',
      body: { substep: 'general', step: 'register' },
      token: true,
    });

    this.router.navigate([
      `/signup/${
        item.register?.role?.applicable
      }/${item.register.type.toLowerCase()}`,
    ]);
  }

  async handleGetAcessToAccount(item) {
    const uudi = item.register.id;
    let id: number;

    const foundedRegister = this.authService.accountsArr.find((account) => {
      return account.register.id === uudi;
    });

    if (!foundedRegister) {
      try {
        const res = await this.api.post({
          route: 'api/registration/request/register',
          body: { register_id: uudi },
          token: true,
        });

        id = res.data.id;
      } catch (error) {
        console.log(error);
        this.toast.show(
          'error',
          'Erro!',
          'Erro ao solicitar acesso a conta ativa'
        );
      }
    } else {
      id = foundedRegister.id;
    }

    await this.api.patch({
      route: `user/me/`,
      token: true,
      body: {
        active_register: id,
      },
    });

    const { data } = await this.api.get<ApiResponse<NewLoginResponse>>({
      route: 'user/me/',
      token: true,
    });

    this.authService.setUser(data);

    let permissionsArr =
      data.active_register.permissions_role &&
      data.active_register.permissions_role.permissions.length > 0
        ? data.active_register.permissions_role.permissions
        : permission;

    this.authService.permissions = permissionsArr;
    this.storageService.set('permissions', permissionsArr, true);
  }

  async filterData() {
    this.loading = true;

    const rolesToFilter = [];
    const roles = [
      'assignor',
      'shareholder',
      'provider',
      'representative',
      'fund',
    ];

    try {
      const values = this.filterForm.value;

      const filters = {};

      Object.entries(values).forEach(([key, value]: any) => {
        if (value !== null && value !== '') {
          filters[key] = key === 'document' ? Utils.onlyNumbers(value) : value;
        }
      });

      const { count, data, offset } = await this.api.get<
        ApiResponse<UserApprovalResponse[]>
      >({
        route: `api/v2/registration/registers/`,
        params: {
          type: 'register',
          page: 1,
          ...filters,
          return_distinct: true,
        },
        token: true,
      });

      this.dataFilters = filters;
      this.count = count;
      this.offset = offset;

      roles.forEach((role) => {
        const slug = `de_can_view_${role}`;
        if (!this.authService.verifyPermission(slug)) {
          rolesToFilter.push(role);
        }
      });

      const filteredData = data.filter((item) => {
        return item.register
          ? !rolesToFilter.includes(item.register?.role?.applicable)
          : !rolesToFilter.includes(item.type);
      });

      this.users = filteredData;

      this.showFilterModal = false;
    } catch (error) {
      console.error(error);
      this.toast.show(
        'error',
        'Erro',
        'Ocorreu um erro ao carregar os dados de cedente.'
      );
    }
    this.loading = false;
  }

  resetForm() {
    this.loading = true;
    this.filterForm.reset();
    this.dataFilters = null;
    this.getData();
    this.showFilterModal = false;
  }

  formatDate(date: string) {
    const day = new Date(date).toLocaleDateString();
    const hours = new Date(date).toLocaleTimeString();
    return `${day} às ${hours}`;
  }

  //   setModel() {
  //     const headers = [
  //       new TableHeaderItem({ data: 'Empresa' }),
  //       new TableHeaderItem({ data: 'Papel' }),
  //       new TableHeaderItem({ data: 'Estágio atual' }),
  //     ];

  //     let newData = [];

  //     for (let user of this.users) {
  //       newData.push(
  //         new TableItem({ data: this.getDisplayName(user) }),
  //         new TableItem({ data: this.formatItemName(user) }),
  //         new TableItem({ data: this.getStatusInfo(user) })
  //       );

  //   new TableItem({
  //     data: {
  //       name: this.getDisplayName(user),
  //       document: this.getDisplayDocument(user),
  //       date: `Qtd Versões: ${
  //         user.register.current_revision
  //       } | Aprovação atualizada em ${this.formatDate(user.updated_at)}`,
  //     },
  //   });
  //     }

  //     this.model.header = headers;
  //     this.model.data = newData;
  //   }
}
