<div class="dash">
    <div class="row">
        <div class="col-12 ml-auto mr-auto">
            <app-tabs [tabs]="tabs" (onChangeTab)="onChangeTab($event)"
                [currentTab]="tabIndex" [dashboardTab]="true">
            </app-tabs>
            <app-spacer [bottom]="16"></app-spacer>

            <app-dashboard-groups *ngIf="activedTab === 'groups'">
            </app-dashboard-groups>

            <app-dashboard-users *ngIf="activedTab === 'users'">
            </app-dashboard-users>
        </div>
    </div>
</div>